import React from 'react';
import './Style.css';
import { PropagateLoader } from 'react-spinners';

const Loader = () => {
    return (
        <div className='LoaderContainer'>
            <PropagateLoader color="#002655" className='LoaderComponent' size={20} />
        </div>
    );
};

export default Loader;