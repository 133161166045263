import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import "leaflet/dist/leaflet.css";
import Dropzone from "react-dropzone";
import Nominatim from "nominatim-geocoder";
import "./custom-marker.css";
import L, { LatLng, divIcon } from "leaflet";
import { useNavigate } from "react-router-dom";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../../../Components/loader/loader";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import { GetAgents, GetAgentsById } from "../../../../service/agents";
import MapGoogle from "../../../Maps/GoogleMaps/GoogleMapIntegration";
import AutoComplete from "../../../Maps/GoogleMaps/GoogleMapsAutoComplete";
import {
  GetProvince,
  GetCanton,
  GetDistrict,
} from "../../../../service/ubications";
import {
  CreateProperty,
  GetProperty,
  UpdateProperty,
} from "../../../../service/properties";
import {
  TileLayer,
  Marker,
  Popup,
  MapContainer,
  useMapEvents,
  useMap,
} from "react-leaflet";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import FeatherIcons from "pages/Icons/FeatherIcons/FeatherIcons";
type Coordinates = {
  lat: number;
  lng: number;
};

const LocationMarker = ({ coord }: { coord: Coordinates }) => {
  const mapRefresh = useMap();
  const customIcon = divIcon({
    className: "custom-marker-icon",
    html: `<i class="mdi mdi-map-marker icon-large"></i>`,
    iconSize: [41, 41],
  });

  console.log("valor de coordenadas!!!", coord);
  const [position, setPosition] = useState<LatLng | null>(null);

  useEffect(() => {
    console.log("Entre aqui!!!");
    setPosition(new LatLng(coord.lat, coord.lng));
    mapRefresh.setView([coord.lat, coord.lng], 13);
  }, [coord, mapRefresh]);

  const map = useMapEvents({
    click() {
      map.locate();
    },
    locationfound(e) {
      console.log("evento maps", e.latlng);
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={coord} icon={customIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

const geocoder = new Nominatim({
  secure: true,
});

const Properties = () => {
  const history = useNavigate();

  const { _id } = useParams();
  const [agent, setAgent] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [selectedFiles, setselectedFiles] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [coordinates, setCoordinates] = useState<Coordinates>({
    lat: 10.2735633,
    lng: -84.0739102,
  });
  const [agentData, setAgentData] = useState<any>();
  const [cantonData, setCantonData] = useState<any>();
  const [provinceData, setProvinceData] = useState<any>();
  const [districtData, setDistrictData] = useState<any>();
  const [previewImages, setPreviewImages] = useState<any>();
  const [images, setImages] = useState([]);
  const currencyOptions = [
    {
      code: "USD",
      name: "Dólar Estadounidense",
      symbol: "$",
    },
    {
      code: "CRC",
      name: "Colón Costarricense",
      symbol: "₡",
    },
  ];

  const [formValues, setFormValues] = useState({
    title: "",
    description: "",
    agent: {},
    currency: {
      label: "Dólar Estadounidense",
      value: {
        code: "USD",
        name: "Dólar Estadounidense",
        symbol: "$",
      },
    },
    numberOfRooms: { value: "", label: "" },
    numberOfBathrooms: { value: "", label: "" },
    numberOfParkingSpaces: { value: "", label: "" },
    priceInDollars: "",
    priceInColones: "",
    cashback: 0,
    featured: false,
    discountedPriceInDollars: "",
    discountedPriceInColones: "",
    constructionYear: { value: "", label: "" },
    surface: "",
    furnished: false,
    province: { value: "", label: "" },
    canton: { value: "", label: "" },
    district: { value: "", label: "" },
    address: "",
    lng: { lat: 0, lng: 0 },
    postalCode: "",
    airConditioning: false,
    garden: false,
    terrace: false,
    walkingCloset: false,
    panoramicView: false,
    coffeeMachine: false,
    kitchen: false,
    dryer: false,
    washingMachine: false,
    refrigerator: false,
    rampOrElevator: false,
    waterHeater: false,
    microwave: false,
    toaster: false,
    indoorParking: false,
    petFriendly: false,
    grill: false,
    petArea: false,
    pool: false,
    sauna: false,
    playground: false,
    tennisCourt: false,
    BBQranch: false,
    security: false,
    gym: false,
    internet: false,
    electricity: false,
    maintenanceFee: false,
    water: false,
    tv: false,
    hospital: false,
    publicTransport: false,
    school: false,
    malls: false,
    supermarkets: false,
    status: false,
    sold: false,
    office: false,
    visitorParking: false,
  });

  const getAllAgents = async () => {
    const response: any = await GetAgents();
    const Agents = response.map((obj: any) => ({
      value: obj._id,
      label: obj.fullName,
    }));

    console.log("Carga de agentes!!!", Agents);
    setAgentData(Agents);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getProvince();
    getAllAgents();
    // getCanton('all');
    if (_id) {
      getProperty();
    }
  }, []);

  useEffect(() => {
    getProvince();
    const { province, canton, district, address } = formValues;
    // searchAddress(province.value, canton.value, district.value, address);
    // Realiza otras acciones según los valores de los estados adicionales
  }, [formValues]);

  const getProperty = async () => {
    if (!_id) return;
    GetProperty(_id)
      .then((response: any) => {
        setLoading(true);
        console.log("valor de propiedad a editar!!!", response);
        setImages(response.images);
        setFormValues({
          ...formValues,
          title: response.detail.title,
          description: response.detail.description,
          agent: response.detail.agent,
          currency: {
            label: response.detail.currency.name,
            value: response.detail.currency,
          },
          featured: response.detail.featured,
          numberOfRooms: {
            label: response.detail.numberOfRooms.toString(),
            value: response.detail.numberOfRooms.toString(),
          },
          numberOfParkingSpaces: {
            label: response.detail.numberOfParkingSpaces.toString(),
            value: response.detail.numberOfParkingSpaces.toString(),
          },
          numberOfBathrooms: {
            label: response.detail.numberOfBathrooms.toString(),
            value: response.detail.numberOfBathrooms.toString(),
          },
          priceInDollars: response.detail.priceInDollars,
          priceInColones: response.detail.priceInColones,
          cashback: response.detail.cashback,
          discountedPriceInDollars: response.detail.discountedPriceInDollars,
          discountedPriceInColones: response.detail.discountedPriceInColones,
          constructionYear: {
            label: response.detail.constructionYear.toString(),
            value: response.detail.constructionYear.toString(),
          },
          surface: response.detail.surface,
          province: {
            label: response.location.province,
            value: response.location.province,
          },
          canton: {
            label: response.location.canton,
            value: response.location.canton,
          },
          district: {
            label: response.location.district,
            value: response.location.district,
          },
          address: response.location.direction,
          postalCode: response.location.postalCode,
          airConditioning: response.additionalCharacteristics.airConditioning,
          garden: response.additionalCharacteristics.garden,
          grill: response.additionalCharacteristics.grill,
          microwave: response.additionalCharacteristics.microwave,
          rampOrElevator: response.additionalCharacteristics.rampOrElevator,
          refrigerator: response.additionalCharacteristics.refrigerator,
          terrace: response.additionalCharacteristics.terrace,
          toaster: response.additionalCharacteristics.toaster,
          walkingCloset: response.additionalCharacteristics.walkingCloset,
          panoramicView: response.additionalCharacteristics.panoramicView,
          coffeeMachine: response.additionalCharacteristics.coffeeMachine,
          kitchen: response.additionalCharacteristics.kitchen,
          dryer: response.additionalCharacteristics.dryer,
          washingMachine: response.additionalCharacteristics.washingMachine,
          waterHeater: response.additionalCharacteristics.waterHeater,
          indoorParking: response.additionalCharacteristics.indoorParking,
          petFriendly: response.additionalCharacteristics.petFriendly,
          office: response.additionalCharacteristics.office,
          furnished: response.additionalCharacteristics.furnished,
          BBQranch: response.amenities.BBQranch,
          gym: response.amenities.gym,
          visitorParking: response.amenities.visitorParking,
          petArea: response.amenities.petArea,
          pool: response.amenities.pool,
          sauna: response.amenities.sauna,
          playground: response.amenities.playground,
          tennisCourt: response.amenities.tennisCourt,
          security: response.amenities.security,
          electricity: response.includedServices.electricity,
          internet: response.includedServices.internet,
          maintenanceFee: response.includedServices.maintenanceFee,
          tv: response.includedServices.tv,
          water: response.includedServices.water,
          hospital: response.surroundings.hospital,
          malls: response.surroundings.malls,
          school: response.surroundings.school,
          publicTransport: response.surroundings.publicTransport,
          supermarkets: response.surroundings.supermarkets,
        });
        if (response.location.lat && response.location.lng) {
          setCoordinates({
            lat: response.location.lat,
            lng: response.location.lng,
          });
        }
        setAgent({
          value: response.detail?.agent?._id,
          label: response.detail?.agent?.fullName,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error al editar", err);
      });
  };

  const getProvince = async () => {
    GetProvince()
      .then((response: any) => {
        console.log("valor de response province", response);
        const mappedOptions = response.map((option: string) => ({
          value: option,
          label: option,
        }));
        console.log("valores de provincias!!!", mappedOptions);
        setProvinceData(mappedOptions);
      })
      .catch((error) => {
        console.log(
          "Ocurrió un error al obtener los datos de provincia",
          error
        );
      });
  };

  const getCanton = (province: string) => {
    GetCanton(province)
      .then((response: any) => {
        console.log("valor de response canton", response);
        const mappedOptions = response.map((option: string) => ({
          value: option,
          label: option,
        }));
        console.log("valores de provincias!!!", mappedOptions);
        setCantonData(mappedOptions);
      })
      .catch((error) => {
        console.log(
          "Ocurrió un error al obtener los datos de provincia",
          error
        );
      });
  };

  const getDistrict = (canton: string) => {
    GetDistrict(formValues.province.value, canton)
      .then((response: any) => {
        console.log("valor de response canton", response);
        const mappedOptions = response.map((option: string) => ({
          value: option,
          label: option,
        }));
        console.log("valores de provincias!!!", mappedOptions);
        setDistrictData(mappedOptions);
      })
      .catch((error) => {
        console.log(
          "Ocurrió un error al obtener los datos de provincia",
          error
        );
      });
  };

  const Parking = [
    { value: "0", label: "0" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
  ];

  const RoomNumbers = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  const BathRoomNumbers = [
    { value: "1", label: "1" },
    { value: "1.5", label: "1.5" },
    { value: "2", label: "2" },
    { value: "2.5", label: "2.5" },
    { value: "3", label: "3" },
    { value: "3.5", label: "3.5" },
    { value: "4", label: "4" },
    { value: "4.5", label: "4.5" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];

  const ConstructionYears = [
    { value: "0-5", label: "0-5" },
    { value: "6-10", label: "6-10" },
    { value: "11-15", label: "11-15" },
    { value: "16-20", label: "16-20" },
    { value: "21-25", label: "21-25" },
    { value: "30 o más...", label: "30 o más..." },
  ];

  const getAgentSelected = async (selectedSingle: any) => {
    console.log("valor de handleSingle", selectedSingle);
    const agent = await GetAgentsById(selectedSingle.value);
    console.log("valor de Agente seleccionado!!!", agent);
    // @ts-ignore
    setFormValues((prevState) => ({
      ...prevState,
      agent: agent,
    }));
  };

  function handleAcceptedFiles(event: React.ChangeEvent<HTMLInputElement>) {
    const images: any = event.target.files;
    console.log("valor de imagenes", images);
    const files = Array.from(images);
    console.log("Array Files!!!!", files);
    const selectedImages = [];

    for (let i = 0; i < files.length; i++) {
      selectedImages.push(URL.createObjectURL(images[i]));
    }

    setPreviewImages(selectedImages);
    setselectedFiles(files);
  }

  function formatBytes(bytes: any, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const searchAddress = async (
    province: string,
    canton: string,
    district: string,
    address: string
  ) => {
    try {
      const addressCompleted = `Costa Rica, ${province}, ${canton}, ${district}, ${address}`;
      console.log("validando direccion completa!!!", addressCompleted);
      const results = await geocoder.search({ q: addressCompleted });
      console.log("valor de resultados", results);
      if (results && results.length > 0) {
        const { lat, lon } = results[0];
        console.log("valor de lat", lat);
        console.log("valor de long", lon);
        setCoordinates({ lat: parseFloat(lat), lng: parseFloat(lon) });
      }
    } catch (error) {
      console.error("Error searching address:", error);
    }
  };

  const handleValidateCashBack = (event: any) => {
    if (event.target.value === "" || !isNaN(event.target.value)) {
      setFormValues({
        ...formValues,
        cashback: event.target.value === "" ? 0 : Number(event.target.value),
      });
    }
  };

  const saveProperties = () => {
    setLoading(true);
    console.log("valores de formato!!!!", formValues);
    console.log("valor de imagenes", images);

    const saveProperty = {
      detail: {
        title: formValues.title,
        description: formValues.description,
        agent: formValues.agent,
        currency: formValues.currency.value,
        featured: formValues.featured,
        cashback: formValues.cashback,
        numberOfRooms: formValues.numberOfRooms.value,
        numberOfBathrooms: formValues.numberOfBathrooms.value,
        numberOfParkingSpaces: formValues.numberOfParkingSpaces.value,
        priceInDollars:
          formValues.currency.value.code === "USD"
            ? parseInt(formValues.priceInDollars)
            : null,
        priceInColones:
          formValues.currency.value.code != "USD"
            ? parseInt(formValues.priceInColones)
            : null,
        discountedPriceInDollars: parseInt(formValues.discountedPriceInDollars),
        discountedPriceInColones: parseInt(formValues.discountedPriceInColones),
        constructionYear: formValues.constructionYear.value,
        surface: parseInt(formValues.surface),
        numberOfVisits: 0,
      },
      location: {
        province: formValues.province.value,
        canton: formValues.canton.value,
        district: formValues.district.value,
        direction: formValues.address,
        lat: coordinates.lat,
        lng: coordinates.lng,
        postalCode: formValues.postalCode,
      },
      additionalCharacteristics: {
        airConditioning: formValues.airConditioning,
        garden: formValues.garden,
        terrace: formValues.terrace,
        walkingCloset: formValues.walkingCloset,
        panoramicView: formValues.panoramicView,
        coffeeMachine: formValues.coffeeMachine,
        kitchen: formValues.kitchen,
        dryer: formValues.dryer,
        washingMachine: formValues.washingMachine,
        refrigerator: formValues.refrigerator,
        rampOrElevator: formValues.rampOrElevator,
        waterHeater: formValues.waterHeater,
        microwave: formValues.microwave,
        toaster: formValues.toaster,
        grill: formValues.grill,
        indoorParking: formValues.indoorParking,
        petFriendly: formValues.petFriendly,
        furnished: formValues.furnished,
        office: !!formValues.office,
      },
      amenities: {
        petArea: formValues.petArea,
        pool: formValues.pool,
        sauna: formValues.sauna,
        playground: formValues.playground,
        tennisCourt: formValues.tennisCourt,
        BBQranch: formValues.BBQranch,
        security: formValues.security,
        gym: formValues.gym,
        visitorParking: formValues.visitorParking,
      },
      includedServices: {
        internet: formValues.internet,
        electricity: formValues.electricity,
        maintenanceFee: formValues.maintenanceFee,
        water: formValues.water,
        tv: formValues.tv,
      },
      surroundings: {
        hospital: formValues.hospital,
        publicTransport: formValues.publicTransport,
        school: formValues.school,
        malls: formValues.malls,
        supermarkets: formValues.supermarkets,
      },
      images: images.length > 0 ? images : [],
      status: {
        status: true,
        sold: false,
      },
      created: new Date(),
    };

    const formData = new FormData();

    if (selectedFiles.length >= 5 && !_id) {
      formData.append("data", JSON.stringify(saveProperty));
      selectedFiles.forEach((image: File) => {
        console.log("cargando archivos al formData", image);
        formData.append("file", image);
      });

      console.log("valor de formData", formData);

      // const response = await CreateProperty(formData);
      // @ts-ignore
      CreateProperty(formData)
        .then(async (response) => {
          setLoading(false);
          if (response) history("/pages-properties");
        })
        .catch((error) => {
          setLoading(false);
          console.log("Error al crear la propiedad", error);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Ha ocurrido un error");
          }
        });
    } else if (selectedFiles.length < 5 && !_id) {
      setLoading(false);
      toast.error("Por favor agregue 5 imágenes como mínimo");
    } else {
      const id = _id ?? "";
      formData.append("data", JSON.stringify(saveProperty));
      if (selectedFiles) {
        selectedFiles.forEach((image: File) => {
          console.log("cargando archivos al formData", image);
          formData.append("file", image);
        });
      }
      // const response = UpdateProperty(_id, formData);
      // @ts-ignore
      UpdateProperty(id, formData)
        .then(async (response) => {
          console.log("response update properties", response);
          setLoading(false);
          if (response) history("/pages-properties");
        })
        .catch((err) => {
          console.log("Error al actualizar", err);
          console.log("Error al actualizar", err.response);
          setLoading(false);
        });
    }
  };

  document.title = "Propiedades";
  return (
    <React.Fragment>
      <ToastContainer theme="colored" />
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Propiedades" pageTitle="Pages" />
            <Row>
              <Col xs={12}></Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">
                      Información de la propiedad
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <div>
                        <div>
                          <Label
                            htmlFor="choices-text-disabled"
                            className="form-label text-muted"
                          >
                            Titulo de la propiedad
                          </Label>
                          <Input
                            className="form-control input-title-propertie"
                            type="text"
                            value={formValues.title}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                title: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div>
                          <Label
                            htmlFor="choices-text-disabled"
                            className="form-label text-muted"
                          >
                            Descripción
                          </Label>
                          <Input
                            className="form-control input-title-propertie"
                            type="text"
                            value={formValues.description}
                            onChange={(e) =>
                              setFormValues({
                                ...formValues,
                                description: e.target.value,
                              })
                            }
                          />
                        </div>
                        <Row>
                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Agente inmobiliario
                              </Label>
                              <Select
                                value={agent}
                                onChange={(selectedSingle: any) => {
                                  getAgentSelected(selectedSingle);
                                  setAgent(selectedSingle);
                                }}
                                options={agentData}
                                placeholder=""
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Habitaciones
                              </Label>
                              <Select
                                value={formValues.numberOfRooms}
                                onChange={(selectedOption: any) =>
                                  setFormValues({
                                    ...formValues,
                                    numberOfRooms: selectedOption,
                                  })
                                }
                                options={RoomNumbers}
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Baños
                              </Label>
                              <Select
                                value={formValues.numberOfBathrooms}
                                onChange={(selectedOption: any) =>
                                  setFormValues({
                                    ...formValues,
                                    numberOfBathrooms: selectedOption,
                                  })
                                }
                                options={BathRoomNumbers}
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Parqueos
                              </Label>
                              <Select
                                value={formValues.numberOfParkingSpaces}
                                onChange={(selectedOption: any) =>
                                  setFormValues({
                                    ...formValues,
                                    numberOfParkingSpaces: selectedOption,
                                  })
                                }
                                options={Parking}
                              />
                            </div>
                          </Col>

                          {/* <Col lg={6} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-text-remove-button" className="form-label text-muted">Precio de dolares</Label>
                                                        <Input className="form-control" id="choices-text-remove-button" value={formValues.priceInDollars} onChange={(e) => setFormValues({ ...formValues, priceInDollars: e.target.value })} type="text" placeholder="$0" />
                                                    </div>
                                                </Col> */}
                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Moneda de pago
                              </Label>
                              <Select
                                value={formValues.currency}
                                onChange={(selectedOption: any) =>
                                  setFormValues({
                                    ...formValues,
                                    currency: selectedOption,
                                  })
                                }
                                options={currencyOptions.map((option) => ({
                                  value: option,
                                  label: option.name,
                                }))}
                              />
                            </div>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-text-remove-button"
                                className="form-label text-muted"
                              >
                                Precio en {formValues.currency.label}
                              </Label>
                              <Input
                                className="form-control"
                                id="choices-text-remove-button"
                                value={
                                  formValues.currency.value.code === "USD"
                                    ? formValues.priceInDollars
                                    : formValues.priceInColones
                                }
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    [formValues.currency.value.code === "USD"
                                      ? "priceInDollars"
                                      : "priceInColones"]: e.target.value,
                                  })
                                }
                                type="text"
                                placeholder={
                                  formValues.currency.value.symbol + "0"
                                }
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-text-remove-button"
                                className="form-label text-muted"
                              >
                                Cashback
                              </Label>
                              <Input
                                className="form-control"
                                id="choices-text-remove-button"
                                value={formValues.cashback}
                                onChange={(e) => handleValidateCashBack(e)}
                                type="text"
                                placeholder="0$"
                              />
                            </div>
                          </Col>
                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Años de construcción
                              </Label>
                              <Select
                                value={formValues.constructionYear}
                                onChange={(selectedOption: any) =>
                                  setFormValues({
                                    ...formValues,
                                    constructionYear: selectedOption,
                                  })
                                }
                                options={ConstructionYears}
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-text-remove-button"
                                className="form-label text-muted"
                              >
                                Superficie en m²
                              </Label>
                              <Input
                                className="form-control"
                                id="choices-text-remove-button"
                                value={formValues.surface}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    surface: e.target.value,
                                  })
                                }
                                data-choices
                                data-choices-limit="3"
                                type="text"
                                defaultValue="0"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">
                      Ubicación de la propiedad
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <div>
                      <div>
                        <Row>
                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Provincia
                              </Label>
                              <Select
                                className="properties-form-select-province"
                                value={formValues.province}
                                onChange={(selectedOption: any) => {
                                  setFormValues({
                                    ...formValues,
                                    province: selectedOption,
                                  });
                                  getCanton(selectedOption.value);
                                }}
                                options={provinceData}
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Municipio / Cantón
                              </Label>
                              <Select
                                className="properties-form-select"
                                value={formValues.canton}
                                onChange={(selectedOption: any) => {
                                  setFormValues({
                                    ...formValues,
                                    canton: selectedOption,
                                  });
                                  getDistrict(selectedOption.value);
                                }}
                                options={cantonData}
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-single-default"
                                className="form-label text-muted"
                              >
                                Distrito
                              </Label>
                              <Select
                                className="properties-form-select"
                                value={formValues.district}
                                onChange={(selectedOption: any) =>
                                  setFormValues({
                                    ...formValues,
                                    district: selectedOption,
                                  })
                                }
                                options={districtData}
                              />
                            </div>
                          </Col>

                          <Col lg={6} md={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="choices-text-remove-button"
                                className="form-label text-muted"
                              >
                                Dirección
                              </Label>
                              <AutoComplete
                                addressValue={formValues.address}
                                getCoords={setCoordinates}
                                address={(
                                  adrs: string,
                                  prov: string,
                                  dstr: string
                                ) =>
                                  setFormValues({
                                    ...formValues,
                                    address: adrs,
                                  })
                                }
                              />
                              {/* <Input className="form-control" id="choices-text-remove-button" value={formValues.address} onChange={(e) => setFormValues({ ...formValues, address: e.target.value })} type="text" /> */}
                            </div>
                          </Col>

                          {/* <Col lg={6} md={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="choices-text-remove-button" className="form-label text-muted">Código postal</Label>
                                                        <Input className="form-control" id="choices-text-remove-button" value={formValues.postalCode} onChange={(e) => setFormValues({ ...formValues, postalCode: e.target.value })} type="text" />
                                                    </div>
                                                </Col> */}

                          <Col lg={12}>
                            <div className="mb-3">
                              {/* <MapContainer center={coordinates} zoom={13} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
                                                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />   
                                                            <LocationMarker coord={coordinates} />
                                                        </MapContainer> */}
                              {/* <AutoComplete getCoords={setCoordinates} address={(adrs: any) => setFormValues({ ...formValues, address: adrs })} /> */}
                              {/* <AutoCompleteTest /> */}
                              <MapGoogle
                                coords={coordinates}
                                setCoords={setCoordinates}
                                draggableMap={true}
                                zoom={8}
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader>
                    <h4 className="card-title mb-0">
                      Características adicionales
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Col md={12}>
                      <div>
                        <p className="text-muted">
                          Características adicionales
                        </p>
                        <Row>
                          <Col md={4}>
                            <div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck1"
                                  checked={formValues.furnished}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      furnished: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck1"
                                >
                                  Amoblado/Semiamoblado
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck2"
                                  checked={formValues.airConditioning}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      airConditioning: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck2"
                                >
                                  Aire acondicionado
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck3"
                                  checked={formValues.garden}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      garden: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck3"
                                >
                                  Jardín / Patio
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck4"
                                  checked={formValues.terrace}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      terrace: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck4"
                                >
                                  Terraza
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck5"
                                  checked={formValues.walkingCloset}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      walkingCloset: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck5"
                                >
                                  Walk-in Closet
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck6"
                                  checked={formValues.panoramicView}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      panoramicView: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck6"
                                >
                                  Vista panoramica
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck18"
                                  checked={formValues.petFriendly}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      petFriendly: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck18"
                                >
                                  Pet Friendly
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck7"
                                  checked={formValues.rampOrElevator}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      rampOrElevator: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck7"
                                >
                                  Rampa / Elevador
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck8"
                                  checked={formValues.waterHeater}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      waterHeater: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck8"
                                >
                                  Calentador de agua
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck9"
                                  checked={formValues.microwave}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      microwave: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck9"
                                >
                                  Microondas
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck10"
                                  checked={formValues.toaster}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      toaster: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck10"
                                >
                                  Tostadora
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck11"
                                  checked={formValues.grill}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      grill: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck11"
                                >
                                  Parrillera
                                </Label>
                              </div>
                              <div className="form-check mb-3">
                                <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="formCheck12"
                                  checked={formValues.indoorParking}
                                  onChange={(e) =>
                                    setFormValues({
                                      ...formValues,
                                      indoorParking: e.target.checked,
                                    })
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  for="formCheck12"
                                >
                                  Parqueo con techo
                                </Label>
                              </div>
                            </div>
                          </Col>
                          <Col md={4}>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck13"
                                checked={formValues.kitchen}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    kitchen: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck13"
                              >
                                Cocina
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck14"
                                checked={formValues.dryer}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    dryer: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck14"
                              >
                                Secadora
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck15"
                                checked={formValues.coffeeMachine}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    coffeeMachine: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck15"
                              >
                                Maquina de cafe
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck16"
                                checked={formValues.washingMachine}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    washingMachine: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck16"
                              >
                                Lavadora
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck17"
                                checked={formValues.refrigerator}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    refrigerator: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck17"
                              >
                                Nevera
                              </Label>
                            </div>

                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck38"
                                checked={formValues.office}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    office: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck38"
                              >
                                Oficina
                              </Label>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>

                    <Col md={12}>
                      <Row>
                        <Col md={4}>
                          <p className="text-muted">Amenidades</p>
                          <div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck19"
                                checked={formValues.petArea}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    petArea: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck19"
                              >
                                Área de mascotas
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck20"
                                checked={formValues.pool}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    pool: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck20"
                              >
                                Piscina
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck21"
                                checked={formValues.playground}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    playground: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck21"
                              >
                                Parque infantil
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck22"
                                checked={formValues.sauna}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    sauna: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck22"
                              >
                                Bodega
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck23"
                                checked={formValues.tennisCourt}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    tennisCourt: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck23"
                              >
                                Cancha deportiva
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck24"
                                checked={formValues.BBQranch}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    BBQranch: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck24"
                              >
                                Rancho BBQ
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck25"
                                checked={formValues.security}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    security: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck25"
                              >
                                Seguridad 24/7
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck26"
                                checked={formValues.gym}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    gym: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck26"
                              >
                                Gimnasio
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck39"
                                checked={formValues.visitorParking}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    visitorParking: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck39"
                              >
                                Parqueos de visita
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <p className="text-muted">Servicios incluidos</p>
                          <div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck27"
                                checked={formValues.internet}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    internet: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck27"
                              >
                                Internet
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck28"
                                checked={formValues.electricity}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    electricity: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck28"
                              >
                                Servicio eléctrico
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck29"
                                checked={formValues.maintenanceFee}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    maintenanceFee: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck29"
                              >
                                Cuota de mantenimiento
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck30"
                                checked={formValues.water}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    water: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck30"
                              >
                                Agua
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck31"
                                checked={formValues.tv}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    tv: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck31"
                              >
                                TV por cable
                              </Label>
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <p className="text-muted">Cercanías</p>
                          <div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck32"
                                checked={formValues.hospital}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    hospital: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck32"
                              >
                                Hospital
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck33"
                                checked={formValues.publicTransport}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    publicTransport: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck33"
                              >
                                Transporte publico
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck34"
                                checked={formValues.school}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    school: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck34"
                              >
                                Escuela
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck35"
                                checked={formValues.malls}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    malls: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck35"
                              >
                                Centros comerciales
                              </Label>
                            </div>
                            <div className="form-check mb-3">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                id="formCheck36"
                                checked={formValues.supermarkets}
                                onChange={(e) =>
                                  setFormValues({
                                    ...formValues,
                                    supermarkets: e.target.checked,
                                  })
                                }
                              />
                              <Label
                                className="form-check-label"
                                for="formCheck36"
                              >
                                Supermercado
                              </Label>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader className="card-header">
                    <h4 className="card-title mb-0">Imagenes</h4>
                  </CardHeader>
                  <CardBody>
                    <p className="text-muted">
                      Mínimo 5 imagenes de 1100x750px en formato JPEG o PNG{" "}
                    </p>

                    {/* <div>
                                        <h2>Upload Files</h2>
                                        <input type="file" onChange={handleFileChange} multiple />
                                        <button onClick={handleUpload}>Upload Files</button>
                                    </div> */}

                    {/* <Dropzone
                                        onDrop={(acceptedFiles: any) => {
                                            handleAcceptedFiles(acceptedFiles);
                                        }}
                                    >
                                        {({ getRootProps, getInputProps }) => ( */}
                    <div className="dropzone dz-clickable">
                      <div
                        className="dz-message needsclick"
                        onClick={() =>
                          fileInputRef.current && fileInputRef.current.click()
                        }
                      >
                        <div className="mb-3">
                          <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                        </div>
                        <input
                          multiple
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={handleAcceptedFiles}
                        />
                        <h4>Sube o arrastra imágenes de la propiedad</h4>
                      </div>
                    </div>
                    {/* )}
                                    </Dropzone> */}
                    <div className="list-unstyled mb-0" id="file-previews">
                      {selectedFiles &&
                        selectedFiles.map((f: any, i: any) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={previewImages[i]}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.size}</strong>
                                    </p>
                                  </Col>
                                  <Col className="col-auto ms-auto">
                                    {" "}
                                    <button
                                      onClick={() => {
                                        const newSelectedFiles =
                                          selectedFiles.filter(
                                            (_: any, index: number) =>
                                              index !== i
                                          );
                                        setselectedFiles(newSelectedFiles);
                                      }}
                                      className="btn btn-link p-0 border-0 d-flex align-items-center "
                                      style={{ width: "auto", marginRight: 4 }}
                                    >
                                      <i className="ri-delete-bin-5-fill me-3 align-middle fw-medium fs-4"></i>
                                      Eliminar
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                    </div>
                    {images && images.length > 0 && (
                      <div className="list-unstyled mb-0" id="file-previews">
                        {images.map((f: any, i: any) => {
                          return (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      src={f.url}
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.size}</strong>
                                    </p>
                                  </Col>
                                  <Col className="col-auto ms-auto">
                                    {" "}
                                    <button
                                      onClick={() => {
                                        const newSelectedFiles = images.filter(
                                          (_: any, index: number) => index !== i
                                        );
                                        setImages(newSelectedFiles);
                                      }}
                                      className="btn btn-link p-0 border-0 d-flex align-items-center "
                                      style={{ width: "auto", marginRight: 4 }}
                                    >
                                      <i className="ri-delete-bin-5-fill me-3 align-middle fw-medium fs-4"></i>
                                      Eliminar
                                    </button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          );
                        })}
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardHeader className="card-header">
                    <h4 className="card-title mb-0">Propiedad Destacada</h4>
                  </CardHeader>
                  <CardBody>
                    <div className="form-check mb-3">
                      <Input
                        className="form-check-input"
                        type="checkbox"
                        id="formCheck37"
                        checked={formValues.featured}
                        onChange={(e) =>
                          setFormValues({
                            ...formValues,
                            featured: e.target.checked,
                          })
                        }
                      />
                      <Label className="form-check-label" for="formCheck37">
                        Selecciona para destacar la propiedad
                      </Label>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row style={{ marginBottom: 20 }}>
              <Col className="d-flex justify-content-end">
                <Button color="primary" onClick={() => saveProperties()}>
                  Guardar
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default Properties;
