import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Label, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown } from 'reactstrap';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import Masonry from "react-masonry-component";

import { gallery } from '../../../../common/data';
import Lightbox, { label } from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import PropertyCard, { PropertyCardProps, Status as ApartamentStatus } from 'Components/UI/PropertyCard';
import { apartments } from 'common/data/pagesData';
import { CreateFavoriteProperty, DeleteFavoriteProperty, DeleteProperty, GetAllProperties, GetAllPropertiesV2, GetAllPropertiesV2Props, GetFavoriteProperty, UpdateProperty } from 'service/properties';
import { Link, useNavigate } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { toast } from 'react-toastify';
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';

const maxStar = 6
interface Status {
  status: boolean;
  sold: boolean;
  _id: string;
}

const FilterOptions: {
  label: string,
  status?: ApartamentStatus[],
  value: ApartamentStatus | "all"
}[] = [
    {
      label: "Todos",
      status: ["active", "inactive", "in-review"],
      value: "all"
    },
    {
      label: "Propiedades Activas",
      status: ["active"],
      value: "active"
    },
    {
      label: "Propiedades Inactiva",
      status: ["inactive"],
      value: "inactive"
    },
    {
      label: "Propiedades en Revisión",
      status: ["in-review"],
      value: "in-review"
    },
  ]

const filterGallery = ({ category }: any) => {

}

const parseStatus = ({ status, sold }: Status): PropertyCardProps["status"] => {
  if (status && sold) {
    return "inactive";
  } else if (status && !sold) {
    return "active";
  } else if (!status && !sold) {
    return "inactive";
  } else {
    return "in-review";
  }
}

const PropertyList = () => {
  const history = useNavigate();
  document.title = "Property List";
  const { user } = useAppSelector(state => state.Profile)

  const [displayCategory, setCategory] = useState<string>("All");
  const [filter, setFilter] = useState<ApartamentStatus | "all">("all");
  const [index, setIndex] = useState<any>(-1);
  const [loading, setLoading] = useState(false);
  const [properties, setProperties] = useState<any>([]);
  const [favoriteProperties, setFavoriteProperties] = useState<any>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<string>('');
  const [starAmount, setStarAmount] = useState<number>(0);

  const deleteToggle = () => setDeleteModal((prev) => {
    if (prev) return ''
    else return prev
  });

  const getProperties = () => {
    if (user?._id) {
      const filters: GetAllPropertiesV2Props = user?.roles.includes(Role.Admin) ? {} : { params: { propertyManager: user?._id } }
      console.log(filters)
      GetAllPropertiesV2(filters).then(async (response) => {
        console.log(response)
        setProperties(response);
        setLoading(false);
      }).catch((err) => {
        console.log('Error al obtener propiedades', err);
        console.log('Error al obtener propiedades', err.message);
        setLoading(false);
      });
    }
    // const response = await GetAllProperties();
  }

  useEffect(() => {
    setLoading(true);
    getProperties();
    handleGetFavoriteProperties();
    setRefresh(false);
  }, [refresh, user?._id])

  useEffect(() => {
    if (properties.length) {
      const starAmount = properties.filter((el: any) => el.detail.featured).length
      setStarAmount(starAmount)
    }
  }, [properties])

  const handleGetFavoriteProperties = async () => {
    try {
      const response = await GetFavoriteProperty({});
      setFavoriteProperties(response.data);
    } catch (err: any) {
      toast.error(err.response.data.message)
    }
  }

  useEffect(() => {
    handleGetFavoriteProperties()

    return () => {

    }
  }, [])


  const deleteProperty = async (id: string) => {
    console.log('valor del id del Agente!!!', id);
    try {
      const response = await DeleteProperty(id);
      toast.success('Propiedad eliminada correctamente');
    } catch (err: any) {
      toast.error(err.response.data.message)
    }
    setDeleteModal('')
    setRefresh(true);
    setLoading(false);
  }
  const editProperty = (id: string) => {
    history(`/pages-property-register/${id}`)
  }

  const propertyDetails = (id: string) => {
    history(`/pages-property-details/${id}`)
  }

  const createProperty = () => {
    history("/pages-properties-form");
  }


  const filterGallery = ({ category }: any) => {
    return (
      (displayCategory === category || displayCategory === "All")
    );
  };

  const filteredGallery = gallery.filter(({ category }: any) => filterGallery({ category }));

  const slideGallery = filteredGallery.map((item) => ({ "src": item.img }));

  const activebtn = (ele: any) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  function onClickData(item: any): void {
    throw new Error('Function not implemented.');
  }



  const handleStarProperty = async (v: any) => {
    if (starAmount >= maxStar && !v.detail.featured) {
      toast.error(`No puedes tener más de ${maxStar} propiedades destacadas`)
      return
    }
    const fetchImagesPromise =
      await v.images.map((image: any) => {
        const URL = image.url
        if (!URL) return null
        return fetch(URL)
          .then(res => res.blob())
          .then(blob => {
            return new File([blob], URL.split("/").pop(), { type: "image/jpeg" });

          }).catch(el => console.log(el));
      })
    v.detail.featured = !v.detail.featured
    const fetchImagesResponse = await Promise.all(fetchImagesPromise)

    console.log(fetchImagesResponse)

    v.images = fetchImagesResponse.filter(el => el)

    UpdateProperty(v._id, v).then(() => {
      setRefresh(true)
    }).catch((err) => {
      console.log(err)
    })
  }

  const handleLikeProperty = async (v: any) => {
    if (favoriteProperties.find((el: any) => el.property._id === v._id)) {
      DeleteFavoriteProperty(v._id).then(() => {
        setRefresh(true)
      }).catch((err) => {
        console.log(err)
      })
    } else {
      CreateFavoriteProperty(v._id).then(() => {
        setRefresh(true)
      }).catch((err) => {
        console.log(err)
      })
    }
  }





  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Visualizar propiedades" pageTitle="Pages" />
          <Row>
            <Col lg={12}>
              <div className="">
                <CardBody>
                  <Row>
                    <Col lg={12}>
                      {/* //TODO: Fix this flex problem  */}
                      <Row className='mb-3'>
                        <Col lg={6}>
                          <h4 className="fs-16">Mis propiedades</h4>
                        </Col>
                        <Col lg={6}>
                          <div className='filter-right'>
                            <UncontrolledDropdown>
                              <DropdownToggle tag="button" className="btn btn-link text-muted p-0 py-0 text-decoration-none fs-15 bg-light">
                                <p className='mb-0'><span className='text-black'>Filtrar Por:</span> {FilterOptions.find(el => el.value === filter)?.label} <i className="ri-arrow-down-s-line" /></p>
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                {
                                  FilterOptions.map((option, index) => (
                                    <DropdownItem key={index} onClick={() => setFilter(option.value)}>{option.label}</DropdownItem>
                                  ))
                                }
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                        </Col>
                      </Row>

                      {
                        properties.filter((el: any) => {
                          let _filter = FilterOptions.find(el => el.value === filter)
                          const status = parseStatus(el.status)
                          if (_filter?.status?.includes(status)) {
                            return true
                          } else {
                            return false
                          }

                        }).length ?
                          <Row>
                            {properties.filter((el: any) => {
                              let _filter = FilterOptions.find(el => el.value === filter)
                              const status = parseStatus(el.status)
                              if (_filter?.status?.includes(status)) {
                                return true
                              } else {
                                return false
                              }

                            }).map((props: any) => (
                              <Col md={6} xl={4} className="element-item project designing development"
                                key={props.id}>
                                <PropertyCard
                                  _id={props.id}
                                  img={props.images ? (props.images.filter((el: any) => el.url)[0]?.url) : ""}
                                  status={parseStatus(props.status)}
                                  address={props.location.direction}
                                  priceDollars={props.detail.priceInDollars}
                                  pricePounds={props.detail.priceInColones}
                                  payFrequency={"monthly"}
                                  bedroomAmount={props.detail.numberOfRooms}
                                  bathroomAmount={props.detail.numberOfBathrooms}
                                  parkingAmount={props.detail.numberOfParkingSpaces}
                                  sizeArea={props.detail.surface}
                                  description={props.detail.title}
                                  onView={() => propertyDetails(props._id)}
                                  onEdit={() => editProperty(props._id)}
                                  onRemove={() => setDeleteModal(props._id)}
                                  owner={true}
                                  onStar={user?.roles.includes(Role.Admin) ? () => handleStarProperty(props) : undefined}
                                  star={props.detail.featured}
                                  onLike={user?.roles.includes(Role.Tenant) ? () => handleLikeProperty(props) : undefined}
                                  like={favoriteProperties.find((el: any) => el.property._id === props._id) ? true : false}
                                />
                              </Col>
                            ))}

                          </Row>
                          : <Card className='w-100'>
                            <div style={{
                              height: 400,
                            }}
                              className='d-flex flex-direction-column justify-content-center align-items-center'
                            >
                              <div>
                                <p>No hay propiedades disponibles </p>
                                <button type="button" onClick={() => history("/pages-property-register")}
                                  className="btn btn-success mx-auto d-block" >Subir una propiedad</button>
                              </div>
                            </div>
                          </Card>
                      }
                      {/* <div className="text-center my-2">
                        <Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load More </Link>
                      </div> */}
                    </Col>
                  </Row>
                </CardBody>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {/* LightBox */}
      <Lightbox
        index={index}
        slides={slideGallery}
        open={index >= 0}
        close={() => setIndex(-1)}
      />

      <Modal
        isOpen={!!deleteModal}
        toggle={deleteToggle}
        backdrop={'static'}
        id="staticBackdrop"
        centered
      >
        <ModalHeader className="modal-title" id="staticBackdropLabel" toggle={deleteToggle}>


        </ModalHeader>
        <ModalBody className="text-center p-4">
          <i className="ri-error-warning-line display-2 ext-warning" style={{ color: "orange" }}></i>

          <div className="mt-4">
            <h4 className="mb-3">¿Estas seguro de eliminar esta propiedad?</h4>
            <p className="text-muted mb-4">Está propiedad será eliminada y no podrá ser recuperada.</p>
            <div className="hstack gap-2 justify-content-center">
              <button type='button' onClick={deleteToggle} className='btn btn-light waves-effect'>Cancelar</button>
              <button type='button' onClick={() => { deleteProperty(deleteModal) }} className='btn btn-outline-danger waves-effect waves-light material-shadow-none'>Eliminar</button>
            </div>
          </div>
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
};

export default PropertyList;