import { parseDataToForm } from "helpers/pardeDataToForm";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { ICreateProperty } from "../types";
import { ContractStatus, IContract, ICreateContract, IUpdateContract } from "types/api/contracts";
import { queries } from "@testing-library/react";
const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};

export interface GetContractsQuery {
    limit?: number;
    offset?: number;
    skip?: number;
    status?: ContractStatus[]
}


export const GetContracts = (filter: GetContractsQuery): Promise<{ count: number, data: IContract[] }> => api.get(`${url.GET_CONTRACTS}`, filter);
export const GetContract = (id: string): Promise<IContract> => api.get(`${url.GET_CONTRACT.replace("{id}", id)}`);
export const CreateContract = (data: ICreateContract): Promise<IContract> => {
    const form = new FormData()
    for (const key in data) {
        if (key === 'legalDocument') {
            form.append(key, data[key] as File)
        } else {
            // @ts-ignore
            form.append(key, data[key])
        }
    }

    return api.create(url.POST_CONTRACTS, data, headers);
}
export const RenewContract = (id: string, data: ICreateContract): Promise<IContract> => {
    const form = new FormData()
    data.status = ContractStatus.PENDING_APPROVAL
    for (const key in data) {
        if (key === 'legalDocument') {
            form.append(key, data[key] as File)
        } else {
            // @ts-ignore
            form.append(key, data[key])
        }
    }

    return api.create(url.POST_RENEW_CONTRACTS.replace('{id}', id), data, headers);
}
export const UpdateContract = (id: string, data: IUpdateContract): Promise<IContract> => {
    const peopleRelations = ['agent', 'tenant', 'owner']
    const form = new FormData()
    for (const key in data) {
        if (key === 'legalDocument') {
            form.append(key, data[key] as File)
        } else {
            if (peopleRelations.includes(key)) {
                // @ts-ignore
                if (typeof data[key] === 'object') {
                    // @ts-ignore
                    form.append(key, data[key]._id)
                    // @ts-ignore
                } else if (typeof data[key] === 'string') {
                    // @ts-ignore
                    form.append(key, data[key])
                }
            } else {
                // @ts-ignore
                form.append(key, data[key])
            }
        }
    }
    return api.update(url.PATH_CONTRACT.replace('{id}', id), form)
};
export const DeleteContract = (id: string): Promise<void> => api.delete(url.DELETE_CONTRACT.replace('{id}', id));

export const EndContractApproved = (id: string, body: { password: string }): Promise<IContract> => api.update(url.END_CONTRACT_APPROVED.replace('{id}', id), body);