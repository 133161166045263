import { userForgetPasswordSuccess, userForgetPasswordError, setUser } from "./reducer"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
    postFakeForgetPwd,
    postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper";
import { SendResetPasswordArgs } from "types";
import * as AuthServices from "service/auth";
import { toast } from "react-toastify";

const fireBaseBackend: any = getFirebaseBackend();

export const userForgetPassword = (user: any, history: any) => async (dispatch: any) => {
    try {
        let response;
        if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

            response = fireBaseBackend.forgetPassword(
                user.email
            )

        } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
            response = postJwtForgetPwd(
                user.email
            )
        } else {
            response = postFakeForgetPwd(
                user.email
            )
        }

        const data = await response;

        if (data) {
            toast.success('Si la dirección de correo electrónico está registrada, recibirás un correo con instrucciones para restablecer tu contraseña.');
            dispatch(userForgetPasswordSuccess(
                "Reset link are sended to your mailbox, check there first"
            ))
        }
    } catch (forgetError) {
        toast.error('Error al solicitar el restablecimiento de contraseña. Por favor, intenta nuevamente.');
        dispatch(userForgetPasswordError(forgetError as string))
    }
}

export const sendResetPassword = (resetPassword: SendResetPasswordArgs, history: any) => async (dispatch: any) => {
    try {
        const response = await AuthServices.SendPasswordReset(resetPassword)
        dispatch(setUser(resetPassword))
        dispatch(userForgetPasswordSuccess(
            response.message
        ))
        /* toast(response.message, {
            type: "success",
            autoClose: 2000,
            onClose: () => {
                history('/auth-reset-password')
            }
        }) */
        toast.success('Si la dirección de correo electrónico está registrada, recibirás un correo con instrucciones para restablecer tu contraseña.');
        return response
    } catch (forgetError: any) {
        toast.error('Error al solicitar el restablecimiento de contraseña. Por favor, intenta nuevamente.');
        /* toast(forgetError.response.data.message, {
            type: "error"
        }) */
        dispatch(userForgetPasswordError(forgetError))
        throw forgetError.response.data
    }
}