export const DNITypesOptions = [
    {
        label: 'CPF',
        value: 'CPF'
    },
    {
        label: 'CPJ',
        value: 'CPJ'
    },
    {
        label: 'DIMEX',
        value: 'DIMEX'
    },
    {
        label: 'DIDI',
        value: 'DIDI'
    }
]