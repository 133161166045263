import React from "react";
import { UncontrolledCarousel } from "reactstrap";

const Slidewithindicator = ({ items } : any) => {
  console.log('valorde items por porps', items)

  return (
    <React.Fragment>
      <UncontrolledCarousel
      interval={4000}
      items={items}
        renderItem={(item: any) => (
          <img
            src={item.src}
            alt={item.altText}
            style={{ height: "100%", width: "100%", objectFit: "cover"}}
          />
        )}
      />
    </React.Fragment>
  );
};

export default Slidewithindicator;
