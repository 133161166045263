import { parseDataToForm } from "helpers/pardeDataToForm";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { ICreateProperty, IProperty } from "../types";
import { GetManyPropertiesFilters } from "types/types";
const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};

export const CreateProperty = (data: ICreateProperty) => {
    const formData = new FormData();
    formData.append("data", JSON.stringify(data));
    data.images.forEach((image: File) => {
        console.log("cargando archivos al formData", image);
        formData.append("file", image);
    });

    return api.create(url.CREATE_PROPERTY, formData, headers)
};

export interface GetAllPropertiesV2Props {
    params?: {
        owner?: string;
        propertyManager?: string;
        status?: {
            status?: boolean
        }
    }
}

export const GetProperty = (id: string): Promise<IProperty> => api.get(`${url.CREATE_PROPERTY}/${id}`);
export const GetAllProperties = (data: any) => api.get(url.GET_ALL_PROPERTIES, { "sortByPrice": "des" });
export const GetAllPropertiesV2 = (data: GetAllPropertiesV2Props) => api.create(url.GET_ALL_PROPERTIES_FILTERS_V1, data);
export const DeleteProperty = (id: string) => api.delete(`${url.DELETE_PROPERTY}/${id}`);
export const UpdateProperty = (id: string, data: ICreateProperty) => {
    const formData = new FormData()
    formData.append("data", JSON.stringify(data));
    if (data.images) {
        console.log(data.images, "data")
        data.images.forEach((image: File) => {
            formData.append("file", image);
        });
    }
    return api.put(`${url.UPDATE_PROPERTY}/${id}`, {}, formData, headers)
};
export const UpdateStateProperty = (id: any, data: any) => api.put(`${url.UPDATE_PROPERTY}/${id}/status`, data);

export const CreateFavoriteProperty = (id: string) => api.create(`${url.SET_FAVORITE_PROPERTY}`.replace('{id}', id), {});
export const DeleteFavoriteProperty = (id: string) => api.delete(`${url.DELETE_FAVORITE_PROPERTY}`.replace('{id}', id));
export const GetFavoriteProperty = (param: GetManyPropertiesFilters): Promise<{ data: Array<unknown> }> => api.get(`${url.GET_FAVORITE_PROPERTY}`, param);