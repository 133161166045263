import React, { useState, useRef, useEffect } from "react";
import { APIProvider, Map, Marker, AdvancedMarker, Pin } from '@vis.gl/react-google-maps';

import env from "configs/app.config";


const MapGoogle = ({ coords, setCoords, zoom = 13, styles }: any) => {
    const API_KEY = env.google.MAP_API_KEY;
    const [centerMap, setCenterMap] = useState<any>();

    useEffect(() => {
        setCenterMap(coords);
    }, [coords])

    const handleClickMap = (e: any) => {
        setCoords(e.detail.latLng);
        setCenterMap(e.detail.latLng);
    }
    return (
        <APIProvider apiKey={API_KEY}>
            <Map
                style={{ width: '100%', height: 400, ...styles }}
                defaultZoom={zoom}
                defaultCenter={coords}
                center={centerMap}
                zoomControl={true}
                disableDefaultUI={true}
                onClick={handleClickMap}
                mapId="2357d5d9e8bcc647"
                gestureHandling={'greedy'}
                onCameraChanged={(e) => setCenterMap(e.detail.center)}
            >
                {/* <Marker position={coords} /> */}
                <AdvancedMarker position={coords}>
                    <Pin
                        background={'#002655'}
                        borderColor={'#002655'}
                        glyphColor={'#FFFFFF'}
                    />
                </AdvancedMarker>
            </Map>
        </APIProvider>
    )
}
export default MapGoogle;
