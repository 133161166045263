import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';

import AvatarIcon from '../../../../assets/images/users/avatar-3.jpg'
import Select from "react-select";
import { C } from '@fullcalendar/core/internal-common';
import { GetContracts, UpdateContract } from 'service/contracts'
import { ContractStatus, IContract } from 'types/api/contracts';
import { toast } from 'react-toastify';
import { Role } from 'types';
import { useAppSelector } from 'slices/hooks';



type contractType = "contracts" | "offers"

interface TemplatePreviewContractsProps {
    statusFilter?: ContractStatus[];
    title?: string;
    headerTitle?: string;
    contractType?: contractType;
    itemsActions: {
        label: string,
        render?: (v: IContract) => React.ReactElement,
        action: (v: IContract, reload: () => {}) => void, key?: string,
        validation?: (v: IContract) => boolean
    }[],
}

const badgeText = {
    [ContractStatus.ACTIVE]: "Activo",
    [ContractStatus.PENDING_APPROVAL]: "APROBACIÓN PENDIENTE",
    [ContractStatus.PENDING_COMPLETION]: "FINALIZACIÓN PENDIENTE",
    [ContractStatus.COMPLETED]: "FINALIZADO",
    [ContractStatus.PENDING_PAYMENT_APPROVAL]: "APROBACIÓN DE PAGO PENDIENTE",
    [ContractStatus.REJECTED]: "RECHAZADO",

}

const badgeColor = {
    [ContractStatus.ACTIVE]: "dark",  //"#0AB39C",
    [ContractStatus.PENDING_APPROVAL]: "warning",  //"#F1A347",
    [ContractStatus.PENDING_COMPLETION]: "primary",  //"#3577F1",
    [ContractStatus.COMPLETED]: "danger",  //"#EA4335",
    [ContractStatus.PENDING_PAYMENT_APPROVAL]: "success",  //"#254F36",
    [ContractStatus.REJECTED]: "danger",  //"#EA4335",
}

const ContractBadge = ({ status }: { status: ContractStatus }) => {
    return <h5><Badge color={badgeColor[status]} className="badge-soft-primary font-size-12">{badgeText[status]}</Badge></h5>
}

const TemplatePreviewContracts = (props: TemplatePreviewContractsProps) => {
    const user = useAppSelector(state => state.Profile.user)
    document.title = props.title ?? "Visualizar contratos | Partarentals";
    const [selectedContract, setSelectedContract] = useState<IContract | null>(null);
    const navigate = useNavigate()
    const [offset, setOffset] = useState(0);
    const [skip, setSkip] = useState(0)
    const [limit, setLimit] = useState(10)
    const [contracts, setContracts] = useState<IContract[]>([])
    const [modal_center, setmodal_center] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(0)
    const [canNextPage, setCanNextPage] = useState<boolean>(true)
    const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const [showAcceptPaymentModal, setShowAcceptPaymentModal] = useState<boolean>(false);
    function tog_accept_payment() {
        setShowAcceptPaymentModal(!showAcceptPaymentModal);
    }

    const pageOptions = useMemo(() => {
        return pageSize > 0 ? new Array(Math.ceil((pageSize / limit))).fill(null).map((el, i) => i) : []
    }, [limit, pageSize])

    const [pageIndex, setPageIndex] = useState<number>(0)
    const nextPage = () => {
        if (page < pageOptions.length - 1) {
            setPage(page + 1)
        }
    }
    const previousPage = () => {
        if (page > 0) {
            setPage(page - 1)
        }
    }

    const gotoPage = (page: number) => {
        setPage(page)
    }
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [modal_declined, setmodal_declined] = useState<boolean>(false);
    function decline_payment() {
        setmodal_declined(!modal_declined);
    }

    const handleGetContracts = useCallback(async () => {
        const offset = page * limit
        const skip = offset + limit
        const res = await GetContracts({ limit, offset: offset, skip: skip, status: props.statusFilter })
        setContracts(res.data)
        setPageSize(res.count)
    }, [limit, page, props.statusFilter])

    useEffect(() => {
        handleGetContracts()
        if (page === 0) {
            setCanPreviousPage(false)
            setCanNextPage(true)
        } else if (page === pageOptions.length - 1) {
            setCanNextPage(false)
            setCanPreviousPage(true)
        } else {
            setCanNextPage(true)
            setCanPreviousPage(true)
        }
    }, [page, pageOptions.length])


    const handleFinishContract = useCallback(async () => {
        try {
            if (!selectedContract) return

            await UpdateContract(selectedContract._id, {
                status: ContractStatus.ACTIVE,
            })
            handleGetContracts()
            setShowAcceptPaymentModal(false)
            navigate("/active-contracts-preview")
            toast.success("Contrato activado...")
        } catch (error) {
            toast.error("Ha ocurrido un error al activar el contrato")
        }
    }, [selectedContract])


    const columns = useMemo(
        () => [
            {
                Header: "Propiedad",
                accessor: (contract: IContract) => <div>
                    <img src={contract.property?.images[0]?.url ?? AvatarIcon} alt="" className="rounded-circle avatar-xs material-shadow" style={{ marginRight: 10 }} />
                    <Link to={`/pages-property-details/${contract.property?._id}`} className='enlace-tabla'>Ver propiedad</Link>
                </div>,
                disableFilters: true,
                filterable: false,
            },
            props.contractType === "offers" ? {
                Header: "Fecha de envío",
                accessor: (contract: IContract) => <span>{new Date(contract.createdAt).toLocaleDateString()}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            props.contractType === "contracts" ? {
                Header: "Inicio y culminación",
                accessor: (contract: IContract) => <span>{new Date(contract.startDate).toLocaleDateString()} - {new Date(contract.endDate).toLocaleDateString()}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            {
                Header: "Precio acordado",
                accessor: (contract: IContract) => <span>${contract.agreedPrice}</span>,
                disableFilters: true,
                filterable: false,
            },
            //@ts-ignore
            [Role.Admin, Role.Owner].includes(user?.roles[0] ?? '') ? {
                Header: "Inquilino",
                accessor: (contract: IContract) => <span>{contract.tenant.name} {contract.tenant._id === user?._id && "*"}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            //@ts-ignore
            [Role.Admin, Role.Tenant].includes(user?.roles[0] ?? '') ? {
                Header: "Arrendador",
                accessor: (contract: IContract) => <span>{contract.owner.name} {contract.owner._id === user?._id && "*"}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            {
                Header: "Estado del contrato",
                accessor: (contract: IContract) => <ContractBadge status={contract.status} />,
                disableFilters: true,
                filterable: false,
            },
            props.contractType === "offers" ? {
                Header: "Inicio y culminación",
                accessor: (contract: IContract) => <span>{new Date(contract.startDate).toLocaleDateString()} - {new Date(contract.endDate).toLocaleDateString()}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            props.contractType === "contracts" ? {
                Header: "Reajuste anual",
                accessor: (contract: IContract) => <span>{contract.annualIncrement}%</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            props.contractType === "contracts" ? {
                Header: "Recarga moratorio",
                accessor: (contract: IContract) => <span>${contract.lateInterest}</span>,
                disableFilters: true,
                filterable: false,
            } : null,
            {
                Header: "Depósito garantía",
                accessor: (contract: IContract) => <span>₡{contract.deposit}</span>,
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Penalidad",
                accessor: (contract: IContract) => <span>₡{contract.terminationPenalty}</span>,
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Acciones",
                accessor: (contract: IContract) => {
                    return contract?.status === ContractStatus.PENDING_PAYMENT_APPROVAL && (contract.owner._id === user?._id || contract.agent._id === user?._id)
                        ?
                        <Button color='success' className="custom-toggle active" onClick={(e) => {
                            tog_accept_payment()
                            setSelectedContract(contract)
                        }}>
                            <span className="icon-off"><i className="ri-check-fill align-bottom me-1"></i> Aprobar pago</span>
                        </Button>
                        :
                        <div className="d-flex justify-content-start">
                            <UncontrolledDropdown className="card-header-dropdown" direction='start'>
                                <DropdownToggle tag="a" className="btn btn-link text-secondary p-2 py-1 text-decoration-none fs-15 bg-light" role="button">
                                    <span className="fs-18"><i className="mdi mdi-dots-horizontal btn-secondary"></i></span>
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                    {
                                        props.itemsActions.map((el, i) => {
                                            if (el.validation)
                                                return el.validation(contract) ? <DropdownItem key={i} onClick={() => el.action(contract, handleGetContracts)}>{el.render ? el.render(contract) : el.label}</DropdownItem> : null
                                            else return <DropdownItem key={i} onClick={() => {
                                                el.action(contract, handleGetContracts)
                                            }}>{el.render ? el.render(contract) : el.label}</DropdownItem>
                                        }
                                        )
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </div>
                },
                disableFilters: true,
                filterable: false,
            },
        ].filter(el => el),
        [props.contractType, props.itemsActions, user?._id, user?.roles]
    );


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Visualizar contratos" pageTitle="Pages" />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">{props.title}</h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            {/* <TableContainer
                                                columns={columns || []}
                                                data={contracts || []}
                                                // isPagination={true}
                                                isGlobalFilter={false}
                                                iscustomPageSize={false}
                                                isBordered={false}
                                                customPageSize={5}
                                                divClass="table-responsive"
                                                className="custom-header-css table align-middle table-nowrap"
                                                tableClassName="table-centered align-middle table-nowrap mb-0 table-responsive"
                                                theadClassName="text-muted table-light"
                                            /> */}
                                            <div className="table-responsive">
                                                <Table className="align-middle table-nowrap mb-0">
                                                    <thead className="table-light">
                                                        <tr>
                                                            {
                                                                columns.map((column, index) => (
                                                                    <th key={index} scope="col">{column?.Header}</th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            contracts.map((contract, index) => (
                                                                <tr key={contract._id}>
                                                                    {
                                                                        columns.map((column, index) => (
                                                                            <td key={index}>{column?.accessor(contract)}</td>
                                                                        ))
                                                                    }
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </Table>

                                            </div>
                                            <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                                                <div className="col-sm">
                                                    <div className="text-muted">Mostrando<span className="fw-semibold ms-1">{contracts.length}</span> de <span className="fw-semibold">{pageSize}</span> Resultados
                                                    </div>
                                                </div>
                                                <div className="col-sm-auto">
                                                    <ul className="pagination pagination-separated pagination-md justify-content-center justify-content-sm-start mb-0">
                                                        <li className={!canPreviousPage ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={previousPage}>Anterior</Link>
                                                        </li>
                                                        {pageOptions.map((item: any, key: any) => (
                                                            <React.Fragment key={key}>
                                                                <li className="page-item">
                                                                    <Link to="#" className={page === item ? "page-link active" : "page-link"} onClick={() => gotoPage(item)}>{item + 1}</Link>
                                                                </li>
                                                            </React.Fragment>
                                                        ))}
                                                        <li className={!canNextPage ? "page-item disabled" : "page-item"}>
                                                            <Link to="#" className="page-link" onClick={nextPage}>Siguiente</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardBody>


                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>


            <Modal
                isOpen={showAcceptPaymentModal}
                toggle={() => {
                    tog_accept_payment();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
                        <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => tog_accept_payment()}>Cancelar</Button>
                            <Button color="success" onClick={() => handleFinishContract()}>Aprobar</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default TemplatePreviewContracts;