import React, { useEffect, useState } from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
import { ecomWidgets } from "../../common/data";
import { GetCountAgents, GetCountProperties, GetCountRentCompleted } from "../../service/getCounts";

const Widgets = () => {
    const [widgetCounts, setWidgetCounts] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    
    const getCountAgents = async () => {
        const response: any = await GetCountAgents();
        console.log('Count Agents', response);
        const reponseCountAgents =
            {
                id: 1,
                cardColor: "primary",
                label: "Cantidad de agentes",
                badge: "ri-arrow-right-up-line",
                badgeClass: "success",
                // percentage: `+${response.count.porcentaje}`,
                counter: response.count.count,
                link: "View net earnings",
                bgcolor: "success",
                icon: "bx bx-dollar-circle",
                decimals: 0,
                prefix: "",
                suffix: ""
            };
        console.log(reponseCountAgents);
        setWidgetCounts((prevCounts: any) => [...prevCounts, reponseCountAgents]);
    }

    const getCountProperties = async () => {
        const response: any = await GetCountProperties();
        console.log('Count Properties', response);
        const responseCountProperties =
            {
                id: 1,
                cardColor: "primary",
                label: "Cantidad de propiedades",
                badge: "ri-arrow-right-up-line",
                badgeClass: "success",
                // percentage: `+${response.count.porcentaje}`,
                counter: response.count,
                link: "View net earnings",
                bgcolor: "success",
                icon: "bx bx-dollar-circle",
                decimals: 0,
                prefix: "",
                suffix: ""
            };
        setWidgetCounts((prevCounts: any) => [...prevCounts, responseCountProperties]);
    }

    const getCountRentCompleted = async () => {
        const response: any = await GetCountRentCompleted();
        console.log('Count Rents', response);
        const reponseCountRentCompleted =
            {
                id: 1,
                cardColor: "primary",
                label: "Alquileres completados",
                badge: "ri-arrow-right-up-line",
                badgeClass: "success",
                // percentage: `+${response.count.porcentaje}`,
                counter: response.count.count,
                link: "View net earnings",
                bgcolor: "success",
                icon: "bx bx-dollar-circle",
                decimals: 0,
                prefix: "",
                suffix: ""
            };
        setWidgetCounts((prevCounts: any) => [...prevCounts, reponseCountRentCompleted]);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                await Promise.all([getCountAgents(), getCountProperties(), getCountRentCompleted()]);
                setIsLoading(false);
            } catch (error) {
                // Manejar errores
                setIsLoading(false);
            }
        };

        fetchData();
    }, [])

    return (
        <React.Fragment>
            {!isLoading && (<>
            {widgetCounts.map((item: any, key: number) => (
                <Col xl={4} md={6} key={key}>
                    <Card className="card-animate">
                        <CardBody>
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-uppercase fw-medium text-muted text-truncate mb-0">{item.label}</p>
                                </div>
                                {/* <div className="flex-shrink-0">
                                    <h5 className={"fs-14 mb-0 text-" + item.badgeClass}>
                                        {item.badge ? <i className={"fs-13 align-middle " + item.badge}></i> : null} {item.percentage} %
                                    </h5>
                                </div> */}
                            </div>
                            <div className="d-flex align-items-end justify-content-between mt-4">
                                <div>
                                    <h4 className="fs-22 fw-semibold ff-secondary mb-4"><span className="counter-value" data-target="559.25">
                                        <CountUp
                                            start={0}
                                            prefix={item.prefix}
                                            suffix={item.suffix}
                                            separator={item.separator}
                                            end={item.counter}
                                            decimals={item.decimals}
                                            duration={4}
                                        />
                                    </span></h4>
                                    {/* <Link to="#" className="text-decoration-underline">{item.link}</Link> */}
                                </div>
                                {/* <div className="avatar-sm flex-shrink-0">
                                    <span className={"avatar-title rounded fs-3 bg-" + item.bgcolor+"-subtle"}>
                                        <i className={`text-${item.bgcolor} ${item.icon}`}></i>
                                    </span>
                                </div> */}
                            </div>
                        </CardBody>
                    </Card>
                </Col>))}
            </>)}
        </React.Fragment>
    );
};

export default Widgets;