import { Link } from "react-router-dom";
import Loader from "../../../Components/loader/loader";
import EditIcon from "../../../assets/images/icons/edit.png";
import TrashIcon from "../../../assets/images/icons/trash.png";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import React, { useMemo, useEffect, useState, useRef } from "react";
import TableContainer from "../../../Components/Common/TablePartaRentals";
import AgentModalIcon from "../../../assets/images/modals/agent-modal-icon.png";
import {
  GetAgents,
  CreateAgents,
  DeleteAgents,
  UpdateAgents,
} from "../../../service/agents";
import {
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
  Card,
} from "reactstrap";

const Agents = () => {
  const currentDate = new Date();
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState<boolean>(false);
  const [agentsInfo, setAgentsInfo] = useState<any>();
  const [selectedFiles, setselectedFiles] = useState<any>();
  const [previewImages, setPreviewImages] = useState<any>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [modalUpdate, setModalUpdate] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [idAgent, setIdAgent] = useState<string>("");
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    phone: "",
    image: "",
    company: "",
  });
  const [errors, setErrors] = useState({
    fullName: "",
    email: "",
    phone: "",
    image: "",
  });
  const getAllAgents = async () => {
    const response = await GetAgents();
    setAgentsInfo(response);
    setRefresh(false);
    setLoading(false);
  };

  const saveAgent = async () => {
    setLoading(true);
    const newErrors = {
      fullName: "",
      email: "",
      phone: "",
      image: "",
    };

    if (!formValues.fullName.trim())
      newErrors.fullName = "El nombre es requerido";
    if (!formValues.email.trim())
      newErrors.email = "El correo electrónico es requerido";
    if (!formValues.phone.trim()) newErrors.phone = "El teléfono es requerido";
    if (!selectedFiles || selectedFiles.length === 0)
      newErrors.image = "La imagen es requerida";

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      setLoading(false);
      return;
    }
    setModal(false);

    const formData = new FormData();
    console.log("valor de imagen agentes", selectedFiles[0]);
    formData.append("fullName", formValues.fullName);
    formData.append("email", formValues.email);
    formData.append("phone", formValues.phone);
    formData.append("company", formValues.company);
    formData.append("image", selectedFiles[0] as File);
    formData.append("created", currentDate.toString());
    formData.append("updated", currentDate.toString());

    const response = await CreateAgents(formData);
    setRefresh(true);
    setLoading(false);
    await getAllAgents();
    console.log("valor de respuesta", response);
  };

  const deleteAgent = async (id: string) => {
    setLoading(true);
    console.log("valor del id del Agente!!!", id);
    const response = await DeleteAgents(id);
    setLoading(false);
    setModalDelete(false);
    setRefresh(true);
  };
  const updateDataAgent = (data: any) => {
    console.log("update data!!!", data);
    setIdAgent(data._id);
    setFormValues({
      ...formValues,
      fullName: data.fullName,
      company: data.company,
      phone: data.phone,
      email: data.email,
    });
    setModalUpdate(true);
  };

  const clearFormValues = () => {
    setFormValues({
      fullName: "",
      email: "",
      phone: "",
      image: "",
      company: "",
    });
    setPreviewImages(null);
    setselectedFiles([]);
  };

  const updateAgent = () => {
    setModal(false);
    const data = {
      fullName: formValues.fullName,
      company: formValues.company,
      email: formValues.email,
      phone: formValues.phone,
      image: "",
      created: currentDate.toString(),
      updated: currentDate.toString(),
    };
    console.log("valor de idAgents", idAgent);
    const response = UpdateAgents(data, idAgent);
    setModalUpdate(false);
    setRefresh(true);
  };

  useEffect(() => {
    setLoading(true);
    getAllAgents();
  }, [refresh]);

  function handleAcceptedFiles(event: React.ChangeEvent<HTMLInputElement>) {
    const images: any = event.target.files;
    setselectedFiles(images);
    setPreviewImages(URL.createObjectURL(images[0]));
  }

  const columns = useMemo(
    () => [
      {
        Header: "Nombre completo",
        accessor: (cellProps: any) => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <div className="flex-shrink-0">
                {cellProps.image && (
                  <img
                    src={cellProps.image}
                    alt=""
                    className="avatar-xs rounded-circle"
                  />
                )}
              </div>
              <div className="flex-grow-1">{cellProps.fullName}</div>
            </div>
          );
        },
        disableFilters: true,
        filterable: false,
      },

      {
        Header: "Teléfono",
        accessor: "phone",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Compañía",
        accessor: "company",
        disableFilters: true,
        filterable: false,
      },
      {
        Header: "Action",
        accessor: (cellProps: any) => {
          return (
            <div className="d-flex gap-2 align-items-center">
              <div className="flex-shrink-0 table-action-button-edit">
                {
                  <img
                    src={EditIcon}
                    alt=""
                    onClick={() => {
                      updateDataAgent(cellProps);
                    }}
                  />
                }
              </div>
              <div className="flex-shrink-0 table-action-button-trash">
                {
                  <img
                    src={TrashIcon}
                    alt=""
                    onClick={() => {
                      setModalDelete(true);
                      setIdAgent(cellProps._id);
                    }}
                  />
                }
              </div>
            </div>
          );
        },
        disableFilters: true,
        filterable: false,
      },
    ],
    []
  );

  document.title = "Agentes";
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Modal
            isOpen={modal}
            // toggle={() => { tog_varying1(); }}
            id="exampleModal"
          >
            <ModalHeader
              className="modal-title"
              toggle={() => {
                setModal(false);
              }}
            >
              Añadir agente
            </ModalHeader>
            <ModalBody>
              <form>
                <div className="dropzone dz-clickable">
                  <div
                    className="dz-message needsclick"
                    onClick={() =>
                      fileInputRef.current && fileInputRef.current.click()
                    }
                  >
                    <div className="mb-3">
                      <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                    </div>
                    <input
                      multiple
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleAcceptedFiles}
                    />
                    <h4>Subir imagen</h4>
                  </div>
                </div>
                <div className="mb-3">
                  {previewImages && (
                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              src={previewImages}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                            >
                              {selectedFiles[0].name}
                            </Link>
                            <p className="mb-0">
                              <strong>{selectedFiles[0].size}</strong>
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                  {errors.image && (
                    <div style={{ color: "red" }}>{errors.image}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Nombre y apellido
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="customer-name"
                    value={formValues.fullName}
                    onChange={(e) =>
                      setFormValues({ ...formValues, fullName: e.target.value })
                    }
                  />
                  {errors.fullName && (
                    <div style={{ color: "red" }}>{errors.fullName}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Correo electrónico
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="customer-name"
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues({ ...formValues, email: e.target.value })
                    }
                  />
                  {errors.email && (
                    <div style={{ color: "red" }}>{errors.email}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Teléfono (WhatsApp)
                  </label>
                  <Input
                    type="text"
                    placeholder="Ejemplo: 50612345678"
                    className="form-control"
                    id="customer-name"
                    value={formValues.phone}
                    onChange={(e) =>
                      setFormValues({ ...formValues, phone: e.target.value })
                    }
                  />
                  {errors.phone && (
                    <div style={{ color: "red" }}>{errors.phone}</div>
                  )}
                </div>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Compañía
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="customer-name"
                    value={formValues.company}
                    onChange={(e) =>
                      setFormValues({ ...formValues, company: e.target.value })
                    }
                  />
                </div>
              </form>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => saveAgent()} color="primary">
                Guardar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={modalUpdate}
            // toggle={() => { tog_varying1(); }}
            id="exampleModal"
          >
            <ModalHeader
              className="modal-title"
              toggle={() => {
                setModalUpdate(false);
              }}
            >
              Actualizar agente
            </ModalHeader>
            <ModalBody>
              <form>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Nombre y apellido
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="customer-name"
                    value={formValues.fullName}
                    onChange={(e) =>
                      setFormValues({ ...formValues, fullName: e.target.value })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Correo electrónico
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="customer-name"
                    value={formValues.email}
                    onChange={(e) =>
                      setFormValues({ ...formValues, email: e.target.value })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Teléfono (WhatsApp)
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="customer-name"
                    value={formValues.phone}
                    onChange={(e) =>
                      setFormValues({ ...formValues, phone: e.target.value })
                    }
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="customer-name" className="col-form-label">
                    Compañía
                  </label>
                  <Input
                    type="text"
                    className="form-control"
                    id="customer-name"
                    value={formValues.company}
                    onChange={(e) =>
                      setFormValues({ ...formValues, company: e.target.value })
                    }
                  />
                </div>
                {/* <div className="mb-3">
                                <label htmlFor="message-text" className="col-form-label">Message:</label>
                            </div> */}
              </form>
            </ModalBody>
            <ModalFooter>
              <Button onClick={() => updateAgent()} color="primary">
                Guardar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            id="success-Payment"
            tabIndex={1}
            isOpen={modalDelete}
            centered
          >
            <ModalBody className="text-center p-5">
              <div className="text-end">
                <button
                  type="button"
                  onClick={() => setModalDelete(false)}
                  className="btn-close text-end"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="mt-2">
                {/* <i className="ri-chat-smile-2-line display-2 text-success"></i> */}
                <img src={AgentModalIcon} />
                <h4 className="mb-3 mt-4">
                  ¿Estás seguro de eliminar a este agente?
                </h4>
                <p className="text-muted fs-15 mb-4">
                  Esta acción es permanente
                </p>
                <div className="hstack gap-2 justify-content-center">
                  <button
                    className="btn btn-soft-success"
                    onClick={() => setModalDelete(false)}
                  >
                    Cancelar
                  </button>
                  <button
                    onClick={() => deleteAgent(idAgent)}
                    className="btn btn-primary"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Container fluid>
            <BreadCrumb title="Agentes" pageTitle="Pages" />
            <Row style={{ marginBottom: 20 }}>
              <Col className="d-flex justify-content-end">
                <Button
                  color="primary"
                  onClick={() => {
                    setModal(true);
                    clearFormValues();
                  }}
                >
                  Añadir Agente
                </Button>
              </Col>
            </Row>
            <Row>
              <div className="table-background-parta-rentals">
                <TableContainer
                  columns={columns || []}
                  data={agentsInfo || []}
                  // isPagination={true}
                  isGlobalFilter={true}
                  iscustomPageSize={false}
                  isBordered={false}
                  customPageSize={5}
                  divClass="table-responsive"
                  className="custom-header-css table align-middle table-nowrap"
                  tableClassName="table-centered align-middle table-nowrap mb-0 table-responsive"
                  theadClassName="text-muted table-light"
                  SearchPlaceholder="Buscar agentes"
                />
              </div>
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default Agents;
