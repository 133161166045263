import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button, Spinner, } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, resetRegisterFlag } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

//import images 
import logoLight from "../../assets/images/logo-light.png";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { createSelector } from "reselect";
import GoogleIcon from "../../assets/images/brands/google.png";
import { SignUpUserArgs } from "types";
import { useAppSelector } from "slices/hooks";
import GoogleLogInComponent from "Components/Common/GoogleLogIn";

const Register = () => {
    const history = useNavigate();
    const dispatch: any = useDispatch();
    const Account = useAppSelector((state) => state.Account);
    const { success, error } = useAppSelector((state) => state.Account);
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const navigate = useNavigate();
    const validation = useFormik<SignUpUserArgs>({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            surname: '',
            email: '',
            password: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Por favor ingresa tu nombre"),
            surname: Yup.string().required("Por favor ingresa tu apellido"),
            email: Yup.string().required("Por favor ingresa tu correo"),
            password: Yup.string().required("Por favor Introduce tu contraseña"),
        }),
        onSubmit: (values) => {
            dispatch(registerUser(values));
        },
    });

    useEffect(() => {
        console.log(Account.user)
        if (Account.user) {
            navigate('/auth-twostep-basic')
        }
    }, [Account.user, navigate])

    /* useEffect(() => {
        if (success) {
            setTimeout(() => history("/login"), 3000);
        }

        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]); */

    document.title = "Registro | PartaRentals";

    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block auth-logo">
                                            <img src={logoLight} alt="" height="30" />
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Busca tu apartamento, consigue tu independencia</p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">

                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Crea tu cuenta</h5>
                                            <p className="text-muted">Regístrate, es fácil y rápido</p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit()
                                                    return false;
                                                }}
                                                className="needs-validation" action="#">

                                                {success && success ? (
                                                    <>
                                                        {toast("Your Redirect To Login Page...", { position: "top-right", hideProgressBar: false, className: 'bg-success text-white', progress: undefined, toastId: "" })}
                                                        <ToastContainer autoClose={2000} limit={1} />
                                                        <Alert color="success">
                                                            Register User Successfully and Your Redirect To Login Page...
                                                        </Alert>
                                                    </>
                                                ) : null}

                                                {error && error ? (
                                                    <Alert color="danger"><div>
                                                        Email has been Register Before, Please Use Another Email Address... </div></Alert>
                                                ) : null}
                                                <Row>
                                                    <Col>
                                                        <div className="mb-3">
                                                            <Label htmlFor="name" className="form-label">Nombre <span className="text-danger">*</span></Label>
                                                            <Input
                                                                id="name"
                                                                name="name"
                                                                className="form-control"
                                                                placeholder="Introduce tu nombre"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.name || ""}
                                                                invalid={
                                                                    validation.touched.name && validation.errors.name ? true : false
                                                                }
                                                            />
                                                            {validation.touched.name && validation.errors.name ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.name}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="mb-3">
                                                            <Label htmlFor="surname" className="form-label">Apellido <span className="text-danger">*</span></Label>
                                                            <Input
                                                                id="surname"
                                                                name="surname"
                                                                className="form-control"
                                                                placeholder="Introduce tu apellido"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.surname || ""}
                                                                invalid={
                                                                    validation.touched.surname && validation.errors.surname ? true : false
                                                                }
                                                            />
                                                            {validation.touched.surname && validation.errors.surname ? (
                                                                <FormFeedback type="invalid"><div>{validation.errors.surname}</div></FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Correo electrónico <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="email"
                                                        type="email"
                                                        placeholder="Introduce tu correo electrónico"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                    ) : null}

                                                </div>

                                                {/* <div className="mb-3">
                                                    <Label htmlFor="phone" className="form-label">Phone <span className="text-danger">*</span></Label>
                                                    <Input
                                                        name="phone"
                                                        type="number"
                                                        placeholder="Enter phone"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phone || ""}
                                                        invalid={
                                                            validation.touched.phone && validation.errors.phone ? true : false
                                                        }
                                                    />
                                                    {validation.touched.phone && validation.errors.phone ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.phone}</div></FormFeedback>
                                                    ) : null}

                                                </div> */}

                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">Contraseña <span className="text-danger">*</span></Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            placeholder="Confirmar contraseña"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />

                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                        ) : null}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="mb-4">
                                                    <p className="mb-0 fs-12 text-muted">Al registrarse aceptas los
                                                        {" "}<Link to="#" className="fw-semibold text-primary text-decoration-underline">Terminos y condiciones</Link></p>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="w-100" type="submit" disabled={Account.loading && true}>
                                                        {Account.loading && <Spinner size="sm" className='me-2'> Loading... </Spinner>}
                                                        Registrarse
                                                    </Button>
                                                </div>

                                                {/* <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>
                                                    </div>

                                                    <div>
                                                        <button type="button" className="btn btn-primary btn-icon waves-effect waves-light"><i className="ri-facebook-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-danger btn-icon waves-effect waves-light"><i className="ri-google-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-dark btn-icon waves-effect waves-light"><i className="ri-github-fill fs-16"></i></button>{" "}
                                                        <button type="button" className="btn btn-info btn-icon waves-effect waves-light"><i className="ri-twitter-fill fs-16"></i></button>
                                                    </div>
                                                </div> */}
                                            </Form>
                                        </div>
                                        <div className="mt-4 text-center">
                                            <div className="signin-other-title">
                                                <h5 className="fs-13 mb-4 title">O</h5>
                                            </div>
                                        </div>

                                        <div style={{
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            <GoogleLogInComponent texts="signup_with" />
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">¿Ya tienes una cuenta? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Accede ahora </Link> </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth >
        </React.Fragment >
    );
};

export default Register;
