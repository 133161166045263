import React, { ImgHTMLAttributes } from 'react';
import { Badge, Card, CardBody, Col, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import "yet-another-react-lightbox/styles.css";

import BathIcon from '../../../assets/images/icons/bath-sink-icon.png'
import BedroomIcon from '../../../assets/images/icons/bedroom-icon.png'
import CarIcon from '../../../assets/images/icons/car-icon.png'
import RuleIcon from '../../../assets/images/icons/rule-meter-icon.png'
import FeatherIcon from "feather-icons-react";
import { Link } from 'react-router-dom';

const badgeColor = {
    active: "success",
    inactive: "danger",
    "in-review": "warning"
}

const badgeText = {
    active: "ACTIVO",
    inactive: "INACTIVO",
    "in-review": "EN REVISIÓN"
}

const frequencyText = {
    monthly: "al mes",
    daily: "al día",
    yearly: "al año",
    weekly: "a la semana"
}

export type Status = "active" | "inactive" | "in-review"
export type Frequency = "monthly" | "daily" | "yearly" | "weekly"

export interface PropertyCardProps {
    onView: () => void;
    onEdit: () => void;
    onRemove: () => void;
    onStar?: () => void;
    onLike?: () => void;
    _id: string;
    img: ImgHTMLAttributes<HTMLImageElement>["src"];
    status: Status;
    address: string;
    priceDollars: number;
    pricePounds: number;
    payFrequency: Frequency;
    bedroomAmount: number;
    bathroomAmount: number;
    parkingAmount: number;
    sizeArea: number;
    description: string;
    owner?: boolean;
    star?: boolean;
    like?: boolean;
}

function PropertyCard(props: PropertyCardProps) {
    return (
        <Card>
            {
                <div className="cover-property position-relative" style={props.img ? { backgroundImage: `url(${props.img})` } : { backgroundColor: "#bebebe" }}>
                    {
                        <>
                            {
                                props.onStar && <div className="estrella" onClick={props.onStar}>
                                    <i className={props.star
                                        ? "ri-star-fill"
                                        : "ri-star-line"}
                                        style={{ fontSize: '20px', color: props.star ? '#1BCEA3' : '#bebebe' }} />
                                </div>
                            }
                            {
                                props.onLike && <div className="estrella" onClick={props.onLike}>
                                    <i className={props.like
                                        ? "ri-heart-fill"
                                        : "ri-heart-line"}
                                        style={{ fontSize: '20px', color: props.like ? '#F06548' : '#bebebe' }} />
                                </div>
                            }
                        </>
                    }
                </div>
            }

            <CardBody>
                <h5>
                    <Badge color={badgeColor[props.status]} className='m-0'>
                        {badgeText[props.status]}
                    </Badge>
                </h5>

                <h4 className="title-property">{props.description}</h4>
                <p className="card-text price-number">${props.priceDollars} | ₡{props.pricePounds} <span className='normal-month'>{frequencyText[props.payFrequency]}</span></p>
                <p className="address-property"><i className="ri-map-pin-2-fill" /> {props.address}</p>
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <div className="mr-2">
                            <i className="ri-hotel-bed-fill me-2" style={{ fontSize: '20px' }} />
                            <span className='caracteristicas-propiedades'>{props.bedroomAmount}</span>
                        </div>
                        <div className="mr-2">
                            <i className="bx bxs-bath me-2" style={{ fontSize: '20px', lineHeight: 'inherit' }} />
                            <span className='caracteristicas-propiedades'>{props.bathroomAmount}</span>
                        </div>
                        <div className="mr-2">
                            <i className="ri-parking-box-fill me-2" style={{ fontSize: '20px' }} />
                            <span className='caracteristicas-propiedades'>{props.parkingAmount}</span>
                        </div>

                    </div>
                    <div className="">
                        <i className="ri-ruler-fill me-2" style={{ fontSize: '20px' }} />
                        <span className='caracteristicas-propiedades'>{props.sizeArea} m²</span>
                    </div>
                </div>
                <hr />
                <Row>
                    <Col xs={12} className='mb-3'>
                        {props.owner &&
                            <button type='button' onClick={props.onView} className='btn btn-outline-success waves-effect waves-light material-shadow-none w-100'>Detalles</button>
                        }
                    </Col>
                    <Col xs={6}>
                        {props.owner &&
                            <button type='button' onClick={props.onEdit} disabled={props.status === 'inactive'} className='btn btn-outline-primary waves-effect waves-light material-shadow-none w-100'>Editar</button>
                        }
                    </Col>
                    <Col xs={6}>
                        {props.owner &&
                            <button type='button' onClick={props.onRemove} disabled={props.status === 'inactive'} className='btn btn-outline-danger waves-effect waves-light material-shadow-none w-100'>Eliminar</button>
                        }
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}



export default PropertyCard
