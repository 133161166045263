import { ICurrency, IProperty, IUser } from "types/entities"

type ContractType = "new" | "extended"

export enum ContractStatus {
    ACTIVE = 'active',
    PENDING_COMPLETION = 'pendingCompletion',
    COMPLETED = 'completed',
    PENDING_APPROVAL = 'pendingApproval',
    PENDING_PAYMENT_APPROVAL = 'pendingPaymentApproval',
    REJECTED = 'rejected',
  }

export enum PenaltyPeriod {
  TWELVE_MONTHS = 12,
  TWENTY_FOUR_MONTHS = 24,
  THIRTY_SIX_MONTHS = 36,
}
export interface ICreateContract {
        tenantId: string;       // ID del inquilino
        ownerId: string;        // ID del dueño
        currencyId: string;     // ID de la moneda
        propertyId: string;     // ID de la propiedad
        startDate: string;      // Fecha de inicio del contrato (formato ISO 8601)
        endDate: string;        // Fecha de finalización del contrato (formato ISO 8601)
        agreedPrice: number;    // Precio acordado para el alquiler
        paymentDate: string;    // Fecha de pago del alquiler (formato ISO 8601)
        annualIncrement: number;// Incremento anual del alquiler en porcentaje
        lateInterest: number;   // Interés moratorio en porcentaje
        deposit: number;        // Depósito de garantía para el alquiler
        paymentMethod: string;  // Método de pago acordado para el alquiler
        status: ContractStatus;         // Estado del contrato
        contractType: ContractType;   // Tipo de contrato
        gracePeriod: number;    // Período de gracia para el pago del alquiler en días
        penaltyPeriod: PenaltyPeriod;  // Período de penalización por retraso en el pago del alquiler
        legalDocument?: File;   // Documento legal (opcional, formato binario)        
        terminationPenalty: number;     
}

export interface IUpdateContract {
  tenantId?: string;       // ID del inquilino
  ownerId?: string;        // ID del dueño
  currencyId?: string;     // ID de la moneda
  propertyId?: string;     // ID de la propiedad
  startDate?: string;      // Fecha de inicio del contrato (formato ISO 8601)
  endDate?: string;        // Fecha de finalización del contrato (formato ISO 8601)
  agreedPrice?: number;    // Precio acordado para el alquiler
  paymentDate?: string;    // Fecha de pago del alquiler (formato ISO 8601)
  annualIncrement?: number;// Incremento anual del alquiler en porcentaje
  lateInterest?: number;   // Interés moratorio en porcentaje
  deposit?: number;        // Depósito de garantía para el alquiler
  paymentMethod?: string;  // Método de pago acordado para el alquiler
  status?: ContractStatus;         // Estado del contrato
  contractType?: ContractType;   // Tipo de contrato
  gracePeriod?: number;    // Período de gracia para el pago del alquiler en días
  penaltyPeriod?: PenaltyPeriod;  // Período de penalización por retraso en el pago del alquiler
  legalDocument?: File;   // Documento legal (opcional, formato binario)        
  terminationPenalty?: number;     
}

export interface IContract {
    _id: string
    tenant: IUser
    owner: IUser
    agent: IUser
    currency: ICurrency
    property: IProperty
    startDate: string
    endDate: string
    agreedPrice: number
    paymentDate: string
    annualIncrement: number
    lateInterest: number
    deposit: number
    paymentMethod: string
    status: ContractStatus    
    contractType: ContractType;
    gracePeriod: number;
    penaltyPeriod: PenaltyPeriod;
    legalDocument?: string;
    terminationPenalty: number;     
    createdAt: string
    updatedAt: string
    __v: number
}

