//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeRegister,
  postJwtRegister,
} from "../../../helpers/fakebackend_helper";

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  registerUserLoading,
} from "./reducer";
import { Register } from "service/auth";
import { Role, SignUpUserArgs } from "types";

// initialize relavant method of both Auth
const fireBaseBackend: any = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
export const registerUser = (user: SignUpUserArgs) => async (dispatch: any) => {
  try {

    dispatch(registerUserLoading());
    let response = await Register({
      ...user,
      role: Role.Tenant,
      dni: "123456789",
      address: "Calle 123",
    });
    //response = Register(user);
    //response = postFakeRegister(user);
    //const data : any = await response;
    const data: any = {
      message: "success",
      user: response,
    };

    console.log(response)

    if (data.message === "success") {
      dispatch(registerUserSuccessful(data));
    } else {
      dispatch(registerUserFailed(data));
    }

  } catch (error: any) {
    dispatch(registerUserFailed(error));
  }
};

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};

