import React, { useState, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import { Link, useParams } from "react-router-dom";
import L, { LatLng, divIcon } from "leaflet";
import "./custom-marker.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../Components/loader/loader";
import BreadCrumb from "../../../../Components/Common/BreadCrumb";
import MapGoogle from "../../../Maps/GoogleMaps/GoogleMapIntegration";
import {
  GetProperty,
  UpdateStateProperty,
  DeleteProperty,
} from "../../../../service/properties";
import Slidewithindicator from "../../../BaseUi/UiCarousel/CarouselTypes/slidewithindicator";
import {
  TileLayer,
  Marker,
  Popup,
  MapContainer,
  useMapEvents,
  useMap,
} from "react-leaflet";
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Col,
  Button,
  Label,
  Row,
  Input,
  ListGroup,
  ListGroupItem,
  Badge,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { ICreateProperty, IProperty, Status } from "types";
import classnames from "classnames";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Scrollbar, EffectFade, EffectCreative, Mousewheel, EffectFlip, EffectCoverflow, Autoplay } from "swiper/modules";
import { currencies } from "common/data";
import { PropertyCardProps } from "Components/UI/PropertyCard";

type Coordinates = {
  lat: number;
  lng: number;
};

const LocationMarker = ({ coord }: { coord: Coordinates }) => {
  const mapRefresh = useMap();
  const customIcon = divIcon({
    className: "custom-marker-icon",
    html: `<i class="mdi mdi-map-marker icon-large"></i>`,
    iconSize: [41, 41],
  });

  console.log("valor de coordenadas!!!", coord);
  const [position, setPosition] = useState<LatLng | null>(null);

  useEffect(() => {
    console.log("Entre aqui!!!");
    setPosition(new LatLng(coord.lat, coord.lng));
    mapRefresh.setView([coord.lat, coord.lng], 13);
  }, [coord]);

  const map = useMapEvents({
    click() {
      map.locate();
    },
    locationfound(e) {
      console.log("evento maps", e.latlng);
      setPosition(e.latlng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker position={coord} icon={customIcon}>
      <Popup>You are here</Popup>
    </Marker>
  );
};

const badgeColor = {
  active: "success",
  inactive: "danger",
  "in-review": "warning"
}

const badgeText = {
  active: "ACTIVO",
  inactive: "INACTIVO",
  "in-review": "EN REVISIÓN"
}

const parseStatus = (props: Status): PropertyCardProps["status"] => {
  if (props) {
    const { status, sold } = props
    if (status && sold) {
      return "inactive";
    } else if (status && !sold) {
      return "active";
    } else if (!status && !sold) {
      return "inactive";
    } else {
      return "in-review";
    }
  } else {
    return "in-review";
  }
}

const partaRentalGreen = '#00c689'
const activeClass = {
  color: partaRentalGreen
}

const PropertyDetails = () => {
  const { _id } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  console.log("valor de id!!", _id);
  const rows: any = [];
  const [loading, setLoading] = useState(false);
  const [detailsProperty, setDetailsProperty] = useState<IProperty>();
  const [detailsCharacteristics, setDetailsCharacteristics] = useState<any>([]);
  const [detailsAmenities, setDetailsAmenities] = useState<any>([]);
  const [detailsIncludedServices, setDetailsIncludedServices] = useState<any>(
    []
  );
  const [detailsSurroundings, setDetailsSurroundings] = useState<any>([]);
  const [detailsImages, setDetailsImages] = useState<any>([]);
  const [propertyStatus, setPropertyStatus] = useState<any>([]);
  const [formValues, setFormValues] = useState({
    status: { label: "Activo", value: true },
    slow: false,
    sold: false,
  });

  const characteristicsToDisplay = [
    { key: "airConditioning", label: "Aire acondicionado" },
    { key: "garden", label: "Jardín / Patio" },
    { key: "grill", label: "Parrilla" },
    { key: "microwave", label: "Microondas" },
    { key: "rampOrElevator", label: "Rampa o Ascensor" },
    { key: "refrigerator", label: "Refrigerador" },
    { key: "terrace", label: "Terraza" },
    { key: "toaster", label: "Tostadora" },
    { key: "walkingCloset", label: "Walk-in Closet" },
    { key: "waterHeater", label: "Calentador de agua" },
    { key: "coffeeMachine", label: "Maquina de café" },
    { key: "dryer", label: "Secadora" },
    { key: "kitchen", label: "Cocina" },
    { key: "washingMachine", label: "Lavadora" },
    { key: "panoramicView", label: "Vista panoramica" },
    { key: "furnished", label: "Amoblado / Semi Amoblado" },
    { key: "indoorParking", label: "Parqueo con techo" },
    { key: "petFriendly", label: "Pet Friendly" },
  ];

  const amenitiesToDisplay = [
    { key: "BBQranch", label: "Rancho BBQ" },
    { key: "gym", label: "Gimnasio" },
    { key: "petArea", label: "Área de mascotas" },
    { key: "pool", label: "Piscina" },
    { key: "security", label: "Seguridad 24/7" },
    { key: "sauna", label: "Sauna" },
    { key: "tennisCourt", label: "Cancha de tenis" },
    { key: "playground", label: "Parque infantil" },
  ];

  const includedServicesToDisplay = [
    { key: "electricity", label: "Servicio electrico" },
    { key: "internet", label: "Internet" },
    { key: "maintenanceFee", label: "Cuota de mantenimiento" },
    { key: "tv", label: "TV por cable" },
    { key: "water", label: "Agua" },
  ];

  const includedSurroundings = [
    { key: "hospital", label: "Hospital" },
    { key: "malls", label: "Centros comerciales" },
    { key: "publicTransport", label: "Transporte publico" },
    { key: "school", label: "Escuela" },
    { key: "supermarkets", label: "Supermercado" },
  ];

  const options = [
    {
      label: "Activo",
      value: true,
    },
    {
      label: "Desactivado",
      value: false,
    },
  ];

  const tabChange = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getProperty = async () => {
    if (!_id) return;
    const response: any = await GetProperty(_id);
    console.log("valor de response!!!!", response);
    setDetailsProperty(response);

    const images = response.images.map((item: any, index: number) => ({
      altText: " ",
      caption: " ",
      key: index,
      src: item.url,
    }));

    const statusProperties = {
      status: response.status.status
        ? { label: "Activo", value: true }
        : { label: "Desactivado", value: false },
      slow: response.status.slow ? true : false,
      sold: response.status.sold ? true : false,
    };

    console.log("status properties", statusProperties);

    setFormValues(statusProperties);

    setDetailsImages((prevImages: any) => [...prevImages, ...images]);

    const trueCharacteristics = Object.entries(
      response.additionalCharacteristics
    )
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueCharacteristics.pop();

    const trueAmenities = Object.entries(response.amenities)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueAmenities.pop();

    const trueServices = Object.entries(response.includedServices)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueServices.pop();

    const trueSurroundings = Object.entries(response.surroundings)
      .filter(([key, value]) => value)
      .map(([key, value]) => key);

    trueSurroundings.pop();

    const selectedTrueCharacteristics = characteristicsToDisplay.filter(
      (characteristic) => trueCharacteristics.includes(characteristic.key)
    );

    const selectedTrueAmenities = amenitiesToDisplay.filter((characteristic) =>
      trueAmenities.includes(characteristic.key)
    );

    const selectedTrueServices = includedServicesToDisplay.filter(
      (characteristic) => trueServices.includes(characteristic.key)
    );

    const selectedTrueSurroundings = includedSurroundings.filter(
      (characteristic) => trueSurroundings.includes(characteristic.key)
    );

    setDetailsCharacteristics(selectedTrueCharacteristics);
    setDetailsAmenities(selectedTrueAmenities);
    setDetailsIncludedServices(selectedTrueServices);
    setDetailsSurroundings(selectedTrueSurroundings);
    setLoading(false);
  };

  const deleteProperty = async (id: any) => {
    setLoading(true);
    console.log("valor del id del Agente!!!", id);
    const response = await DeleteProperty(id);
    setLoading(false);
    navigate("/pages-properties");
  };

  const updateStateProperty = async () => {
    setLoading(true);
    console.log("VALOR DE DATA A ACTUALIZAR", formValues);
    const states = {
      status: formValues.status.value,
      slow: formValues.slow,
      sold: formValues.sold,
    };
    const response = await UpdateStateProperty(_id, states);
    setLoading(false);
    console.log("respuesta actualizar estado de la propiedad", response);
    console.log("valor de estados PROPERTY!!!", states);
  };

  const handleRadioChange = (event: any) => {
    const { value } = event.target;
    console.log("valor de value", value);
    setFormValues({
      ...formValues,
      slow: value === "slow" ? true : false,
      sold: value === "sold" ? true : false,
    });
  };

  useEffect(() => {
    setLoading(true);
    getProperty();
    // setRefresh(false);
  }, []);

  document.title = "Detalle de una propiedad";
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Detalle de una propiedad" pageTitle="Pages" />

            <Row>
              <Col xl={12}>
                <Card >
                  <Row>
                    <Col lg={12}>
                      <CardBody>
                        <Swiper modules={[Navigation, Pagination, Autoplay]} pagination={{ clickable: true }} navigation={true} loop={true} autoplay={{ delay: 2500, disableOnInteraction: false }} className="mySwiper swiper navigation-swiper rounded">
                          <div className="swiper-wrapper">
                            {detailsProperty?.images.map((el: any) => {
                              return <SwiperSlide><img src={el.url} alt="" className="img-fluid" /></SwiperSlide>
                            })}
                            {/* <SwiperSlide><img src={Scene1} alt="" className="img-fluid" /></SwiperSlide>
                            <SwiperSlide><img src={Scene2} alt="" className="img-fluid" /></SwiperSlide>
                            <SwiperSlide><img src={Scene3} alt="" className="img-fluid" /></SwiperSlide> */}
                          </div>
                        </Swiper>
                      </CardBody>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className="position-relative">
                      <CardHeader>
                        <Row>
                          <Col lg={11}>
                            <h4 className="">
                              Información de la propiedad

                            </h4>
                          </Col>
                          <Col lg={1} className="align-self-end p-0 mx-auto"> {/* Revisar */}
                            <div className='d-flex align-items-center justify-content-center'>
                              <h5>
                                {/*@ts-ignore*/}
                                <Badge color={badgeColor[parseStatus(detailsProperty?.status)]} className='m-0'>
                                  {/*@ts-ignore*/}
                                  {badgeText[parseStatus(detailsProperty?.status)]}
                                </Badge>
                              </h5>
                            </div>
                          </Col>
                        </Row>

                      </CardHeader>
                      <CardBody>
                        <Col lg={12}>
                          <h4 className="">
                            {detailsProperty?.detail?.title || "Título no disponible"}
                          </h4>
                          <div dangerouslySetInnerHTML={{
                            __html: detailsProperty?.detail?.description ?? ''
                          }} className="text-muted"></div>
                        </Col>
                        <Col lg={12}>
                          <h4 className="mt-3">
                            Detalles de la propiedad
                          </h4>
                        </Col>

                        <Row>
                          <Col lg={6}>
                            <div>
                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bx-money" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Moneda de pago</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.detail.currency.nombre}</p>
                                </div>
                              )}

                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bx-bed" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Habitaciones</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.detail.numberOfRooms}</p>
                                </div>
                              )}

                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bxs-car" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Parqueo</p>
                                  </div>
                                  <p className={`${!!detailsProperty.detail.numberOfParkingSpaces || "text-danger"}`} style={{ marginBottom: 0 }}>
                                    {detailsProperty.detail.numberOfParkingSpaces || "No disponible"}
                                  </p>
                                </div>
                              )}

                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bxs-parking" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Parqueo con techo</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.detail.numberOfParkingSpaces}</p>
                                </div>
                              )}

                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bx-current-location" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Locación</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.location.direction}</p>
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div>
                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bxs-ruler" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Tamaño</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.detail.surface} m²</p>
                                </div>
                              )}

                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bx-bath" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Baños</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.detail.numberOfBathrooms}</p>
                                </div>
                              )}
                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bx-receipt" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Amoblado/Semiamoblado</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.additionalCharacteristics.furnished
                                    ? "Sí"
                                    : "No"}
                                  </p>
                                </div>
                              )}

                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bxs-dog" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Pet Friendly</p>
                                  </div>
                                  <p className="parrafo-detalles">
                                    {detailsProperty.amenities.petArea
                                      ? "Sí"
                                      : "No"}
                                  </p>
                                </div>
                              )}

                              {detailsProperty && (
                                <div className="details-container-info">
                                  <div className="d-flex">
                                    <i className="bx bxs-grid" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                    <p className="parrafo-detalles text-bold">Año de construcción</p>
                                  </div>
                                  <p className="parrafo-detalles">{detailsProperty.detail.constructionYear} años</p>
                                </div>
                              )}
                            </div>
                          </Col>
                        </Row>

                        {detailsCharacteristics.length > 0 && (
                          <Col md={12}>
                            <div>
                              <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mb-1"
                                role="tablist">
                                {!!detailsCharacteristics.length && <NavItem style={{
                                  cursor: 'pointer'
                                }}>
                                  <NavLink
                                    style={activeTab === "1" ? activeClass : {}}
                                    to="#"
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                      tabChange("1");
                                    }}>
                                    <i className="fas fa-home tab-item"></i>
                                    Características adicionales
                                  </NavLink>
                                </NavItem>}

                                {!!detailsAmenities.length && <NavItem style={{
                                  cursor: 'pointer'
                                }}>
                                  <NavLink
                                    style={activeTab === "2" ? activeClass : {}}
                                    to="#"
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                      tabChange("2");
                                    }}>
                                    <i className="far fa-home"></i>
                                    Amenidades
                                  </NavLink>
                                </NavItem>}

                                {!!detailsIncludedServices.length && <NavItem style={{
                                  cursor: 'pointer'
                                }}>
                                  <NavLink
                                    style={activeTab === "3" ? activeClass : {}}
                                    to="#"
                                    className={classnames({ active: activeTab === "3" })}
                                    onClick={() => {
                                      tabChange("3");
                                    }}>
                                    <i className="far fa-envelope"></i>
                                    Servicios incluidos
                                  </NavLink>
                                </NavItem>}

                                {!!detailsSurroundings.length && <NavItem style={{
                                  cursor: 'pointer'
                                }}>
                                  <NavLink
                                    style={activeTab === "4" ? activeClass : {}}
                                    to="#"
                                    className={classnames({ active: activeTab === "4" })}
                                    onClick={() => {
                                      tabChange("4");
                                    }}>
                                    <i className="far fa-envelope"></i>
                                    Cercanías
                                  </NavLink>
                                </NavItem>}
                              </Nav>
                            </div>

                            <div>
                              <CardBody>
                                <TabContent activeTab={activeTab}>
                                  <TabPane tabId="1"> {/*Características adicionales*/}
                                    <Row>
                                      {detailsCharacteristics.map(
                                        (row: any, rowIndex: number) => (
                                          <Col md={4} lg={3} className="form-check mb-3">
                                            <i className="bx bxs-check-circle" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                            <Label
                                              className="form-check-label"
                                              for={`formCheck`}
                                            >
                                              {row.label}
                                            </Label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </TabPane>

                                  <TabPane tabId="2"> {/*Amenidades*/}
                                    <Row>
                                      {detailsAmenities.map(
                                        (row: any, rowIndex: number) => (
                                          <Col md={4} lg={3} className="form-check mb-3">
                                            <i className="bx bxs-check-circle" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                            <Label
                                              className="form-check-label"
                                              for={`formCheck`}
                                            >
                                              {row.label}
                                            </Label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </TabPane>

                                  <TabPane tabId="3"> {/*Servicios incluidos*/}
                                    <Row>
                                      {detailsIncludedServices.map(
                                        (row: any, rowIndex: number) => (
                                          <Col md={4} lg={3} className="form-check mb-3">
                                            <i className="bx bxs-check-circle" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                            <Label
                                              className="form-check-label"
                                              for={`formCheck`}
                                            >
                                              {row.label}
                                            </Label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </TabPane>

                                  <TabPane tabId="4"> {/*Cercanías*/}
                                    <Row>
                                      {detailsSurroundings.map(
                                        (row: any, rowIndex: number) => (
                                          <Col md={4} lg={3} className="form-check mb-3">
                                            <i className="bx bxs-check-circle" style={{ fontSize: '16px', marginRight: '5px' }}></i>
                                            <Label
                                              className="form-check-label"
                                              for={`formCheck`}
                                            >
                                              {row.label}
                                            </Label>
                                          </Col>
                                        )
                                      )}
                                    </Row>
                                  </TabPane>
                                </TabContent>
                              </CardBody>
                            </div>
                          </Col>
                        )}

                        <Col md={12}>
                          <h4 className="mb-3 ">Ubicación de la propiedad</h4>
                          <div className="mb-3">
                            {detailsProperty && (
                              <MapGoogle
                                coords={{
                                  lat: detailsProperty.location.lat,
                                  lng: detailsProperty.location.lng,
                                }}
                              />
                              //     <MapContainer center={{ lat: detailsProperty.location.lat, lng: detailsProperty.location.lng }} zoom={13} scrollWheelZoom={false} style={{ height: '400px', width: '100%' }}>
                              //     <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                              //                 <LocationMarker coord={{ lat: detailsProperty.location.lat, lng: detailsProperty.location.lng }} />
                              // </MapContainer>
                            )}
                          </div>
                        </Col>

                        {/*Carta del agente designado*/}
                        {detailsProperty?.agent?._id !== detailsProperty?.owner?._id &&
                          <Col md={12}>
                            <h5 className="mb-2">Agente asignado a la propiedad</h5>
                            <CardBody className="p-0 mb-1">
                              <Row>
                                <Col md={6} className="mt-3">
                                  <div className="d-flex">
                                    <img
                                      src={
                                        detailsProperty && detailsProperty.agent?.image
                                      }
                                      alt=""
                                      className="img-fluid rounded-circle imagen-agente"
                                      width={50}
                                      height={50}
                                    />
                                    <div>
                                      <h5 className="card-title mb-1">
                                        {detailsProperty && detailsProperty.agent?.name}
                                      </h5>
                                      <p className="text-muted mb-0">
                                        {detailsProperty && detailsProperty.agent?.email}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6} className="mt-3">
                                  {detailsProperty && (
                                    <div className="d-flex betica-derecha">
                                      <Button className="btn btn-success waves-effect waves-light">
                                        <Link
                                          to={`https://wa.me/${detailsProperty.agent?.phone}`} style={{ color: 'white' }}
                                        >
                                          Contactar por Whatsapp
                                        </Link>
                                      </Button>
                                      <Button className="ms-3 btn btn-primary waves-effect waves-light">
                                        <Link
                                          to={`mailto:${detailsProperty.agent?.email}`} style={{ color: 'white' }}
                                        >
                                          Contactar por Correo
                                        </Link>
                                      </Button>
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </CardBody>
                          </Col>}
                        {/*Carta del propietario designado*/}
                        <Col md={12}>
                          <h5 className="mb-2">Propietario asignado a la propiedad</h5>
                          <CardBody className="p-0 mb-1">
                            <Row>
                              <Col md={6} className="mt-3">
                                <div className="d-flex">
                                  <img
                                    src={
                                      detailsProperty && detailsProperty.agent?.image
                                    }
                                    alt=""
                                    className="img-fluid rounded-circle imagen-agente"
                                    width={50}
                                    height={50}
                                  />
                                  <div>
                                    <h5 className="card-title mb-1">
                                      {detailsProperty && detailsProperty.owner?.name}
                                    </h5>
                                    <p className="text-muted mb-0">
                                      {detailsProperty && detailsProperty.owner?.email}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6} className="mt-3">
                                {detailsProperty && (
                                  <div className="d-flex betica-derecha">
                                    <Button className="btn btn-success waves-effect waves-light">
                                      <Link
                                        to={`https://wa.me/${detailsProperty.owner?.phone}`} style={{ color: 'white' }}
                                      >
                                        Contactar por Whatsapp
                                      </Link>
                                    </Button>
                                    <Button className="ms-3 btn btn-primary waves-effect waves-light">
                                      <Link
                                        to={`mailto:${detailsProperty.owner?.email}`} style={{ color: 'white' }}
                                      >
                                        Contactar por Correo
                                      </Link>
                                    </Button>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </CardBody>
                        </Col>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>

              {/* <Col xl={12}>
                <Card>
                  <Row className="p-12">
                    <Col lg={12}>
                      <CardHeader>
                        <Row>
                          <Col lg={12}>
                            <h4 className="card-title mb-0">
                              Motivo del rechazo
                            </h4>
                          </Col>
                        </Row>
                      </CardHeader>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <CardBody>
                        <Row>
                          <Col lg={12} className="mb-1">
                            <h5 className="card-title mb-2 fw-semibold">
                              Motivo #1 {"(02/07/2024)"}
                            </h5>
                            <p className="parrafo-detalles">
                              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam eius similique accusantium error consequuntur ad mollitia, officia nemo quos voluptas molestias? A, assumenda tenetur. Iste eius vitae eum ducimus dignissimos!
                            </p>
                          </Col>
                          <Col lg={12} className="mb-1">
                            <h5 className="card-title mb-2 fw-semibold">
                              Motivo #2 {"(22/06/2024)"}
                            </h5>
                            <p className="parrafo-detalles">
                              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quam eius similique accusantium error consequuntur ad mollitia, officia nemo quos voluptas molestias? A, assumenda tenetur. Iste eius vitae eum ducimus dignissimos!
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Col>
                  </Row>

                </Card>
              </Col> */}
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default PropertyDetails;