import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback } from 'reactstrap';
import ParticlesAuth from '../ParticlesAuth';
import logoLight from "../../../assets/images/logo-light.png";

//formik
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ResetPassword } from 'service/auth';
import { useAppDispatch, useAppSelector } from 'slices/hooks';
import { userForgetPasswordError } from 'slices/auth/forgetpwd/reducer';
import { AxiosError } from 'axios';

const BasicPasswCreate = () => {
    const { user, forgetError } = useAppSelector((state) => state.ForgetPassword)
    document.title = "Create New Password | Velzon - React Admin & Dashboard Template";
    const dispatch = useAppDispatch()
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [confrimPasswordShow, setConfrimPasswordShow] = useState<boolean>(false);
    const navigate = useNavigate()

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            newPassword: "",
            confirmPassword: "",
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
                .min(8, 'Password must be at least 8 characters')
                .matches(RegExp('(.*[a-z].*)'), 'At least lowercase letter')
                .matches(RegExp('(.*[A-Z].*)'), 'At least uppercase letter')
                .matches(RegExp('(.*[0-9].*)'), 'At least one number')
                .required("This field is required"),
            confirmPassword: Yup.string()
                .required('Confirmar contraseña is required')
        }),
        onSubmit: async (values) => {
            console.log(forgetError)
            try {
                if (values.newPassword !== values.confirmPassword) {
                    validation.setFieldError('confirmPassword', 'Las contraseñas no coinciden')
                } else {
                    await ResetPassword({
                        ...user,
                        newPassword: values.newPassword,
                    })
                    navigate('/login')
                }
            } catch (e: unknown) {
                let errorMessage: string | undefined = (e as AxiosError<{ message: string }>).response?.data.message;
                if (errorMessage)
                    dispatch(userForgetPasswordError(errorMessage))
            }
        }
    });

    return (
        <ParticlesAuth>
            <div className="auth-page-content mt-lg-5">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="text-center mt-sm-5 mb-4 text-white-50">
                                <div>
                                    <Link to="/#" className="d-inline-block auth-logo">
                                        <img src={logoLight} alt="" height="44" />
                                    </Link>
                                </div>
                                <p className="mt-3 fs-15 fw-medium">Tu nueva contraseña debe ser diferente a las anteriores.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="mt-4">

                                <CardBody className="p-4">
                                    <div className="text-center mt-2">
                                        <h5 className="text-primary">Nueva contraseña</h5>
                                        <p className="text-muted">Tu nueva contraseña debe ser diferente a las anteriores.</p>
                                    </div>

                                    <div className="p-2">
                                        <Form onSubmit={validation.handleSubmit} action="/auth-signin-basic">
                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="newPassword-input">Password</Label>
                                                <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        type={passwordShow ? "text" : "password"}
                                                        className="form-control pe-5 newPassword-input"
                                                        placeholder="Introduce una nueva contraseña"
                                                        id="newPassword-input"
                                                        name="newPassword"
                                                        value={validation.values.newPassword}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.newPassword && validation.touched.newPassword ? true : false}
                                                    />
                                                    {validation.errors.newPassword && validation.touched.newPassword ? (
                                                        <FormFeedback type="invalid">{validation.errors.newPassword}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setPasswordShow(!passwordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted newPassword-addon" type="button"
                                                        id="newPassword-addon"><i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                                <div id="passwordInput" className="form-text">Debe tener minimo 8 caracteres.</div>
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="confirm-newPassword-input">Repetir contraseña</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        type={confrimPasswordShow ? "text" : "password"}
                                                        className="form-control pe-5 newPassword-input"
                                                        placeholder="Repite la contraseña"
                                                        id="confirm-newPassword-input"
                                                        name="confirmPassword"
                                                        value={validation.values.confirmPassword}
                                                        onBlur={validation.handleBlur}
                                                        onChange={validation.handleChange}
                                                        invalid={validation.errors.confirmPassword && validation.touched.confirmPassword ? true : false}
                                                    />
                                                    {validation.errors.confirmPassword && validation.touched.confirmPassword ? (
                                                        <FormFeedback type="invalid">{validation.errors.confirmPassword}</FormFeedback>
                                                    ) : null}
                                                    <Button color="link" onClick={() => setConfrimPasswordShow(!confrimPasswordShow)} className="position-absolute end-0 top-0 text-decoration-none text-muted newPassword-addon" type="button">
                                                        <i className="ri-eye-fill align-middle"></i></Button>
                                                </div>
                                            </div>

                                            <div id="newPassword-contain" className="p-3 bg-light mb-2 rounded">
                                                <h5 className="fs-13">Password must contain:</h5>
                                                <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                                <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                                <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                                <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                            </div>

                                            {/* < className="form-check">
                                                <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                            </(div> */}

                                            <div className="mb-3">
                                                <div className="position-relative auth-pass-inputgroup mb-3">

                                                    {forgetError ? (
                                                        <FormFeedback type="invalid">{forgetError}</FormFeedback>
                                                    ) : null}

                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <Button color="success" className="w-100" type="submit">Restablecer contraseña</Button>
                                            </div>

                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-4 text-center">
                                <p className="mb-0">Espera, Recuerdo mi contraseña... <Link to="/auth-signin-basic" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </ParticlesAuth>
    );
};

export default BasicPasswCreate;