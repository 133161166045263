import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { GetRatingArgs, GetURatingResponse as GetRatingResponse, PostRatingArgs, PostRatingResponse, SendRatingArgs, SendRatingResponse } from "types";
const api = new APIClient();

export const GetRatings = async (data: GetRatingArgs) => await api.get<GetRatingArgs, GetRatingResponse>(url.GET_RATING, data)
export const CreateTenantRating = async (data: PostRatingArgs) => await api.create<PostRatingArgs, PostRatingResponse>(url.POST_RATING, data)
export const SendRating = async (data: SendRatingArgs) => await api.create<SendRatingArgs, SendRatingResponse>(url.POST_USER, data)


