import { DeleteUserArgs, DeleteUserResponse, GetUniqueUserArgs, GetUniqueUserResponse, GetUsersArgs, GetUsersResponse, IUser, PostUserArgs, PostUserResponse, PutUserArgs, PutUserResponse } from "types";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";
import { fileToBinaryString } from "helpers/file";
const api = new APIClient();
const headers = {
    "Content-Type": "multipart/form-data",
};

export interface IUpdateUser extends Omit<IUser, "image" | "photoDni" | "photoDniBehind" | "companyLogo"> {
    image?: File;
    photoDni?: File;
    photoDniBehind?: File;
    companyLogo?: File;
}

export const Get = async (data: GetUsersArgs) => await api.get<GetUsersArgs, GetUsersResponse>(url.GET_USERS, data)
export const Post = async (data: PostUserArgs) => await api.create<PostUserArgs, PostUserResponse>(url.POST_USER, data)
export const GetUnique = async (id:string, data?: GetUniqueUserArgs) => await api.get<GetUniqueUserArgs, GetUniqueUserResponse>(url.GET_UNIQUE_USER.replace("{id}", id), data)
export const GetUserByDNI = async (dni:string) => await api.get<GetUniqueUserArgs, GetUniqueUserResponse>(url.GET_USER_BY_DNI.replace("{dni}", dni))
export const Update = async (data: PutUserArgs, body: IUpdateUser) => {
    for (const key in body) {
        // @ts-ignore
        if (!body[key]) delete body[key]
    }
    if (body._id) {
        // @ts-ignore
        // eslint-disable-next-line
        delete body._id;
    }

    const formData = new FormData();

    for (const key in body) {
        // @ts-ignore
        if (body[key])
            // @ts-ignore
            formData.append(key, body[key]);
    }

    return await api.put<FormData, PutUserResponse>(`${url.PUT_USER.replace('{id}', data.id)}`, {}, formData, headers)
}
export const Delete = async () => await api.delete<DeleteUserArgs, DeleteUserResponse>(url.DELETE_USER)
