interface GoogleConfig {
  MAP_API_KEY: string;
  API_KEY: string;
  CLIENT_ID: string;
  SECRET: string;
}

interface FacebookConfig {
  APP_ID: string;
}

interface ApiConfig {
  API_URL: string;
}

interface Config {
  google: GoogleConfig;
  facebook: FacebookConfig;
  api: ApiConfig;
}

const config: Config = {
  google: {
    API_KEY: "",
    CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID ?? '',
    SECRET: "",
    MAP_API_KEY: process.env.REACT_APP_MAP_API_KEY ?? '',
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // API_URL: "https://api-node.themesbrand.website",
    // API_URL: "https://api.partarentals.com/api",
    API_URL: process.env.REACT_APP_BACK_API_URL || "http://localhost:4000",
  },
};

export default config;
