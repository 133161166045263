import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from "use-places-autocomplete";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input } from 'reactstrap';
import { useEffect, useState } from "react";

interface Location {
    lat: number;
    lng: number;
}
interface PlacesAutocompleteProps {
    getCoords: (({ lat, lng }: Location) => void);
    address: (description: string, province: string, distrito: string) => void;
    addressValue: string;
}

const PlacesAutocomplete = ({ getCoords, address, addressValue }: PlacesAutocompleteProps) => {
    const [isLocationDropdown, setIsLocationDropdown] = useState(false);

    const {
        ready,
        value,
        suggestions: { status, data },
        setValue,
        clearSuggestions,
    } = usePlacesAutocomplete({
        callbackName: "YOUR_CALLBACK_NAME",
        requestOptions: {
            /* Define search scope here */
        },
        debounce: 300,
    });
    // const ref = useOnclickOutside(() => {
    //     // When the user clicks outside of the component, we can dismiss
    //     // the searched suggestions by calling this method
    //     clearSuggestions();
    // });

    const handleInput = (e: any) => {
        // Update the keyword of the input element
        setValue(e.target.value);
    };

    const handleSelect = ({ description }: any) => () => {
        setValue(description, false);
        clearSuggestions();

        getGeocode({ address: description }).then((results) => {
            if (results && results.length > 0) {
                const { address_components } = results[0];
                let province = "";
                let canton = "";
                let distrito = "";

                address_components.forEach((component) => {
                    const { types, long_name } = component;
                    if (types.includes("administrative_area_level_1")) {
                        // Provincia
                        province = long_name;
                    } else if (types.includes("administrative_area_level_2")) {
                        // Cantón
                        canton = long_name;
                    } else if (types.includes("locality")) {
                        // Distrito
                        distrito = long_name;
                    }
                });

                address(description, province, distrito);
            }

            const { lat, lng } = getLatLng(results[0]);
            // console.log("📍 Coordinates: ", { lat, lng });
            getCoords({ lat, lng });
        });
    };

    const renderSuggestions = () => {
        return (data ?? []).map((suggestion) => {
            const {
                place_id,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (<>
                <DropdownItem className='p-0' key={`${secondary_text}`}>
                    <div key={place_id} onClick={handleSelect(suggestion)} className="p-2">
                        <strong>{main_text}</strong> <small>{secondary_text}</small>
                    </div>
                </DropdownItem>

            </>
            );
        })

    }

    const toggleLocationDropdown = () => {
        setIsLocationDropdown(!isLocationDropdown);
    };

    useEffect(() => {
        status === "OK" && setIsLocationDropdown(true);
    }, [status])


    return (
        <div>
            <Input
                value={value ? value : addressValue}
                onChange={handleInput}
                disabled={!ready}
                placeholder="Dirección"
            />
            {/* We can use the "status" to decide whether we should display the dropdown or not */}
            {<Dropdown isOpen={isLocationDropdown} key={"dropdown"} toggle={toggleLocationDropdown}>
                <DropdownToggle tag="button" type="button" className="btn" style={{
                    height: '0px',
                }}>
                </DropdownToggle>
                <DropdownMenu>
                    {renderSuggestions()}
                </DropdownMenu>
            </Dropdown>}
        </div>
    );
};

export default PlacesAutocomplete;