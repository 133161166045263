import { useMemo } from "react"
import { useAppSelector } from "slices/hooks"
import { IUser } from "types"

const userRequiredData: (keyof IUser)[] = ["phone", "email", "dni", "name", "surname", "photoDni", "photoDniBehind"]

export const useProfileUtils = () => {
    const user = useAppSelector((state) => state.Profile.user)

    const isLoggedIn = useMemo(() => {
        return user !== null
    }, [user])


    const isProfileComplete = useMemo(() => {
        if (!isLoggedIn) return false
        if (user === null) return false
        return userRequiredData.every((key) => user[key])
    }, [user, isLoggedIn])

    return { isLoggedIn, isProfileComplete }
}

