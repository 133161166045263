import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import config from "../configs/app.config";

const { api } = config;


class APIClient {
  axiosInstance = axios.create({
    baseURL: api.API_URL,
    headers: {
      'Content-Type': 'application/json',
    }
  });
  token: string | null = null;
  /**
   * Fetches data from the given URL
   */

  constructor() {
    // default
    this.axiosInstance.defaults.baseURL = api.API_URL;
    // content type
    this.axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

    // content type
    const authUser: any = sessionStorage.getItem("authUser")
    const token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;
    if (token) {
      this.axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
    // this.axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    // intercepting to capture errors
    this.axiosInstance.interceptors.response.use(
      function (response) {
        return response.data ? response.data : response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;
        switch (error.status) {
          case 500:
            message = "Internal Server Error";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          case 404:
            message = "Sorry! the data you are looking for could not be found";
            break;
          default:
            // message = error.message || error;
            message = error;
        }
        throw message;
      }
    );
    const _token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;
    this.setAuthorization(_token)
  }

  request() {
    if (!this.axiosInstance.defaults.headers.common["Authorization"] && this.token) {
      this.setAuthorization(this.token);
    } else {
      const authUser: string = sessionStorage.getItem("authUser") || "";
      if (authUser === "") return this.axiosInstance

      const _token = JSON.parse(authUser) ? JSON.parse(authUser).token : null;
      if (_token) {
        this.setAuthorization(_token);
      }
    }

    return this.axiosInstance
  }

  get<T = unknown, D = unknown>(url: string, params?: T): Promise<D> {
    let response: Promise<D>;
    if (params) {
      const config = {
        params: params
      };
      response = this.request().get(url, config);
    } else {
      response = this.request().get(url);
    }

    return response;
  };

  /**
   * Posts the given data to the URL
   */
  create<T = unknown, D = unknown>(url: string, data: T, headers = {}): Promise<D> {
    console.log(headers, "headeeers")
    return this.request().post(url, data, { headers });
  };

  /**
   * Updates data
   */
  update<T = unknown, D = unknown>(url: string, data: T): Promise<D> {
    return this.request().patch(url, data);
  };

  put<T = unknown, D = unknown>(url: string, params: AxiosRequestConfig['params'], data?: T, headers = {}): Promise<AxiosResponse<T, D>> {
    console.log(headers)
    console.log(data)
    return this.request().put(url, data, {
      params: params,
      headers
    });
  };
  patch<T = unknown, D = unknown>(url: string, params: AxiosRequestConfig['params'], data?: T, headers = {}): Promise<AxiosResponse<T, D>> {
    console.log(headers)
    console.log(data)
    return this.request().patch(url, data, {
      params: params,
      headers
    });
  };

  /**
   * Deletes data
   */
  delete<T = unknown, D = unknown>(url: string, config?: AxiosRequestConfig): Promise<D> {
    return this.request().delete(url, { ...config });
  };

  setAuthorization = (token: string) => {
    if (token) {
      this.axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      this.token = token;
    }
  }
}

const getLoggedinUser = () => {
  const user = sessionStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, getLoggedinUser };