import React, { useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';

import AvatarIcon from '../../../../assets/images/users/avatar-3.jpg'
import Select from "react-select";

const ContractOfferDetails = () => {
    document.title = "Detalle de la oferta del contrato | Partarentals";

    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [modal_declined, setmodal_declined] = useState<boolean>(false);
    function decline_payment() {
        setmodal_declined(!modal_declined);
    }

    return (
        <React.Fragment>
            <div className="page-content mb-1 pb-0">
                <Container fluid>
                    <BreadCrumb title="Detalle de la oferta del contrato" pageTitle="Pages" />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col lg={6} className="d-flex align-items-center mb-0 justify-content-start">
                                            <h4 className='my-0 fw-bold fs-18'>La oferta de contrato aún no ha sido aceptada</h4>
                                        </Col>
                                        <Col lg={6} className="d-flex align-items-center mb-0 justify-content-end">
                                            <Button color="danger" onClick={() => tog_center()}>
                                                Retirar oferta
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="page-content pt-0">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody className="my-2">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="live-preview">

                                                {/*First Row*/}
                                                <Row className='mb-3'>
                                                    <Col lg={12}>
                                                        <h5 className='fw-semibold'>Tipo de contrato</h5>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>
                                                                        Tipo de contrato
                                                                    </Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>
                                                                            Contrato nuevo
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>
                                                                        Identificador
                                                                    </Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>
                                                                            123456789
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {/*Second Row*/}
                                                <Row className='mb-3'>
                                                    <Col lg={12}>
                                                        <h5 className='fw-semibold'>Propiedad a alquilar</h5>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <Row>
                                                                    <Col lg={12}>
                                                                        <div>
                                                                            <Label>Propiedad</Label>
                                                                            <div>
                                                                                <a href='#' className='text-success fw-semibold text-decoration-underline'>Lorem ipsum dolor sit amet, consectetur adipiscing elit</a>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>
                                                                        Nombre de la propiedad
                                                                    </Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>
                                                                            Contrato nuevo
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>
                                                                        Ubicación
                                                                    </Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>
                                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {/*Third Row*/}
                                                <Row className='mb-3'>
                                                    <Col lg={12}>
                                                        <h5 className='fw-semibold'>Datos del arrendatario</h5>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Cédula de identidad</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>CPF 123456789</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>
                                                                        Correo electrónico
                                                                    </Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>
                                                                            deazevedoalexander@gmail.com
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>
                                                                        Nombre y apellido
                                                                    </Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>
                                                                            Alexander De Azevedo
                                                                        </Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {/*Fourth Row*/}
                                                <Row className='mb-3'>
                                                    <Col lg={12}>
                                                        <h5 className='fw-semibold'>Pagos del contrato</h5>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Moneda de pago</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>Dólares americanos</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Precio acordado en Dólares Americanos</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>$0.00</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Deposito en garantía en Dólares Americanos </Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>$0.00</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {/*Fifth Row*/}
                                                <Row className='mb-3'>
                                                    <Col lg={12}>
                                                        <h5 className='fw-semibold'>Clausulas del contrato</h5>
                                                        <Row className='mb-1'>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Fecha de inicio</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>19 Jun, 2024</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Fecha culminación</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>19 Jun, 2024</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Reajuste anual</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>15%</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Fecha de cobro</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>19 de cada mes</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Fecha límite de pago</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>19 Jun, 2024</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Recargo moratorio</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>$0.00</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Penalidad por finalización del contrato</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>$0.00</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <Label>Plazo de penalidad</Label>
                                                                    <div>
                                                                        <Label className='fw-semibold'>Antes de los 12 meses</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>

                                                {/*Sixth Row*/}
                                                <Row className='mb-3'>
                                                    <Col lg={12}>
                                                        <h5 className='fw-semibold'>Contrato legal en pdf</h5>
                                                        <Row>
                                                            <Col lg={3}>
                                                                <div>
                                                                    <a href='#' className='text-success fw-semibold text-decoration-underline'>
                                                                        Descargar contrato {" "}
                                                                        <i className='bx bxs-download'></i>
                                                                    </a>
                                                                </div>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                            <Col lg={3}>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
                        <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                            <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="ri-error-warning-line display-2 ext-warning" style={{ color: "orange" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Aprobar finalización</h4>
                        <h5>Para finalizar este contrato deberás seleccionar los siguientes elementos:</h5>
                        <form action="">
                                <div className="mb-3 text-start">
                                    <p>
                                        <span className='fw-semibold'>
                                            Declaro:
                                        </span>
                                        {" "}ya he visitado la propiedad y he verificado su estado actual, así como el de los bienes muebles que me pertenecen.
                                    </p>
                                    {/* <Label htmlFor="currency" className="form-label">Motivo del rechazo</Label>
                                <select className="form-select mb-3" aria-label="Default select example">
                                    <option value="pago_incompleto">Pago incompleto</option>
                                </select> */}
                                </div>
                                <div className="mb-3 text-start">
                                    <p>
                                        <span className='fw-semibold'>
                                            Declaro:
                                        </span>
                                        {" "}ya he devuelto la totalidad del depósito de garantía, descontando únicamente las penalizaciones o reparaciones correspondientes.
                                    </p>
                                    {/* <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea> */}
                                </div>
                                <div className="mb-3 text-start">
                                    <Label for="iconrightInput" className="form-label">Introduzca su contraseña</Label>
                                    <div className='form-icon right position-relative'>
                                        <Input type="password" className="form-control form-control-icon pe-5" placeholder='Introduce tu contraseña' />
                                        <button className="btn btn-link position-absolute end-0 top-50 translate-middle-y text-decoration-none text-muted">
                                            <i className="ri-eye-fill align-middle"></i>
                                        </button>
                                    </div>
                                </div>
                            <div className="hstack gap-2 justify-content-center">
                                <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                                <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment >
    );
};

export default ContractOfferDetails;
