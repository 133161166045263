

export enum PaymentMethodType {
    PAYPAL = 'paypal',
    CASH = 'cash',
    BANK_TRANSFER = 'bank_transfer',
    SINPE = 'sinpe',
}

export const PaymentMethodMap = {
    [PaymentMethodType.BANK_TRANSFER]: "Transferencia bancaria",
    [PaymentMethodType.PAYPAL]: "Paypal",
    [PaymentMethodType.SINPE]: "Transferencia SINPE MOVIL",
    [PaymentMethodType.CASH]: "Efectivo",
}
export interface PaypalDetails {
    email: string
}

export interface CashDetails {
}

export interface BankTransferDetails {
    accountName: string,
    bankName: string,
    accountNumber: string,
    swiftCode: string
}

export interface SimpeDetails {
    accountName: string,
    phoneNumber: string
}


export interface ICreatePaymentMethodBase<T, R> {
    owner: string,
    name: string,
    type: T,
    details: R,
    isActive: boolean
}

export type ICreatePaymentMethodPaypal = ICreatePaymentMethodBase<PaymentMethodType.PAYPAL, PaypalDetails>
export type ICreatePaymentMethodCash = ICreatePaymentMethodBase<PaymentMethodType.CASH, CashDetails>
export type ICreatePaymentMethodBankTransfer = ICreatePaymentMethodBase<PaymentMethodType.BANK_TRANSFER, BankTransferDetails>
export type ICreatePaymentMethodSinpe = ICreatePaymentMethodBase<PaymentMethodType.SINPE, SimpeDetails>

export type ICreatePaymentMethod = ICreatePaymentMethodPaypal | ICreatePaymentMethodCash | ICreatePaymentMethodBankTransfer | ICreatePaymentMethodSinpe

export interface IUpdatePaymentMethod {
    owner?: string,
    name?: string,
    type?: PaymentMethodType,
    details?: {
        email?: string,
        accountNumber?: string,
        accountName?: string,
        bankName?: string,
        phoneNumber?: string
        swiftCode?: string
    },
    isActive?: boolean
}

export interface IPaymentMethod {
    _id: string
    owner: string,
    name: string,
    type: PaymentMethodType,
    details: {
        email?: string,
        accountNumber?: string,
        accountName?: string,
        bankName?: string,
        phoneNumber?: string
        swiftCode?: string
    },
    isActive: boolean
}

