import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Input, InputGroup, Label, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate, useParams, useRoutes } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';

import AvatarIcon from '../../../../assets/images/users/avatar-3.jpg'
import Select from "react-select";
import { GetContract, UpdateContract, DeleteContract, EndContractApproved } from "service/contracts"
import { ContractStatus, IContract, IContract as any } from 'types/api/contracts';
import { use } from 'echarts';
import { toast } from 'react-toastify';
import { useAppSelector } from 'slices/hooks';
import { Role } from 'types';
import { Checkbox } from 'pages/Forms/CheckboxAndRadio/CheckboxAndRadioCode';

const ContractDetails = () => {
    document.title = "Detalle de un contrato | Partarentals";
    const { _id } = useParams<{ _id: string }>();
    const navigate = useNavigate()
    const profile = useAppSelector(state => state.Profile)
    const [contract, setContract] = useState<any | null>(null)
    const [password, setPassword] = useState<any | null>(null)
    const [checkBox, setCheckBox] = useState<boolean[]>([false, false])
    const [modal_center, setmodal_center] = useState<boolean>(false);
    const [modal_declined_offer, setModal_declined_offer] = useState<boolean>(false);

    const tog_decline_offer = () => setModal_declined_offer(prev => !prev);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [modal_declined, setmodal_declined] = useState<boolean>(false);
    function decline_payment() {
        setmodal_declined(!modal_declined);
    }

    const handleGetContract = useCallback(async () => {
        if (!_id) return
        const res = await GetContract(_id)
        setContract(res)
    }, [_id])

    useEffect(() => {
        handleGetContract()
    }, [_id, handleGetContract])

    const handleFinishContract = useCallback(async () => {
        try {
            if (!contract) return
            /* await EndContractApproved(contract._id, { password }) */
            await UpdateContract(contract._id, { status: ContractStatus.COMPLETED })
            toast.success("Finalizacion de contrato aceptada")
            toast.info("Contrato finalizado")
            navigate("/finished-contracts-preview")
        } catch (error) {
            //@ts-ignore
            toast.error(error?.message)
            toast.error("Ha ocurrido un error al finalizar el contrato")
        }

    }, [contract, navigate, password])

    const handleDeclineOffer = useCallback(async () => {
        try {
            if (_id) {
                DeleteContract(_id)
                toast.success("Oferta retirada")
                navigate("/contracts-offers-preview")
            }
        } catch (error) {
            toast.error("Hubo un error al buscar el contrato, por favor contacte al propietario")
        }
    }, [_id, navigate])

    const handlePayment = useCallback(async () => {
        try {
            if (!_id) return
            await GetContract(_id)
            navigate(`/direct-payment/${contract?._id}`)
        } catch (error) {
            toast.error("Hubo un error al buscar el contrato, por favor contacte al propietario")
        }
    }, [_id, contract?._id, navigate])


    return (
        <React.Fragment>
            <div className="page-content mb-1 pb-0">
                {
                    // @ts-ignore
                    contract?.status === ContractStatus.PENDING_COMPLETION
                    &&
                    // @ts-ignore
                    (contract?.owner._id === profile.user?._id || contract?.agent._id === profile.user?._id) &&
                    <Container fluid>
                        <BreadCrumb title="Detalle de un contrato" pageTitle="Pages" />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className="">
                                        <Row>
                                            <Col md={6}>
                                                <div className="">
                                                    <h5 className='fw-semibold m-0'>Su arrendatario ha solicitado finalizar este contrato el {new Date(contract?.updatedAt ?? 0).toLocaleDateString()} <span className='text-bg-danger'>(Futuro)</span></h5>
                                                    <p className='m-0'>Apruebe la finalización del contrato en caso de estar de acuerdo.</p>
                                                </div>

                                            </Col>
                                            <Col md={6} className="d-flex align-items-center mb-0 justify-content-end">
                                                <Button color="success" onClick={() => tog_center()} className='w-100-mobile mt-2-mobile'>
                                                    Aprobar finalización {" "}
                                                    <i className='bx bx-check'></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
                {
                    // @ts-ignore
                    contract?.owner._id === profile.user?._id && contract?.status === ContractStatus.PENDING_APPROVAL && [Role.Owner, Role.Admin].includes(profile.user?.roles[0] || '') &&
                    <Container fluid>
                        <BreadCrumb title="Detalle de un contrato" pageTitle="Pages" />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className="">
                                        <Row>
                                            <Col md={6}>
                                                <div className="">
                                                    <h5 className='fw-semibold m-0'>La oferta de contrato aun no ha sido aceptada</h5>
                                                    <p className='m-0'>Puedes retirar la oferta antes de que sea aceptada.</p>
                                                </div>
                                            </Col>
                                            <Col md={6} className="d-flex align-items-center mb-0 justify-content-end">
                                                <Button color="danger" onClick={() => tog_decline_offer()} className='w-100-mobile mt-2-mobile'>
                                                    Retirar oferta {" "}
                                                    <i className='bx bx-trash'></i>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
                {
                    // @ts-ignore
                    contract?.tenant._id === profile.user?._id && [Role.Tenant, Role.Admin].includes(profile.user?.roles[0] || '') && contract?.status === ContractStatus.PENDING_APPROVAL &&
                    <Container fluid>
                        <BreadCrumb title="Detalle de un contrato" pageTitle="Pages" />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader className="">
                                        <Row>
                                            <Col md={6} lg="7" className="">
                                                <div>
                                                    <h5 className='fw-semibold'>Si usted esta de acuerdo con el contrato proceda a <span className='text-success'>Aceptar y Pagar</span></h5>
                                                    <p className='m-0'>Lea detenidamente los detalles del contrato antes de tomas un decisión.</p>
                                                </div>
                                            </Col>
                                            <Col md={6} lg="5">
                                                <div className='contrato-activo-buttons'>
                                                    <Button color="success" onClick={() => handlePayment()} className='w-100-mobile mt-2-mobile'>
                                                        Aceptar y pagar {" "}
                                                        <i className='bx ri-check-line'></i>
                                                    </Button>
                                                    <Button color="danger" onClick={() => tog_decline_offer()} className='w-100-mobile mt-2-mobile'>
                                                        Rechazar {" "}
                                                        <i className='bx bx-trash'></i>
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                }
            </div>
            <div className="page-content pt-0">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody className="my-2">
                                    <Row>
                                        <Col lg={12}>
                                            <div className="live-preview">

                                                {/*First Row*/}
                                                <Row className='mb-3'>

                                                    <h5 className='fw-semibold'>Tipo de contrato</h5>
                                                    <Row>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>
                                                                    Tipo de contrato
                                                                </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>
                                                                        {
                                                                            contract?.contractType === "new" ? "Contrato nuevo" : "Contrato existente"
                                                                        }
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>
                                                                    Identificador
                                                                </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>
                                                                        {contract?._id}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                    </Row>

                                                </Row>

                                                {/*Second Row*/}
                                                <Row className='mb-3'>

                                                    <h5 className='fw-semibold'>Propiedad a alquilar</h5>
                                                    <Row>
                                                        <Col md={6} xl={3}>
                                                            <Row>
                                                                <Col lg={12}>
                                                                    <div>
                                                                        <Label>Propiedad</Label>
                                                                        <div>
                                                                            <Link to={`/pages-property-details/${contract?.property?._id}`} className='text-success fw-semibold text-decoration-underline'>{contract?.property?.detail.title}</Link>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>
                                                                    Ubicación
                                                                </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>
                                                                        {contract?.property?.location.direction}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                    </Row>

                                                </Row>

                                                {/*Third Row*/}
                                                <Row className='mb-3'>

                                                    <h5 className='fw-semibold'>Datos del arrendador</h5>
                                                    <Row>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Cédula de identidad</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{contract?.owner.dni}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>
                                                                    Correo electrónico
                                                                </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>
                                                                        {contract?.owner.email}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>
                                                                    Nombre y apellido
                                                                </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>
                                                                        {contract?.owner.name} {contract?.owner.surname}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                    </Row>

                                                </Row>

                                                {/*Third Row*/}
                                                <Row className='mb-3'>

                                                    <h5 className='fw-semibold'>Datos del arrendatario</h5>
                                                    <Row>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Cédula de identidad</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{contract?.tenant.dni}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>
                                                                    Correo electrónico
                                                                </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>
                                                                        {contract?.tenant.email}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>
                                                                    Nombre y apellido
                                                                </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>
                                                                        {contract?.tenant.name} {contract?.tenant.surname}
                                                                    </Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                    </Row>

                                                </Row>

                                                {/*Fourth Row*/}
                                                <Row className='mb-3'>

                                                    <h5 className='fw-semibold'>Pagos del contrato</h5>
                                                    <Row>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Moneda de pago</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{contract?.currency.nombre}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Precio acordado en {contract?.currency.nombre}</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>${contract?.agreedPrice}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Deposito en garantía en {contract?.currency.nombre} </Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>${contract?.deposit}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                    </Row>

                                                </Row>

                                                {/*Fifth Row*/}
                                                <Row className='mb-3'>

                                                    <h5 className='fw-semibold'>Clausulas del contrato</h5>
                                                    <Row className='mb-1'>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Fecha de inicio</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{new Date(contract?.startDate ?? 0).toLocaleDateString()}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Fecha culminación</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{new Date(contract?.endDate ?? 0).toLocaleDateString()}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Reajuste anual</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{contract?.annualIncrement}%</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Fecha de cobro</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{new Date(contract?.paymentDate ?? 0).getDate()} de cada mes</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Plazo de gracia</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>{contract?.gracePeriod} dias</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Recargo moratorio</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>${contract?.lateInterest}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Penalidad por finalización del contrato</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>${contract?.terminationPenalty}</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <Label>Plazo de penalidad</Label>
                                                                <div>
                                                                    <Label className='fw-semibold'>Antes de los {contract?.penaltyPeriod} meses</Label>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>

                                                </Row>

                                                {/*Sixth Row*/}
                                                {contract?.legalDocument && <Row className='mb-3'>

                                                    <h5 className='fw-semibold'>Contrato legal en pdf</h5>
                                                    <Row>
                                                        <Col md={6} xl={3}>
                                                            <div>
                                                                <a href={contract?.legalDocument} className='text-success fw-semibold text-decoration-underline' download={true}>
                                                                    Descargar contrato {" "}
                                                                    <i className='bx bxs-download'></i>
                                                                </a>
                                                            </div>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                        <Col md={6} xl={3}>
                                                        </Col>
                                                    </Row>
                                                </Row>}
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
                        <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                            <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* Vertically Centered */}
            <Modal
                isOpen={modal_declined_offer}
                toggle={() => {
                    tog_decline_offer();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-trash text-danger" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de retirar esta oferta?</h4>
                        <p className="text-muted mb-4">Una vez retirada no se podrá recuperar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => tog_decline_offer()}>Cancelar</Button>
                            <Button color="danger" onClick={handleDeclineOffer}>Retirar</Button>
                        </div>
                    </div>
                </ModalBody>
            </Modal>


            {/* Vertically Centered */}
            <Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="ri-error-warning-line display-2 ext-warning" style={{ color: "orange" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">Aprobar finalización</h4>
                        <h5>Para finalizar este contrato deberás seleccionar los siguientes elementos:</h5>
                        <form action="">
                            <div className="mb-3 text-start">
                                <Label check style={{ marginLeft: '5px' }}>
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        checked={checkBox[0]}
                                        onChange={(e) => {
                                            setCheckBox([e.target.checked, checkBox[1]])
                                        }}
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    />{"   "}

                                    <span className='fw-semibold'>
                                        Declaro:
                                    </span>
                                    {" "}ya he visitado la propiedad y he verificado su estado actual, así como el de los bienes muebles que me pertenecen.

                                </Label>
                            </div>
                            <div className="mb-3 text-start">
                                <Label check style={{ marginLeft: '5px' }}>
                                    <Input
                                        className="form-check-input"
                                        type="checkbox"
                                        id="formCheck1"
                                        checked={checkBox[1]}
                                        onChange={(e) => {
                                            setCheckBox([checkBox[0], e.target.checked])
                                        }}
                                        style={{
                                            marginRight: '5px'
                                        }}
                                    />{"   "}

                                    <span className='fw-semibold'>
                                        Declaro:
                                    </span>
                                    {" "}ya he devuelto la totalidad del depósito de garantía, descontando únicamente las penalizaciones o reparaciones correspondientes.

                                </Label>
                                {/* <Label htmlFor="exampleFormControlTextarea5" className="form-label">Example Textarea</Label>
                                <textarea className="form-control" id="exampleFormControlTextarea5" rows={3}></textarea> */}
                            </div>
                            <hr />
                            {/* <div className="mb-3 text-start">
                                <Label for="iconrightInput" className="form-label">Introduzca su contraseña</Label>
                                <div className='form-icon right position-relative'>
                                    <Input type="password" className="form-control form-control-icon pe-5" placeholder='Introduce tu contraseña' value={password} onChange={(e) => setPassword(e.target.value)} />
                                    <button className="btn btn-link position-absolute end-0 top-50 translate-middle-y text-decoration-none text-muted">
                                        <i className="ri-eye-fill align-middle"></i>
                                    </button>
                                </div>
                            </div> */}
                            <div className="hstack gap-2 justify-content-center">
                                <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                                <Button color="success" disabled={!checkBox[0] || !checkBox[1]} onClick={handleFinishContract}>Aprobar</Button>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );
};

export default ContractDetails;
