import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "../../Components/Common/TablePartaRentals";
import { GetLastProperties } from "../../service/getLastPropertiesDahsboard";
import { useNavigate } from "react-router-dom";

const DashboardTable = () => {
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [modal, setModal] = useState<boolean>(false);
    const [lastProperties, setLastProperties] = useState<any>();

    const getLastProperties = () => {
        // const response = await GetLastProperties();
        // console.log('valor de response de last properties', response);
        // setLastProperties(response);
        GetLastProperties().then(async (response) => {
            console.log('valor de response de last properties', response);
            setLastProperties(response);
        }).catch((err) => {
            console.log('Error al obtener propiedades', err);
        });
    }

    useEffect(() => {
        getLastProperties();
    }, [])

    const columns = useMemo(
        () => [
            {
                Header: "Título",
                accessor: (cellProps: any) => {
                    // console.log('valor de celdas', cellProps);
                    return (
                        <div className="d-flex gap-2 align-items-center" style={{ cursor: 'pointer' }} onClick={() => history(`/pages-details-properties/${cellProps._id}`)}>
                            <div className="flex-shrink-0">
                                {cellProps.images && (<img src={cellProps.images[0]?.url} alt="" className="avatar-xs rounded-circle" />)}
                            </div>
                            <div className="flex-grow-1">
                                {cellProps.detail.title}
                            </div>
                        </div>
                    )
                },
                disableFilters: false,
                filterable: true,
            },

            {
                Header: "Ubicación",
                accessor: (cellProps: any) => {
                    console.log('valor de celdas', cellProps);
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.location.province}
                            </div>
                        </div>
                    )
                },
                disableFilters: false,
                filterable: true,
            },
            {
                Header: "Agente",
                accessor: (cellProps: any) => {
                    console.log('valor de celdas', cellProps);
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.detail?.agent?.fullName}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Precio Doláres",
                accessor: (cellProps: any) => {
                    console.log('valor de celdas', cellProps);
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.detail.priceInDollars}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Precio Colones",
                accessor: (cellProps: any) => {
                    console.log('valor de celdas', cellProps);
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.detail.priceInColones}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    return (
        <React.Fragment >
            <TableContainer
                columns={(columns || [])}
                data={(lastProperties || [])}
                // isPagination={true}
                isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={5}
                divClass="table-responsive"
                className="custom-header-css table align-middle table-nowrap"
                tableClassName="table-centered align-middle table-nowrap mb-0 table-responsive"
                theadClassName="text-muted table-light"
                SearchPlaceholder='Buscar...'
                showPagination={false}
            />
        </React.Fragment >
    );
};

export default DashboardTable;