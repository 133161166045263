const parseDataToForm = (data: any) => {
    for (const key in data) {
        // @ts-ignore
        if (!data[key]) delete data[key]
    }
    if (data._id) {
        // @ts-ignore
        // eslint-disable-next-line
        delete data._id;
    }

    const formData = new FormData();

    for (const key in data) {
        // @ts-ignore
        if (data[key])
            // @ts-ignore
            formData.append(key, data[key]);
    }

    return formData;
}

export { parseDataToForm };