import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "types";
interface InitStateType {
  message: null | string;
  loading: boolean;
  user: IUser | null;
  success: boolean;
  error: boolean;
}


export const initialState: InitStateType = {
  error: false,
  success: false,
  user: null,
  message: null,
  loading: false
};

const ProfileSlice = createSlice({
  name: "Profile",
  initialState,
  reducers: {
    profileSuccess(state, action) {
      state.success = action.payload.status;
      state.user = action.payload.data
    },
    profileError(state, action) {
      state.error = action.payload
    },
    editProfileChange(state) {
      state = { ...state };
    },
    resetProfileFlagChange(state) {
      state = {
        error: false,
        success: false,
        user: null,
        message: null,
        loading: false
      };

    }
  },
});

export const {
  profileSuccess,
  profileError,
  editProfileChange,
  resetProfileFlagChange
} = ProfileSlice.actions

export default ProfileSlice.reducer;