import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, UncontrolledTooltip, DropdownMenu, DropdownToggle, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalHeader, Pagination, PaginationItem, PaginationLink, Row, Table, UncontrolledDropdown } from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import { DefaultTable } from 'pages/Tables/ReactTables/ReactTable';
import { ResponsiveTables } from 'pages/Tables/BasicTables/BasicTablesCode';
import PreviewCardHeader from 'Components/Common/PreviewCardHeader';

import AvatarIcon from '../../../../assets/images/users/avatar-3.jpg'
import Select from "react-select";

import FlatPicker from "react-flatpickr";

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import { ContractStatus, IContract, ICreateContract, PenaltyPeriod } from 'types/api/contracts';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { ICurrency, IProperty, IUser } from 'types';
import { CreateContract, UpdateContract, GetContract, RenewContract } from 'service/contracts';
import { GetProperty, GetAllPropertiesV2 } from "service/properties"
import { GetUnique, GetUserByDNI } from "service/users"
import { GetCurrencies } from "service/currencies"
import { co } from '@fullcalendar/core/internal-common';
import { DNITypesOptions } from 'Components/constants/data';
import { useDebounceCallback, useDebounceValue } from 'usehooks-ts';
import { useAppSelector } from 'slices/hooks';
import { Form } from "reactstrap"
import { FilePondFile } from 'filepond';

interface CustomFlatPickerProps {
    remove: boolean,
    index?: number
}

const CustomFlatPicker = (props: any & CustomFlatPickerProps) => {
    const ref = useRef<any>()
    useEffect(() => {
        if (props.index && props.remove) {
            document.querySelectorAll(".flatpickr-calendar")[props.index]?.querySelector(".flatpickr-months")?.remove()
        }
    }, [props.index, props.remove])

    return (
        <FlatPicker
            {...props}
            ref={ref}
        />
    )
}

const penaltyTimes = [
    {
        value: 12,
        label: "12 meses"
    },
    {
        value: 18,
        label: "18 meses"
    },
    {
        value: 24,
        label: "24 meses"
    },
    {
        value: 30,
        label: "30 meses"
    },
    {
        value: 36,
        label: "36 meses"
    }
]

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const formInitValue: ICreateContract = {
    agreedPrice: 0,
    annualIncrement: 0,
    currencyId: "",
    deposit: 0,
    endDate: "",
    lateInterest: 0,
    ownerId: "",
    paymentDate: "",
    paymentMethod: "Card",
    propertyId: "",
    startDate: "",
    tenantId: "",
    legalDocument: undefined,
    contractType: "new",
    gracePeriod: 9,
    penaltyPeriod: PenaltyPeriod.TWELVE_MONTHS,
    status: ContractStatus.PENDING_APPROVAL,
    terminationPenalty: 0
}

const parseStringNumber = (value: string) => {
    const valueNumber = parseInt(value)

    if (isNaN(valueNumber)) return 0

    if (value[0] === "0" && valueNumber > 0) {
        value = value.slice(1)
    }

    return parseInt(value)
}

interface ContractRegisterProps {
    renew?: boolean
}

const ContractRegister = ({ renew }: ContractRegisterProps) => {
    const history = useNavigate()
    const user = useAppSelector(state => state.Profile.user)
    const { _id } = useParams<{ _id: string }>()
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState<File | null>(null);
    const [filesPreview, setFilesPreview] = useState<any | null>(null);
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState(formInitValue);
    const [contract, setContract] = useState<IContract | null>(null);
    const [tenant, setTenant] = useState<IUser | null>(null);
    const [owner, setOwner] = useState<IUser | null>(null);
    const [property, setProperty] = useState<IProperty | null>(null);
    const [properties, setProperties] = useState<IProperty[]>([]);
    const [currency, setCurrency] = useState<ICurrency | null>(null);
    const [currencies, setCurrencies] = useState<ICurrency[]>([]);
    const [isNewContract, setIsNewContract] = useState(true);
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [tenantDniType, setTenantDniType] = useState(DNITypesOptions[0].value);
    const [tenantDniNumber, setTenantDniNumber] = useState("");
    const [ownerDniType, setOwnerDniType] = useState(owner?.dni?.split("-")[0] ?? DNITypesOptions[0].value);
    const [ownerDniNumber, setOwnerDniNumber] = useState(owner?.dni?.split("-")[1] ?? "");
    const [fullTenantDni] = useDebounceValue(`${tenantDniType}-${tenantDniNumber}`, 500)
    const [fullOwnerDni] = useDebounceValue(`${ownerDniType}-${ownerDniNumber}`, 500)

    const handleAcceptedFiles = useCallback(async (newFiles: FilePondFile[]) => {
        if (!newFiles) return
        if (files) return

        const _file = newFiles[0]

        if (!_file.file?.arrayBuffer) return

        const arrayBuffer = await _file.file.arrayBuffer()

        setFiles(new File([arrayBuffer], _file.filename, { type: _file.fileType }))
        setFilesPreview(_file)
    }, [files])

    document.title = "Registrar contrato | Partarentals";

    const [modal_center, setmodal_center] = useState<boolean>(false);
    function tog_center() {
        setmodal_center(!modal_center);
    }

    const [modal_declined, setmodal_declined] = useState<boolean>(false);
    function decline_payment() {
        setmodal_declined(!modal_declined);
    }

    const saveContracts = () => {
        setLoading(true);
        const saveContract: ICreateContract = {
            agreedPrice: formValues.agreedPrice,
            annualIncrement: formValues.annualIncrement,
            currencyId: formValues.currencyId,
            deposit: formValues.deposit,
            endDate: formValues.endDate,
            lateInterest: formValues.lateInterest,
            ownerId: formValues.ownerId,
            paymentDate: formValues.paymentDate,
            paymentMethod: formValues.paymentMethod,
            propertyId: formValues.propertyId,
            startDate: formValues.startDate,
            tenantId: formValues.tenantId,
            legalDocument: files ?? undefined,
            contractType: formValues.contractType,
            gracePeriod: isNewContract ? formValues.gracePeriod : new Date(formValues.gracePeriod).getDate(),
            penaltyPeriod: formValues.penaltyPeriod,
            status: formValues.status,
            terminationPenalty: formValues.terminationPenalty,
        }

        if (!_id) {
            CreateContract(saveContract)
                .then(async (response) => {
                    setLoading(false);
                    toast.success("Contrato creado con éxito");
                    if (response) history("/contracts-offers-preview");
                })
                .catch((error) => {
                    setLoading(false);
                    console.log("Error al crear la propiedad", error);
                    if (
                        error &&
                        error.response &&
                        error.response.data &&
                        error.response.data.message
                    ) {
                        toast.error(error.response.data.message);
                    } else {
                        toast.error("Ha ocurrido un error");
                    }
                });
        } else {
            const id = _id ?? "";
            if (renew) {
                RenewContract(id, saveContract)
                    .then(async (response) => {
                        setLoading(false);
                        toast.success("Contrato renovado con éxito");
                        if (response) history("/active-contracts-preview");
                    })
                    .catch((err) => {
                        toast.error("Error al renovar el contrato");
                        setLoading(false);
                    })
            } else {
                UpdateContract(id, saveContract)
                    .then(async (response) => {
                        setLoading(false);
                        toast.success("Propiedad actualizada con éxito");
                        if (response) history("/active-contracts-preview");
                    })
                    .catch((err) => {
                        toast.error("Error al actualizar la propiedad");
                        setLoading(false);
                    });
            }

        }
    };

    const handleContractValidation = useFormik<ICreateContract>({
        enableReinitialize: true,
        initialValues: formInitValue,
        onSubmit: tog_center,
        validationSchema: Yup.object<ICreateContract>({
            tenantId: Yup.string().required("El campo inquilino es requerido"),
            ownerId: Yup.string().required("El campo propietario es requerido"),
            currencyId: Yup.string().required("El campo moneda es requerido"),
            propertyId: Yup.string().required("El campo propiedad es requerido"),
            startDate: Yup.string().required("El campo fecha de inicio es requerido"),
            endDate: Yup.string().required("El campo fecha de culminacion es requerido"),
            agreedPrice: Yup.number().min(1, "El campo precio acordado es requerido").required("El campo precio acordado es requerido"),
            paymentDate: Yup.string().required("El campo fecha de pago es requerido"),
            annualIncrement: Yup.number().required("El campo incremento anual es requerido"),
            lateInterest: Yup.number().required("El campo recargo moratorio es requerido"),
            deposit: Yup.number().min(1, "El campo deposito es requerido").required("El campo deposito es requerido"),
            paymentMethod: Yup.string().required("El campo metodo de pago es requerido"),
            terminationPenalty: Yup.number().min(1, "El campo metodo de pago es requerido").required("El campo metodo de pago es requerido"),
        }),
    })

    const handleGetCurrencies = useCallback(async () => {
        const currencies = await GetCurrencies()
        setCurrencies(currencies)
        setFormValues(prev => ({ ...prev, currencyId: currencies[0]._id, contractType: renew ? "extended" : prev.contractType }))
        setCurrency(currencies[0])
    }, [])

    const handleGetContract = useCallback(async () => {
        if (_id) {
            const contract = await GetContract(_id)
            setContract(contract)
            setTenant(contract.tenant)
            setCurrency(contract.currency)
            handleContractValidation.setValues({
                agreedPrice: contract.agreedPrice,
                annualIncrement: contract.annualIncrement,
                currencyId: contract.currency._id,
                deposit: contract.deposit,
                endDate: contract.endDate,
                lateInterest: contract.lateInterest,
                ownerId: contract.owner._id,
                paymentDate: contract.paymentDate,
                paymentMethod: contract.paymentMethod,
                propertyId: contract.property._id,
                startDate: contract.startDate,
                tenantId: contract.tenant._id,
                contractType: renew ? "extended" : contract.contractType,
                gracePeriod: contract.gracePeriod,
                penaltyPeriod: contract.penaltyPeriod,
                status: contract.status,
                terminationPenalty: contract.terminationPenalty
            })
            setFormValues({
                agreedPrice: contract.agreedPrice,
                annualIncrement: contract.annualIncrement,
                currencyId: contract.currency._id,
                deposit: contract.deposit,
                endDate: contract.endDate,
                lateInterest: contract.lateInterest,
                ownerId: contract.owner._id,
                paymentDate: contract.paymentDate,
                paymentMethod: contract.paymentMethod,
                propertyId: contract.property._id,
                startDate: contract.startDate,
                tenantId: contract.tenant._id,
                contractType: renew ? "extended" : contract.contractType,
                gracePeriod: contract.gracePeriod,
                penaltyPeriod: contract.penaltyPeriod,
                status: contract.status,
                terminationPenalty: contract.terminationPenalty
            })
        }
    }, [_id, handleContractValidation])

    const handleGetTenant = useCallback(async () => {
        if (handleContractValidation.values.tenantId) {
            const tenant = await GetUnique(handleContractValidation.values.tenantId)
            setTenant(tenant)
        }
    }, [handleContractValidation.values.tenantId])

    const handleGetProperty = useCallback(async () => {
        if (handleContractValidation.values.propertyId) {
            const property = await GetProperty(handleContractValidation.values.propertyId)
            setProperty(property)
        }
    }, [handleContractValidation.values.propertyId])

    const handleGetCurrency = useCallback(async () => {
        if (handleContractValidation.values.currencyId) {
            const currency = currencies.find(currency => currency._id === handleContractValidation.values.currencyId)
            if (currency) {
                setCurrency(currency)
                setFormValues(prev => ({ ...prev, currencyId: currency._id }))
                handleContractValidation.setValues(prev => ({ ...prev, currencyId: currency._id }))
            }
        }
    }, [currencies])

    const handleGetOwnerProperties = useCallback(async () => {
        if (user?._id) return
        const properties = await GetAllPropertiesV2({
            params: {
                propertyManager: user?._id,
                status: {
                    status: true
                }
            }
        })
        // @ts-ignore
        setProperties(properties)
    }, [user?._id])

    const handleGetTenantUser = useDebounceCallback(async (fullDni) => {
        try {
            const _tenant = await GetUserByDNI(fullDni)
            if (_tenant) {
                setTenant(_tenant)
                handleContractValidation.setValues({
                    ...handleContractValidation.values,
                    tenantId: _tenant._id
                })
                setFormValues(prev => ({
                    ...prev,
                    tenantId: _tenant._id
                }))
            }
        } catch (error) {
            setTenant(null)
            handleContractValidation.setValues({
                ...handleContractValidation.values,
                tenantId: ""
            })
            setFormValues(prev => ({
                ...prev,
                tenantId: ""
            }))
            console.log(error)
        }

    }, 500)

    const handleGetOwnerUser = useDebounceCallback(async (fullDni) => {
        try {
            const _owner = await GetUserByDNI(fullDni)
            if (_owner) {
                setOwner(_owner)
                handleContractValidation.setValues({
                    ...handleContractValidation.values,
                    ownerId: _owner._id
                })
                setFormValues(prev => ({
                    ...prev,
                    ownerId: _owner._id
                }))
            }
        } catch (error) {
            console.log(error)
        }

    }, 500)

    useEffect(() => {
        handleGetCurrencies()
        handleGetOwnerProperties()
    }, [user])

    useEffect(() => {
        handleContractValidation.setValues(formValues)
        const selectedCurrency = currencies.find(currency => currency._id === formValues.currencyId)
        selectedCurrency && setCurrency(selectedCurrency)
    }, [currencies, formValues])

    useEffect(() => {
        if (formValues.contractType === "new") {
            setIsNewContract(true)
        } else {
            setIsNewContract(false)
        }
    }, [formValues.contractType])

    useEffect(() => {
        handleGetContract()
    }, [_id])

    useEffect(() => {
        if (tenant?._id === undefined || tenant?._id !== handleContractValidation.values.tenantId) {
            handleGetTenant()
        }
        if (property?._id === undefined || property?._id !== handleContractValidation.values.propertyId) {
            handleGetProperty()
        }
        if (currency?._id === undefined || currency?._id !== handleContractValidation.values.currencyId) {
            handleGetCurrency()
        }
    }, [currency?._id, handleContractValidation.values, property?._id, tenant?._id])

    useEffect(() => {
        if (!tenant?.dni) return
        const dniSplit = `${tenant?.dni}`?.split('-')
        if (!dniSplit) return
        if (dniSplit?.length === 2) {
            setTenantDniType(dniSplit[0])
            setTenantDniNumber(dniSplit[1])
        } else if (dniSplit?.length === 1) {
            setTenantDniNumber(dniSplit[0])
        } else {
            setTenantDniNumber('')
        }
    }, [tenant?.dni])

    useEffect(() => {
        if (fullTenantDni) {
            handleGetTenantUser(fullTenantDni)
        }
    }, [fullTenantDni])

    useEffect(() => {
        if (fullOwnerDni)
            handleGetOwnerUser(fullOwnerDni)
    }, [fullOwnerDni])

    useEffect(() => {
        if (currency?.codigo === "USD") {
            setFormValues({
                ...formValues,
                annualIncrement: 0
            })
        }
    }, [currency])

    useEffect(() => {
        if (property?.owner)
            setOwner(property?.owner)
    }, [property])

    useEffect(() => {
        setOwnerDniNumber(owner?.dni?.split("-")[1] ?? "")
        setOwnerDniType(owner?.dni?.split("-")[0] ?? DNITypesOptions[0].value)
    }, [owner])


    return (
        <React.Fragment>
            <div className="page-content mb-1 pb-0">
                <Container fluid>
                    <BreadCrumb title="Registrar contrato" pageTitle="Pages" />
                </Container>
            </div>
            <Form onSubmit={(e) => {
                e.preventDefault()
                setSubmitted(true)
                handleContractValidation.submitForm()
            }}>
                {/* Tipo de contrato */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col lg={12} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h5 className="mb-0">Tipo de contrato</h5>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className='d-flex gap-2'  >
                                            <Col lg={2}>
                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="validationFormCheck1"
                                                        name="radio-stacked" required
                                                        onChange={() => {
                                                            setIsNewContract(true)
                                                            setFormValues({ ...formValues, contractType: "new" })
                                                        }}
                                                        checked={isNewContract}
                                                        disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                    />
                                                    <Label className="form-check-label" htmlFor="validationFormCheck1">Contrato nuevo</Label>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div className="form-check">
                                                    <Input
                                                        type="radio"
                                                        className="form-check-input"
                                                        id="validationFormCheck2"
                                                        name="radio-stacked"
                                                        required
                                                        onChange={() => {
                                                            setIsNewContract(false)
                                                            setFormValues({ ...formValues, contractType: "extended" })
                                                        }}
                                                        checked={!isNewContract}
                                                        disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                    />
                                                    <Label className="form-check-label" for="validationFormCheck2">Contrato existente</Label>
                                                </div>
                                            </Col>
                                            {submitted &&
                                                <Label className='text-danger'>
                                                    <div>{handleContractValidation.errors.contractType}</div>
                                                </Label>}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Propiedad a alquilar */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col lg={12} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h5 className="mb-0">Propiedad a alquilar</h5>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className='d-flex'>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="ForminputState" className="form-label">Propiedad</Label>
                                                    <Select
                                                        value={{ value: property?._id, label: property?.detail.title }}
                                                        onChange={(selectedOption: any) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                propertyId: selectedOption.value,
                                                            });
                                                            const selectedProperty = properties.find(property => property._id === selectedOption.value)
                                                            if (selectedProperty) setProperty(selectedProperty)
                                                        }}
                                                        options={properties.filter(el => el.status.status).map(property => ({ value: property._id, label: property.detail.title }))}
                                                        style={{
                                                            zindex: 2,
                                                        }}
                                                        isDisabled={!!_id && contract?.status === ContractStatus.ACTIVE}
                                                    />
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.propertyId}</div>
                                                        </Label>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="address" className="form-label">Ubicación</Label>
                                                    <Input
                                                        type="text"
                                                        name="address"
                                                        id="address"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={property?.location.direction}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Datos del arrendador */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col lg={12} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h5 className="mb-0">Datos del arrendador (Titular de la propiedad)</h5>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className='d-flex'>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="JoiningdatInput" className="form-label">Cédula de identidad</Label>
                                                    <Row>
                                                        <Col lg={4} className='mb-3'>
                                                            <Select
                                                                value={DNITypesOptions.find(option => option.value === ownerDniType)}
                                                                onChange={(selectedOption: any) => {
                                                                    setOwnerDniType(selectedOption.value)
                                                                }}
                                                                options={DNITypesOptions}
                                                                style={{
                                                                    zindex: 2,
                                                                }}
                                                                isDisabled={true}
                                                            />

                                                        </Col>

                                                        <Col lg={8}>
                                                            <Input
                                                                type="number"
                                                                name="dniNumber"
                                                                id="dniNumber"
                                                                className="form-control"
                                                                placeholder="Ingrese su cédula de identidad"
                                                                value={ownerDniNumber}
                                                                onChange={(e) => setOwnerDniNumber(e.target.value ?? "")}
                                                                disabled={true}
                                                            />
                                                            {submitted &&
                                                                <Label className='text-danger'>
                                                                    <div>{handleContractValidation.errors.ownerId}</div>
                                                                </Label>}
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </Col>
                                            <Col lg={4} className='mb-3'>
                                                <div>
                                                    <Label htmlFor="email" className="form-label">Correo electrónico</Label>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={owner?.email}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="fullName" className="form-label">Nombre y apellido</Label>
                                                    <Input
                                                        type="text"
                                                        name="fullName"
                                                        id="fullName"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={`${owner?.name ?? ''} ${owner?.surname ?? ''}`}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Datos del arrendatario */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col lg={12} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h5 className="mb-0">Datos del arrendatario</h5>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className='d-flex'>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="JoiningdatInput" className="form-label">Cédula de identidad</Label>
                                                    <Row>
                                                        <Col lg={4} className='mb-3'>
                                                            <Select
                                                                value={DNITypesOptions.find(option => option.value === tenantDniType)}
                                                                onChange={(selectedOption: any) => {
                                                                    setTenantDniType(selectedOption.value)
                                                                }}
                                                                options={DNITypesOptions}
                                                                style={{
                                                                    zindex: 2,
                                                                }}
                                                                isDisabled={!!_id && contract?.status === ContractStatus.ACTIVE}
                                                            />

                                                        </Col>

                                                        <Col lg={8}>
                                                            <Input
                                                                type="number"
                                                                name="dniNumber"
                                                                id="dniNumber"
                                                                className="form-control"
                                                                placeholder="Ingrese su cédula de identidad"
                                                                value={tenantDniNumber}
                                                                onChange={(e) => setTenantDniNumber(e.target.value ?? "")}
                                                                disabled={!!_id && contract?.status === ContractStatus.ACTIVE}
                                                            />
                                                            {submitted &&
                                                                <Label className='text-danger'>
                                                                    <div>{handleContractValidation.errors.ownerId}</div>
                                                                </Label>}
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </Col>
                                            <Col lg={4} className='mb-3'>
                                                <div>
                                                    <Label htmlFor="email" className="form-label">Correo electrónico</Label>
                                                    <Input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={tenant?.email ?? ""}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="fullName" className="form-label">Nombre y apellido</Label>
                                                    <Input
                                                        type="text"
                                                        name="fullName"
                                                        id="fullName"
                                                        className="form-control"
                                                        placeholder=""
                                                        value={`${tenant?.name ?? ''} ${tenant?.surname ?? ''}`}
                                                        disabled
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Pagos del contrato */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col lg={12} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h5 className="mb-0">Pagos del contrato</h5>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className='d-flex'>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="ForminputState" className="form-label">Moneda de pago</Label>
                                                    <Select
                                                        value={{ value: currency?._id, label: currency?.nombre }}
                                                        onChange={(selectedOption: any) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                currencyId: selectedOption.value,
                                                            });
                                                            handleContractValidation.setValues((prev) => ({
                                                                ...prev,
                                                                currencyId: selectedOption.value
                                                            }))
                                                        }}
                                                        options={currencies.map(currency => ({ value: currency._id, label: currency.nombre }))}
                                                        style={{
                                                            zindex: 2,
                                                        }}
                                                        isDisabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                    />
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.currencyId}</div>
                                                        </Label>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="agreedPrice" className="form-label">Precio acordado en {currency?.nombre}</Label>

                                                    <div className="input-group">
                                                        <span className="input-group-text" id="basic-addon1">{currency?.simbolo}</span>
                                                        <Input
                                                            type="number"
                                                            name="agreedPrice"
                                                            id="agreedPrice"
                                                            className="form-control"
                                                            placeholder={`${currency?.simbolo}0.00`}
                                                            onChange={(e) =>
                                                                setFormValues({
                                                                    ...formValues,
                                                                    agreedPrice: parseStringNumber(e.target.value),
                                                                })
                                                            }
                                                            value={formValues.agreedPrice || ""}
                                                        />
                                                    </div>
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.agreedPrice}</div>
                                                        </Label>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="agreedPrice" className="form-label">Deposito en garantía en {currency?.nombre}</Label>

                                                    <div className="input-group">
                                                        <span className="input-group-text" id="basic-addon1">{currency?.simbolo}</span>
                                                        <Input
                                                            type="number"
                                                            name='deposit'
                                                            className="form-control"
                                                            placeholder={`${currency?.simbolo}0.00`}
                                                            id="deposit"
                                                            onChange={(e) =>
                                                                setFormValues({
                                                                    ...formValues,
                                                                    deposit: parseStringNumber(e.target.value),
                                                                })
                                                            }
                                                            value={formValues.deposit || ""}
                                                            disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                        />
                                                    </div>
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.deposit}</div>
                                                        </Label>}
                                                </div>
                                            </Col>
                                        </Row>
                                        {isNewContract && <Row>
                                            <div>
                                                <Col lg={12} className='width-100 text-center'>
                                                    <Alert color="success" style={{ color: "#002655" }} className='mb-0'>
                                                        Monto a recibir por la primera cuota: <span className='fw-semibold'>{currency?.simbolo}{(formValues.deposit + formValues.agreedPrice) || 0}</span>
                                                    </Alert>
                                                </Col>
                                            </div>
                                        </Row>}
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Clausulas del contrato */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col lg={12} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h5 className="mb-0">Clausulas del contrato</h5>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <Row className='d-flex'>
                                            <Col lg={4}>
                                                <div className='mb-3'>
                                                    <Label htmlFor="startDate" className="form-label">Fecha de inicio</Label>
                                                    <CustomFlatPicker
                                                        name='startDate'
                                                        id='startDate'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            minDate: formValues.contractType === "new" ? new Date().setHours(0, 0, 0) : undefined,
                                                            maxDate: formValues.contractType === "extended" ? new Date().setHours(23, 59, 59) : undefined,
                                                        }}
                                                        onChange={(date: any) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                startDate: date[0].toISOString(),
                                                            })
                                                        }}
                                                        value={formValues.startDate}
                                                        disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                    />
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.startDate}</div>
                                                        </Label>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className='mb-3'>
                                                    <Label htmlFor="endDate" className="form-label">Fecha culminación</Label>
                                                    <CustomFlatPicker
                                                        name='endDate'
                                                        id='endDate'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            minDate: new Date(formValues.startDate).setFullYear(new Date(formValues.startDate).getFullYear() + 3)
                                                        }}
                                                        onChange={(date: any) => {
                                                            console.log(date)
                                                            setFormValues({
                                                                ...formValues,
                                                                endDate: date[0].toISOString(),
                                                            })
                                                        }}
                                                        value={formValues.endDate}
                                                        disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                    />
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.endDate}</div>
                                                        </Label>}
                                                </div>
                                            </Col>

                                            <Col lg={4}>
                                                <div className='mb-3'>
                                                    <Label htmlFor="annualIncrement" className="form-label" id="tooltipTop">Incremento anual acordado <i className='ri-error-warning-line'></i></Label>
                                                    <UncontrolledTooltip placement="top" target="tooltipTop" > <span>{"Según leyes locales el incremento no puede ser superior al 15%."}</span><br /><span>{"Si la moneda de pago es en dolares entonces no habrá incremento anual por ley."}</span> </UncontrolledTooltip>
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="basic-addon1">%</span>
                                                        <Input
                                                            type="number"
                                                            name='deposit'
                                                            id='deposit'
                                                            className="form-control"
                                                            placeholder="0%"
                                                            disabled={currency?.codigo === "USD" || !!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                            onChange={(e) => {
                                                                if (currency?.codigo === "USD") {
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        annualIncrement: 0,
                                                                    })
                                                                    return
                                                                }
                                                                if (parseStringNumber(e.target.value) > 15) {
                                                                    return setFormValues({
                                                                        ...formValues,
                                                                        annualIncrement: 15,
                                                                    })
                                                                }

                                                                setFormValues({
                                                                    ...formValues,
                                                                    annualIncrement: parseStringNumber(e.target.value),
                                                                })
                                                            }}
                                                            value={formValues.annualIncrement || ""}
                                                        />
                                                        {submitted &&
                                                            <Label className='text-danger'>
                                                                <div>{handleContractValidation.errors.annualIncrement}</div>
                                                            </Label>}
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row className='d-flex'>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="paymentDate" className="form-label">Fecha de cobro</Label>
                                                    <CustomFlatPicker
                                                        remove={true}
                                                        index={2}
                                                        name='paymentDate'
                                                        id='paymentDate'
                                                        className="form-control"
                                                        options={{
                                                            dateFormat: "d M, Y",
                                                            maxDate: new Date().setDate(28),
                                                            minDate: new Date().setDate(1),
                                                        }}
                                                        onChange={(date: any) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                paymentDate: date[0].toISOString(),
                                                            })
                                                        }}
                                                        value={formValues.paymentDate}
                                                        disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                    />
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.paymentDate}</div>
                                                        </Label>}
                                                    {/*  <Input type="text" className="form-control" placeholder="1 de cada mes" id="textInput" /> */}
                                                </div>
                                            </Col>
                                            {isNewContract &&
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <UncontrolledTooltip placement="top" target="tooltipTop2" >Elija un valor entre 7 a 15 días</UncontrolledTooltip>
                                                        <Label htmlFor="gracePeriod" className="form-label" id="tooltipTop2">Plazo de gracia (Días) <i className='ri-error-warning-line'></i></Label>
                                                        <div className="input-step full-width">
                                                            <button
                                                                type="button"
                                                                className="minus"
                                                                onClick={() => {
                                                                    if (formValues.gracePeriod - 1 < 7) return
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        gracePeriod: formValues.gracePeriod - 1,
                                                                    })
                                                                }}
                                                                disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                            >
                                                                –
                                                            </button>

                                                            <Input
                                                                type="number"
                                                                className="product-quantity"
                                                                value={formValues.gracePeriod}
                                                                min="0"
                                                                max="100"
                                                                readOnly
                                                                disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                            />

                                                            <button
                                                                type="button"
                                                                className="plus"
                                                                onClick={() => {
                                                                    if (formValues.gracePeriod + 1 > 15) return
                                                                    setFormValues({
                                                                        ...formValues,
                                                                        gracePeriod: formValues.gracePeriod + 1,
                                                                    })
                                                                }}
                                                                disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                            >
                                                                +
                                                            </button>
                                                        </div>
                                                        {submitted &&
                                                            <Label className='text-danger'>
                                                                <div>{handleContractValidation.errors.gracePeriod}</div>
                                                            </Label>}
                                                    </div>
                                                </Col>
                                            }
                                            {!isNewContract &&
                                                <Col lg={4}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="gracePeriod" className="form-label">Fecha límite de pago</Label>
                                                        {/* TODO: Por hacer */}
                                                        <CustomFlatPicker
                                                            name='gracePeriod'
                                                            id='gracePeriod'
                                                            className="form-control"
                                                            options={{
                                                                dateFormat: "d M, Y",
                                                                maxDate: new Date().setDate(15),
                                                                minDate: new Date().setDate(7)
                                                            }}
                                                            onChange={(date: any) => {
                                                                setFormValues({
                                                                    ...formValues,
                                                                    gracePeriod: date[0].getTime()
                                                                })
                                                            }}
                                                            value={formValues.gracePeriod}
                                                            disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                        />
                                                        {submitted &&
                                                            <Label className='text-danger'>
                                                                <div>{handleContractValidation.errors.gracePeriod}</div>
                                                            </Label>}
                                                    </div>
                                                </Col>}
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="lateInterest" className="form-label">Recargo moratorio </Label>
                                                    <div className="input-group">
                                                        <span className="input-group-text" id="basic-addon1">{currency?.simbolo}</span>
                                                        <Input
                                                            type="number"
                                                            id="lateInterest"
                                                            name='lateInterest'
                                                            className="form-control"
                                                            placeholder={`${currency?.simbolo}0.00`}
                                                            onChange={(e) =>
                                                                setFormValues({
                                                                    ...formValues,
                                                                    lateInterest: parseStringNumber(e.target.value),
                                                                })
                                                            }
                                                            value={formValues.lateInterest || ""}
                                                            disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                        />
                                                        {submitted &&
                                                            <Label className='text-danger'>
                                                                <div>{handleContractValidation.errors.lateInterest}</div>
                                                            </Label>}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row className='d-flex'>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="terminationPenalty" className="form-label">Penalidad por finalización del contrato </Label>
                                                    {/* TODO: Por hacer */}

                                                    <div className="input-group">
                                                        <span className="input-group-text" id="basic-addon1">{currency?.simbolo}</span>
                                                        <Input
                                                            type="number"
                                                            id="terminationPenalty"
                                                            name='terminationPenalty'
                                                            className="form-control"
                                                            placeholder={`${currency?.simbolo}0.00`}
                                                            onChange={(e) =>
                                                                setFormValues({
                                                                    ...formValues,
                                                                    terminationPenalty: parseStringNumber(e.target.value),
                                                                })
                                                            }
                                                            value={formValues.terminationPenalty || ""}
                                                            disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                        />
                                                    </div>
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.terminationPenalty}</div>
                                                        </Label>}
                                                </div>
                                            </Col>
                                            <Col lg={4}>
                                                <div className="mb-3">
                                                    <Label htmlFor="ForminputState" className="form-label">Plazo de penalidad</Label>
                                                    {/* TODO: Por hacer */}
                                                    <Select
                                                        value={penaltyTimes.find(penaltyTime => penaltyTime.value === formValues.penaltyPeriod)}
                                                        onChange={(selectedOption: any) => {
                                                            setFormValues({
                                                                ...formValues,
                                                                penaltyPeriod: selectedOption.value,
                                                            })
                                                        }}
                                                        options={penaltyTimes}
                                                        style={{
                                                            zindex: 2,
                                                        }}
                                                        isDisabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                    />
                                                    {submitted &&
                                                        <Label className='text-danger'>
                                                            <div>{handleContractValidation.errors.penaltyPeriod}</div>
                                                        </Label>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Contrato legal en PDF (Opcional) */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={12} className="d-flex align-items-center mb-0 justify-content-start">
                                                <h5 className="mb-0">Contrato legal en PDF <span className='text-muted'>{"(Opcional)"}</span></h5>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col lg={12}>
                                                <p className='mt-1 text-muted'>En caso de tener el contrato a la mano, puede subir una copia en formato PDF, sino puede hacerlo más adelante.</p>
                                            </Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col lg={12}>
                                                <FilePond
                                                    files={filesPreview ? [filesPreview] : []}
                                                    onupdatefiles={handleAcceptedFiles}
                                                    maxFiles={1}
                                                    name="files"
                                                    className="filepond filepond-input-multiple"
                                                    allowMultiple={true}
                                                    disabled={!!_id && contract?.status === ContractStatus.ACTIVE && !renew}
                                                />
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* Botón Registrar */}
                <div className="page-content mb-1 pb-0 pt-0">
                    <Container fluid>
                        <Row>
                            <Col lg={12}>
                                <CardBody>
                                    <Row>
                                        <Col lg={12} className="d-flex align-items-center mb-4 justify-content-end">
                                            <Button color="success" type='submit' className='w-100-mobile'>
                                                {renew ? "Renovar" : "Registrar"}  contrato
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Form >
            {/* Vertically Centered */}
            < Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="bx bx-check-shield text-success" style={{ fontSize: "60px" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estas seguro de aprobar este pago?</h4>
                        <p className="text-muted mb-4">Una vez aprobado no se podrá modificar</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                            <Button color="success" onClick={() => console.log("Funciona")}>Aprobar</Button>
                        </div>
                    </div>
                </ModalBody>
            </ Modal>

            {/* Vertically Centered */}
            < Modal
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalHeader className="modal-title" />

                <ModalBody className="text-center p-4">
                    <i className="ri-error-warning-line display-2 ext-warning" style={{ color: "orange" }}></i>
                    <div className="mt-4">
                        <h4 className="mb-3">¿Estás seguro de enviar esta oferta?</h4>
                        <p className='text-muted'>Una vez aceptado por el arrendatario sus cláusulas no podrán se modificadas ni retiradas. Solo podrás modificar el precio cada 12 meses.</p>
                        <form action="">
                            <div className="hstack gap-2 justify-content-center">
                                <Button color="light" onClick={() => setmodal_center(false)}>Cancelar</Button>
                                <Button color="success" onClick={saveContracts}>Confirmar</Button>
                            </div>
                        </form>
                    </div>
                </ModalBody>
            </ Modal>

        </React.Fragment >
    );
};

export default ContractRegister;
