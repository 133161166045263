import React, {useMemo} from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import avtarImage3 from '../../../assets/images/users/avatar-3.jpg';
import TableContainer from "../../../Components/Common/TablePartaRentals";

const Users = () => {

    const searchTable =
        [
            { title: "The Cool House", agent: "Wonderland Property", avatar: avtarImage3, designation: "Senior Response Liaison", company: "Raynor, Rolfson and Daugherty", location: "Qatar" },
            { title: "09", name: "Cathy", avatar: avtarImage3, designation: "Customer Data Director", company: "Ebert, Schamberger and Johnston", location: "Mexico" },
            { title: "08", name: "Patsy", avatar: avtarImage3, designation: "Dynamic Assurance Director", company: "Streich Group", location: "Niue" },
            { title: "07", name: "Kerry", avatar: avtarImage3, designation: "Lead Applications Associate", company: "Feeney, Langworth and Tremblay", location: "Niger" },
            { title: "06", name: "Traci", avatar: avtarImage3, designation: "Corporate titleentity Director", company: "Koelpin - Goldner", location: "Vanuatu" },
            { title: "05", name: "Noel", avatar: avtarImage3, designation: "Customer Data Director", company: "Howell - Rippin", location: "Germany" },
            { title: "04", name: "Robert", avatar: avtarImage3, designation: "Product Accounts Technician", company: "Hoeger", location: "San Marino" },
            { title: "03", name: "Shannon", avatar: avtarImage3, designation: "Legacy Functionality Associate", company: "Zemlak Group", location: "South Georgia" },
            { title: "Jason Lau", name: "Harold", avatar: avtarImage3, designation: "Forward Creative Coordinator", company: "Metz Inc", location: "Iran" },
            { title: "Edward Stewart", name: "Jonathan", avatar: avtarImage3, designation: "Senior Implementation Architect", company: "Hauck Inc", location: "Holy See" }
        ]

    const columns = useMemo(
        () => [
            {
                Header: "Nombre completo",
                accessor: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-shrink-0">
                                {cellProps.avatar && (<img src={cellProps.avatar} alt="" className="avatar-xs rounded-circle" />)}
                            </div>
                            <div className="flex-grow-1">
                                {cellProps.title}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Teléfono",
                accessor: "location",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Email",
                accessor: "agent",
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: "price",
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    document.title = "Usuarios";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <BreadCrumb title="Usuarios" pageTitle="Pages" />
                    <Row>
                        <div className='table-background-parta-rentals'>
                            <TableContainer
                                columns={(columns || [])}
                                data={(searchTable || [])}
                                // isPagination={true}
                                isGlobalFilter={true}
                                iscustomPageSize={false}
                                isBordered={false}
                                customPageSize={5}
                                divClass="table-responsive"
                                className="custom-header-css table align-middle table-nowrap"
                                tableClassName="table-centered align-middle table-nowrap mb-0 table-responsive"
                                theadClassName="text-muted table-light"
                                SearchPlaceholder='Buscar...'
                            />
                        </div >
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Users;