import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { logoutUser } from "../../slices/thunks";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";
import { createSelector } from "reselect";
import { useAppDispatch, useAppSelector } from "slices/hooks";
import { resetProfileFlagChange } from "slices/auth/profile/reducer";
import { useLocalStorage, useSessionStorage } from "usehooks-ts";

const Logout = (props: any) => {
  const dispatch = useAppDispatch()
  const { isUserLogout } = useAppSelector(state => state.Login)
  const [, setLocalToken] = useLocalStorage("authUser", { token: "" });
  const [, setSessionToken] = useSessionStorage("authUser", { token: "" });
  useEffect(() => {
    dispatch(resetProfileFlagChange())
  }, [dispatch])


  useEffect(() => {
    setLocalToken({ token: "" })
    setSessionToken({ token: "" })
    dispatch(logoutUser());
  }, [dispatch, setLocalToken, setSessionToken]);

  if (isUserLogout) {
    return <Navigate to="/login" />;
  }

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};


export default withRouter(Logout);