import firebase from 'firebase/app';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDwzKZ63Irc7cwfUBWra_C50V9hdD-ZUJw",
  authDomain: "preparentals.firebaseapp.com",
  projectId: "preparentals",
  storageBucket: "preparentals.appspot.com",
  messagingSenderId: "103680908217",
  appId: "1:103680908217:web:dbce350dde59664f06f468",
  measurementId: "G-Z90WNLD7HJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const onAuthStatusChange = (callBack: (user: any) => any) => {
  return onAuthStateChanged(auth, (user) => callBack(user))
}

export { app, auth, onAuthStatusChange }