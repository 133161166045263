import Loader from "../../../../Components/loader/loader";
import React, { useMemo, useEffect, useState } from 'react';
import EditIcon from '../../../../assets/images/icons/edit.png';
import TrashIcon from '../../../../assets/images/icons/trash.png';
import BreadCrumb from '../../../../Components/Common/BreadCrumb';
import TableContainer from "../../../../Components/Common/TablePartaRentals";
import { GetAllProperties, DeleteProperty } from "../../../../service/properties";
import AgentModalIcon from '../../../../assets/images/modals/agent-modal-icon.png';
import { Col, Container, Row, Modal, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap';
import { useNavigate } from "react-router-dom";

const StatusIndicator = ({ status, slow, sold }: any) => {
    let color = '#FFFFFF'; // Default color
    let background = '#FFFFFF'; // Default color

    if (status) {
        color = '#54C93C';
        background = '#EFFCEC';
    } else if (sold) {
        color = '#3ACAE1';
        background = '#ECFCFE';
    } else if (slow) {
        color = '#E56A44';
        background = '#FFF1ED';
    }

    return (
        <div style={{ backgroundColor: background, padding: 5, width: 80, borderRadius: 5, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ color: color }}>
                {status ? 'Activo' : sold ? 'Alquilado' : slow ? 'Pausado' : ''}
            </span>
        </div>
    );
};

const Agents = () => {
    const history = useNavigate();
    const [loading, setLoading] = useState(false);
    const [properties, setProperties] = useState<any>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [idProperty, setIdProperty] = useState<string>('');
    const [modalDelete, setModalDelete] = useState<boolean>(false);

    const getProperties = () => {
        // const response = await GetAllProperties();
        GetAllProperties({}).then(async (response) => {
            setProperties(response);
            setLoading(false);
        }).catch((err) => {
            console.log('Error al obtener propiedades', err);
            console.log('Error al obtener propiedades', err.message);
            setLoading(false);
        });
    }

    useEffect(() => {
        setLoading(true);
        getProperties();
        setRefresh(false);
    }, [refresh])

    const deleteProperty = async (id: string) => {
        console.log('valor del id del Agente!!!', id);
        const response = await DeleteProperty(id);
        setModalDelete(false);
        setRefresh(true);
        setLoading(false);
    }

    const createProperty = () => {
        history("/pages-properties-form");
    }

    const columns = useMemo(
        () => [
            {
                Header: "Nombre de la propiedad",
                accessor: (cellProps: any) => {
                    console.log('valor de celdas!!!', cellProps)
                    return (
                        <div className="d-flex gap-2 align-items-center" style={{ cursor: 'pointer' }} onClick={() => history(`/pages-details-properties/${cellProps._id}`)}>
                            <div className="flex-shrink-0">
                                {cellProps.images && (<img src={cellProps.images[0]?.url} alt="" className="avatar-xs rounded-circle" />)}
                            </div>
                            <div className="flex-grow-1">
                                {cellProps.detail.title}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },

            {
                Header: "Habitaciones",
                accessor: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.detail.numberOfRooms}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Baños",
                accessor: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.detail.numberOfBathrooms}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Estacionamiento",
                accessor: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.detail.numberOfParkingSpaces}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Superficie en m²",
                accessor: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                {cellProps.detail.surface}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Estatus",
                accessor: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-grow-1">
                                <StatusIndicator
                                    status={cellProps.status.status}
                                    slow={cellProps.status.slow}
                                    sold={cellProps.status.sold}
                                />
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
            {
                Header: "Action",
                accessor: (cellProps: any) => {
                    return (
                        <div className="d-flex gap-2 align-items-center">
                            <div className="flex-shrink-0 table-action-button-edit">
                                {(<img src={EditIcon} alt="" onClick={() => history(`/pages-properties-form/${cellProps._id}`)} />)}
                            </div>
                            <div className="flex-shrink-0 table-action-button-trash">
                                {(<img src={TrashIcon} alt="" onClick={() => { setModalDelete(true); setIdProperty(cellProps._id) }} />)}
                            </div>
                        </div>
                    )
                },
                disableFilters: true,
                filterable: false,
            },
        ],
        []
    );

    document.title = "Propiedades";
    return (
        <React.Fragment>
            {loading ? (<Loader />) : (
                <div className="page-content">
                    <Modal id="success-Payment" tabIndex={1} isOpen={modalDelete} centered>
                        <ModalBody className='text-center p-5'>
                            <div className="text-end">
                                <button type="button" onClick={() => setModalDelete(false)} className="btn-close text-end" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="mt-2">
                                {/* <i className="ri-chat-smile-2-line display-2 text-success"></i> */}
                                <img src={AgentModalIcon} />
                                <h4 className="mb-3 mt-4">¿Estás seguro de eliminar esta propiedad?</h4>
                                <p className="text-muted fs-15 mb-4">Esta acción es permanente</p>
                                <div className="hstack gap-2 justify-content-center">
                                    <button className="btn btn-soft-success" onClick={() => setModalDelete(false)}>Cancelar</button>
                                    <button onClick={() => deleteProperty(idProperty)} className="btn btn-primary">Eliminar</button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Container fluid>
                        <BreadCrumb title="Propiedades" pageTitle="Dashboard" />
                        <Row style={{ marginBottom: 20 }}>
                            <Col className="d-flex justify-content-end">
                                <Button color="primary" onClick={() => createProperty()}>Nueva propiedad</Button>
                            </Col>
                        </Row>
                        <Row>
                            <div className='table-background-parta-rentals' >
                                <TableContainer
                                    columns={(columns || [])}
                                    data={(properties || [])}
                                    // isPagination={true}
                                    isGlobalFilter={true}
                                    iscustomPageSize={false}
                                    isBordered={false}
                                    customPageSize={5}
                                    divClass="table-responsive"
                                    className="custom-header-css table align-middle table-nowrap"
                                    tableClassName="table-centered align-middle table-nowrap mb-0 table-responsive"
                                    theadClassName="text-muted table-light"
                                    SearchPlaceholder='Buscar propiedades'
                                />
                            </div >
                        </Row>
                    </Container>
                </div>)}
        </React.Fragment>
    );
};

export default Agents;