import { parseDataToForm } from "helpers/pardeDataToForm";
import { APIClient } from "../helpers/api_helper";
import * as url from "../helpers/url-services-parta-rentals/url_helper";

const api = new APIClient();

const headers = {
    "Content-Type": "multipart/form-data",
};

export const GetAgents = () => api.get(url.GET_AGENTS);
export const GetAgentsById = (id: string) => api.get(`${url.GET_AGENTS}/${id}`);
export const CreateAgents = (data: any) => api.create(url.CREATE_AGENTS, data, headers);
export const DeleteAgents = (data: any) => api.delete(`${url.DELETE_AGENTS}/${data}`);
export const UpdateAgents = (data: any, id: string) => {
    const formData = parseDataToForm(data);
    return api.put(`${url.UPDATE_AGENTS}/${id}`, {}, formData, headers)
}
