import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ForgetPassword {
  forgetSuccessMsg: null | string;
  forgetError: null | string;
  user: {
    email: string;
    code: string;
  };
  loading: boolean;
  success: boolean;
  error: boolean
}

export const initialState: ForgetPassword = {
  user: {
    email: "",
    code: "",
  },
  forgetSuccessMsg: null,
  forgetError: null,
  loading: false,
  success: false,
  error: false
};

const forgotPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
    userForgetPasswordSuccess(state, action: PayloadAction<string>) {
      state.forgetSuccessMsg = action.payload
      state.success = true
      state.loading = false
      state.error = false
    },
    userForgetPasswordError(state, action: PayloadAction<string>) {
      state.forgetError = action.payload
      state.success = false
      state.loading = false
      state.error = true
    },
    setUser(state, action: PayloadAction<{ email: string }>) {
      state.user.email = action.payload.email
    },
    setCode: (state, action: PayloadAction<{ code: string }>) => {
      state.user.code = action.payload.code
    },
  }
});

export const {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  setUser,
  setCode
} = forgotPasswordSlice.actions

export default forgotPasswordSlice.reducer;
