import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { stat } from "fs";
import { IUser } from "types";

interface InitStateType {
  user: IUser | null;
  error: string;
  loading: boolean;
  isUserLogout: boolean;
  errorMsg: boolean;
  token: string;
}

export const initialState: InitStateType = {
  user: null,
  token: "",
  error: "", // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    loginUserLoading(state) {
      state.loading = true;
      state.error = "";
      state.errorMsg = false;
    },
    apiError(state, action: PayloadAction<{ data: string }>) {
      state.error = action.payload.data;
      state.loading = false; // Cambiar a false porque la solicitud ha fallado
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action: PayloadAction<string>) {
      state.token = action.payload;
      state.loading = false;
      state.errorMsg = false;
      state.error = ""; // Asegurarse de que el mensaje de error se resetea
    },
    logoutUserSuccess(state) {
      state.isUserLogout = true;
      state.user = null; // Limpiar el usuario al cerrar sesión
      state.token = "";
    },
    resetLoginFlag(state) {
      state.error = "";
      state.loading = false;
      state.errorMsg = false;
    }
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  resetLoginFlag,
  loginUserLoading
} = loginSlice.actions;

export default loginSlice.reducer;
