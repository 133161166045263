//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import {
  GoogleLogin,
  Login,
  RecoverSession
} from "../../../service/auth";

import { loginSuccess, logoutUserSuccess, apiError, resetLoginFlag as resetLoginFlagRedux, loginUserLoading } from './reducer';
import { profileSuccess, resetProfileFlagChange } from '../profile/reducer';
import { SessionToken, SignInUserArgs } from "types";
import { toast } from "react-toastify";

export const loginUser = (user: SignInUserArgs, history: any) => async (dispatch: any) => {
  try {

    dispatch(loginUserLoading())
    let data = await Login({
      email: user.email,
      password: user.password,
    })

    let session = await RecoverSession(data.token)
    sessionStorage.setItem("authUser", JSON.stringify(data));

    dispatch(profileSuccess({ status: "success", data: session.user }));
    dispatch(loginSuccess(data.token));
    toast.success('Inicio de sesión exitoso. ¡Bienvenido de nuevo!');
    history('/dashboard')

  } catch (error: any) {
    toast.error(error.response.data.message)
    dispatch(apiError(error));
  }
};

export const loginUserWithGoogle = (token: string, history: any) => async (dispatch: any) => {
  try {
    dispatch(loginUserLoading())
    let data = await GoogleLogin(token)
    let session = await RecoverSession(data.token)
    sessionStorage.setItem("authUser", JSON.stringify(data));

    dispatch(profileSuccess({ status: "success", data: session.user }));
    dispatch(loginSuccess(data.token));
    toast.success('Inicio de sesión exitoso. ¡Bienvenido de nuevo!');
    history('/dashboard')

  } catch (error: any) {
    toast.error(error.response.data.message)
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    sessionStorage.removeItem("authUser");
    localStorage.removeItem("authUser");
    let fireBaseBackend: any = getFirebaseBackend();
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = fireBaseBackend.logout;
      dispatch(logoutUserSuccess(response));
      dispatch(resetProfileFlagChange())
    } else {
      dispatch(logoutUserSuccess());
      dispatch(resetProfileFlagChange());
    }

  } catch (error: any) {
    dispatch(apiError(error));
  }
};

export const socialLogin = (type: any, history: any) => async (dispatch: any) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend: any = getFirebaseBackend();
      response = fireBaseBackend.socialLoginUser(type);
    }
    //  else {
    //   response = postSocialLogin(data);
    // }

    const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      dispatch(loginSuccess(response));
      history('/dashboard')
    }

  } catch (error: any) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(resetLoginFlagRedux());
    return response;
  } catch (error: any) {
    dispatch(apiError(error));
  }
};

export const recoverSession = (token: string) => async (dispatch: any) => {
  try {
    const storageTokenObject: SessionToken = JSON.parse(sessionStorage.getItem("authUser") ?? '') ?? { token: "" };
    token = token ?? storageTokenObject.token;
    const response = await RecoverSession(token)
    console.log(response)
    if (response.user) {
      dispatch(profileSuccess({ status: "success", data: response.user }));
    }

  } catch (error: any) {
    console.log(error);
  }
}
